// ginue-react-app/src/NoticeDetailModal.js
import React from 'react';
//import './css/layout.css'; // Import the CSS file containing the styles
//import './css/module.css'; // Import additional CSS if needed
import { formatDate } from './components/dateUtils'; // formatDate 함수 임포트 (경로는 실제 위치에 맞게 조정해야 함)

function NoticeDetailModal({ open, onClose, notice }) {
    return (
        <div className={`modal-w ${open ? 'active' : ''}`} id="modal2">
            <div className="modal-inner-type60">
                <div className="title-type001">
                    <strong className="tit01">{notice?.title}</strong>
                </div>
                <div className="list-text002">
                    <div className="tit01" dangerouslySetInnerHTML={{ __html: notice?.content }} />
                    <p className="txt01">
                        작성일: {formatDate(notice?.createdAt)} | 조회수: {notice?.viewCount}
                    </p>
                </div>

                {notice?.attachments && notice.attachments.length > 0 && (
                    <fieldset className="fieldset-type02">
                        <div className="title-type002" style={{ textAlign: 'left' }}>
                            <strong className="tit01">첨부 파일 : </strong>
                            <ul>
                                {notice.attachments.map((attachment, index) => (
                                    <li key={index}>
                                        <a
                                            href={`${process.env.REACT_APP_API_BASE_URL}/uploads/${attachment.fileName}`}
                                            download
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {attachment.fileName}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </fieldset>
                )}
                <button type="button" className="btn-type005 border close" onClick={onClose}>
                    <span className="txt01">닫기</span>
                </button>
            </div>
            <i className="dimm"></i>
        </div>
    );
}

export default NoticeDetailModal;