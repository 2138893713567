import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '30%',
        maxWidth: '800px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));


function SchoolDetail({ open, onClose, schoolCode, refreshSchoolList, searchParams }) {
    const [schoolData, setSchoolData] = useState(null);

    useEffect(() => {
        if (schoolCode) {
            const fetchData = async () => {
                const response = await api.get(`/schoolmgr/school/${schoolCode}`);
                setSchoolData(response.data.data);
            };
            fetchData();
        }
    }, [schoolCode]);

    const handleSave = async () => {
        if (!window.confirm('저장하시겠습니까?')) {
            return;
        }
        const dataToSave = {
            schoolCode: schoolData.schoolCode,
            schoolName: schoolData.schoolName,
            schoolGrade: schoolData.schoolGrade,
            schoolZipcode: schoolData.schoolZipcode,
            schoolAddress1: schoolData.schoolAddress1,
            schoolAddress2: schoolData.schoolAddress2,
            teacherPasswd: schoolData.teacherPasswd
        };

        try {
            const response = await api.put(`/schoolmgr/school/${schoolCode}`, dataToSave);  // 수정 API 호출
            if (response.status >= 200 && response.status < 300) {
                alert('저장에 성공했습니다.');
                refreshSchoolList(searchParams); // 검색 조건으로 다시 검색
                onClose();
            } else {
                alert(`저장에 실패했습니다. 상태 코드: ${response.status}`);
            }
        } catch (error) {
            alert(`저장에 실패했습니다. 오류 메시지: ${error.message}`);
            console.error("There was an error saving the data", error);
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('삭제하시겠습니까? 주의: 학교관리자, 학생, 교원, 검사 응답 정보가 있는 경우에는 삭제되지 않습니다.')) {
            return;
        }
        try {
            const response = await api.delete(`/schoolmgr/school/${schoolCode}`);  // 삭제 API 호출
            if (response.status >= 200 && response.status < 300) {
                alert('삭제에 성공했습니다.');
                refreshSchoolList(searchParams); // 검색 조건으로 다시 검색
                onClose();
            } else {
                alert(`삭제에 실패했습니다. 상태 코드: ${response.status}`);
            }
        } catch (error) {
            alert(`삭제에 실패했습니다. 오류 메시지: ${error.message}`);
            console.error("There was an error deleting the data", error);
        }
    };


    if (!schoolData) return null;  // 데이터가 없으면 아무것도 표시하지 않습니다.

    const schoolOptions = [
        { schoolGrade: 'E', name: '초' },
        { schoolGrade: 'M', name: '중' },
        { schoolGrade: 'H', name: '고' },
    ];

    const filteredOptions = schoolOptions.filter(option => option.schoolGrade === schoolData.schoolGrade);


    return (
        <StyledDialog open={open} onClose={onClose} maxWidth={false}>
            <DialogTitle>학교 정보</DialogTitle>
            <DialogContent style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                overflow: 'auto', // 스크롤바 추가
                maxHeight: '70vh' // 최대 높이 설정. 필요에 따라 조절 가능
            }}>
                <TextField
                    label="학교코드"
                    fullWidth
                    value={schoolData.schoolCode}
                    disabled={true} // schoolCode는 항상 수정 불가능합니다.
                />

                <TextField
                    label="학교명"
                    fullWidth
                    value={schoolData.schoolName}
                    onChange={(e) => setSchoolData({ ...schoolData, schoolName: e.target.value })}
                />

                <TextField
                    label="학교주소1"
                    fullWidth
                    value={schoolData.schoolAddress1}
                    onChange={(e) => setSchoolData({ ...schoolData, schoolAddress1: e.target.value })}
                />

                <TextField
                    label="학교주소2"
                    fullWidth
                    value={schoolData.schoolAddress2}
                    onChange={(e) => setSchoolData({ ...schoolData, schoolAddress2: e.target.value })}
                />

                <TextField
                    label="우편번호"
                    fullWidth
                    value={schoolData.schoolZipcode}
                    onChange={(e) => setSchoolData({ ...schoolData, schoolZipcode: e.target.value })}
                />

                <FormControl component="fieldset" disabled={true}>
                    <FormLabel component="legend">학교급</FormLabel>
                    <RadioGroup
                        row
                        value={schoolData.schoolGrade}
                        onChange={(e) => setSchoolData({ ...schoolData, schoolGrade: e.target.value })}
                    >
                        <FormControlLabel value="E" control={<Radio />} label="초" />
                        <FormControlLabel value="M" control={<Radio />} label="중" />
                        <FormControlLabel value="H" control={<Radio />} label="고" />
                    </RadioGroup>
                </FormControl>
                <div style={{ display: 'flex', gap: '12px' }}>
                    <Button onClick={handleSave} disabled={false}>저장</Button>
                    <Button onClick={handleDelete} disabled={false}>삭제</Button>
                    <Button onClick={onClose}>닫기</Button>
                </div>

            </DialogContent>
        </StyledDialog>
    );
}

export default SchoolDetail;
