import React, { useState, useEffect, useRef } from 'react';
import api from './apiService';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import './css/layout.css';
import './css/module.css';
import './css/reset.css';

const ManagerClass = ({ schoolCode, currentSection }) => {
    const [classes, setClasses] = useState([]);
    const [classesLoaded, setClassesLoaded] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState({ text: '', type: '' });
    const inputRefs = useRef([]);

    const fetchClasses = async () => {
        try {
            const response = await api.get(`/school/class?schoolCode=${schoolCode}`);
            if (response.data && response.data.data && response.data.data.length > 0) {
                setClasses(response.data.data.map(classData => ({
                    schoolYear: classData.schoolYear,
                    schoolClass: classData.schoolClass,
                    classPasswd: classData.classPasswd,
                    schoolCode: schoolCode,
                })));
                setClassesLoaded(true);
            } else {
                setClasses([]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchClasses();
    }, [schoolCode]);

    const handleAddClass = () => {
        setClasses(prevClasses => {
            const newClasses = [...prevClasses, { schoolYear: '', schoolClass: '', classPasswd: '', schoolCode }];
            inputRefs.current = newClasses.map((_, i) => inputRefs.current[i] || React.createRef());
            return newClasses;
        });

        // Focus on the last added class input
        setTimeout(() => {
            const lastIndex = classes.length;
            if (inputRefs.current[lastIndex]) {
                inputRefs.current[lastIndex].current.focus();
            }
        }, 0);
    };

    const handleRemoveClass = (index) => {
        const newClasses = classes.filter((_, i) => i !== index);
        setClasses(newClasses);
        inputRefs.current.splice(index, 1); // Remove the ref for the removed class
    };

    const handleInputChange = (index, event) => {
        const newClasses = classes.map((classInfo, i) => {
            if (i === index) {
                return { ...classInfo, [event.target.name]: event.target.value };
            }
            return classInfo;
        });
        setClasses(newClasses);
    };

    const validateData = () => {
        const classPairs = new Set();

        for (const classInfo of classes) {
            if (!classInfo.schoolYear || isNaN(classInfo.schoolYear) ||
                !classInfo.schoolClass || isNaN(classInfo.schoolClass)) {
                return '학년, 반 정보가 올바르지 않습니다.';
            }

            const classPasswdStr = classInfo.classPasswd.toString();
            if (!classPasswdStr || isNaN(classPasswdStr) || classPasswdStr.length !== 5) {
                return '비밀번호가 올바르지 않습니다. 5자리 자연수로 입력하세요. copy & paste는 하지마세요!';
            }

            const classPair = `${classInfo.schoolYear}-${classInfo.schoolClass}`;
            if (classPairs.has(classPair)) {
                return '학년, 반 정보에 중복이 있습니다.';
            }
            classPairs.add(classPair);
        }
        return '';
    };

    const showMessage = (text, type) => {
        setMessage({ text, type });
        setModalOpen(true);
    };

    const handleSubmit = async () => {
        if (classes.length === 0) {
            showMessage('학급 정보는 1개 이상 입력해야 합니다. 학급을 초기화하려면 초기화 버튼을 클릭하세요.', 'error');
            handleCancel();
            return;
        }

        if (window.confirm('학급 정보를 저장하시겠습니까?')) {
            const validationError = validateData();
            if (validationError) {
                showMessage(validationError, 'error');
                return;
            }

            try {
                let response;
                if (classesLoaded) {
                    response = await api.post('/school/class/update', classes);
                } else {
                    response = await api.post('/school/class/create', classes);
                }

                if (response.status === 200) {
                    showMessage('학급 정보를 성공적으로 저장하였습니다.', 'success');
                    fetchClasses();
                } else {
                    showMessage('학급 정보 저장 실패.', 'error');
                }
            } catch (error) {
                console.error(error);
                showMessage('서버 통신 중 오류가 발생했습니다.', 'error');
            }
        }
    };

    const handleCloseModal = () => setModalOpen(false);

    const handleCancel = () => {
        fetchClasses(); // Re-fetch the classes to reset the state
    };

    const handleResetClasses = async () => {
        if (window.confirm('모든 학급 정보가 초기화 됩니다. 계속하시겠습니까?')) {
            try {
                const response = await api.delete(`/school/class`, { params: { schoolCode } });
                if (response.status >= 200 && response.status < 300) {
                    showMessage('학급 정보를 초기화하였습니다.', 'success');
                    fetchClasses();
                } else {
                    showMessage(`학급 정보 초기화에 실패했습니다. 오류: ${response.statusText}`, 'error');
                }
            } catch (error) {
                console.error(error);
                showMessage(`학급 정보 초기화에 실패했습니다. 서버 오류: ${error.message}`, 'error');
            }
        }
    };

    const handleAutoGeneratePasswords = () => {
        setClasses(prevClasses => prevClasses.map(classInfo => ({
            ...classInfo,
            classPasswd: Math.floor(10000 + Math.random() * 90000).toString()
        })));
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 학교관리 &gt; {currentSection}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">학급 정보</strong>
                </h2>

                <div className="form-inner">
                    <table className="table-type03">
                        <caption>관리자 학급관리 테이블</caption>
                        <thead>
                            <tr>
                                <th width="15%">
                                    <div className="title-type002"><strong className="tit01">학년</strong></div>
                                </th>
                                <th width="25%">
                                    <div className="title-type002"><strong className="tit01">학급(반)</strong></div>
                                </th>
                                <th width="30%">
                                    <div className="title-type002"><strong className="tit01">학급 비밀번호(자연수 5자리)</strong></div>
                                </th>
                                <th width="10%">
                                    <button type="button" className="btn-type006" onClick={handleAddClass}>
                                        <span className="ico-type005 plus"><i className="ir">추가</i></span>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {classes.map((classInfo, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="input-type01">
                                            <label htmlFor={`schoolYear-${index}`} className="hide">학년</label>
                                            <input
                                                id={`schoolYear-${index}`}
                                                type="text"
                                                name="schoolYear"
                                                placeholder="학년"
                                                value={classInfo.schoolYear}
                                                onChange={(event) => handleInputChange(index, event)}
                                                ref={inputRefs.current[index]}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-type01">
                                            <label htmlFor={`schoolClass-${index}`} className="hide">학급수(반)</label>
                                            <input
                                                id={`schoolClass-${index}`}
                                                type="text"
                                                name="schoolClass"
                                                placeholder="학급수(반)"
                                                value={classInfo.schoolClass}
                                                onChange={(event) => handleInputChange(index, event)}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-type01">
                                            <label htmlFor={`classPasswd-${index}`} className="hide">비밀번호(5자리 자연수)</label>
                                            <input
                                                id={`classPasswd-${index}`}
                                                type="text"
                                                name="classPasswd"
                                                placeholder="비밀번호(5자리 자연수)"
                                                value={classInfo.classPasswd}
                                                onChange={(event) => handleInputChange(index, event)}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" className="btn-type006" onClick={() => handleRemoveClass(index)}>
                                            <span className="ico-type005 minus"><i className="ir">삭제</i></span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="btns-group03" style={{ textAlign: 'right', marginTop: '20px' }}>
                        <button type="button" className="btn-type005 border" onClick={handleResetClasses}>
                            학급정보 초기화
                        </button>
                        <button type="button" className="btn-type005 border" onClick={handleAutoGeneratePasswords}>
                            비밀번호 자동 생성
                        </button>
                        <button type="button" className="btn-type005 blue" onClick={handleSubmit}>
                            저장
                        </button>
                        <button type="button" className="btn-type005 border" onClick={handleCancel}>
                            취소
                        </button>
                    </div>
                </div>
            </div>

            <Dialog
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{message.type === 'error' ? '오류' : '성공'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ManagerClass;