import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import api from './apiService';
import ManagerSurveyResultStudentDetail from './ManagerSurveyResultStudentDetail';
import CustomToolbar from './components/CustomToolbar'; // CustomToolbar를 임포트합니다

function ManagerSurveyResultStudent({ schoolCode }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSchoolCode, setSelectedSchoolCode] = useState(null);
    const [schoolName, setSchoolName] = useState('');

    const columns = [
        { field: 'schoolName', headerName: '학교명', flex: 1.5 },
        { field: 'schoolCode', headerName: '학교 코드', flex: 1 },
        { field: 'eventId', headerName: '이벤트ID', flex: 1 },
        { field: 'totalResponses', headerName: '총 응답 수', flex: 1 },
        { field: 'totalAces', headerName: 'ACES 수', flex: 1 },
        { field: 'totalMidd', headerName: 'MIDD 수', flex: 1 },
        { field: 'totalRase', headerName: 'RASE 수', flex: 1 },
    ];

    const handleSearch = () => {
        setIsLoading(true);
        api.post(`/surveyresult/school/student?schoolCode=${schoolCode}`)
            .then(response => {
                const studentData = response.data.data.map(item => ({ ...item, id: item.eventId }));
                setData(studentData);
                if (studentData.length > 0) {
                    setSchoolName(studentData[0].schoolName);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("API fetch error:", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        handleSearch();
    }, []);

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    const handleRowClick = (params) => {
        setSelectedEventId(params.id);
        setSelectedSchoolCode(params.row.schoolCode);
        setOpenDialog(true);
        console.log(params.row);
    };

    return (
        <div id="container" className="container page-admin">
        <div className="content-w-form">
            <div className="admin-indicator">
                <div className="txt-type001">
                    <span className="txt01">홈 &gt; 검사결과 &gt; 학생</span>
                </div>
            </div>

                <h2 className="title-type001">
                    <strong className="tit01">검사결과 (학생)</strong>
                </h2>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ height: data && data.length > 0 ? 'calc(100vh - 300px)' : 'auto' }}>
                            <DataGrid
                                slots={{ toolbar: () => <CustomToolbar fileName={`검사결과(학생)_${schoolName}`} /> }}
                                rows={data}
                                columns={columns}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={[5, 10, 20]}
                                onPageChange={handlePageChange}
                                getRowId={(row) => row.eventId}
                                onRowClick={handleRowClick}
                                loading={isLoading}
                            />
                      
                        {openDialog && <ManagerSurveyResultStudentDetail
                            open={openDialog}
                            onClose={() => {
                                setOpenDialog(false);
                                setSelectedSchoolCode(null);
                            }}
                            eventId={selectedEventId}
                            schoolCode={selectedSchoolCode}
                        />}
                    </Box>
                    {/* 생략된 모달 다이얼로그 관련 코드 */}
                </Box>
            </div>
        </div>
    );
}

export default ManagerSurveyResultStudent;
