// ginue-react-app/src/NoticeListModal.js
import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { formatDate } from './components/dateUtils'; // Assuming this path is correct

import './css/layout.css'; // Import the CSS files
import './css/module.css';
import './css/reset.css'; // Import the CSS files

function NoticeListModal({ open, onClose, notices, onNoticeSelect }) {
    const [searchType, setSearchType] = useState('title'); // 'title' or 'content'
    const [searchKeyword, setSearchKeyword] = useState('');

    const handleSearch = () => {
        return notices.filter(notice => {
            if (searchType === 'title') {
                return notice.title.toLowerCase().includes(searchKeyword.toLowerCase());
            } else if (searchType === 'content') {
                return notice.content.toLowerCase().includes(searchKeyword.toLowerCase());
            }
            return notice;
        });
    };

    const filteredNotices = handleSearch();

    const columns = [
        { field: 'notice_id', headerName: 'ID', flex: 0.5 },
        { field: 'title', headerName: '제목', flex: 2 },
        { field: 'author', headerName: '작성자', flex: 0.5 },
        {
            field: 'createdAt',
            headerName: '작성일',
            flex: 0.5,
            renderCell: (params) => <span>{formatDate(params.row.createdAt)}</span>,
        },
        { field: 'viewCount', headerName: '조회수', flex: 0.5 },
    ];

    return (
        <div id="modal1" className={`modal-w ${open ? 'active' : ''}`}>
            <div className="modal-inner-type80">
                <div className="title-type001">
                    <strong className="tit01">공지사항</strong>
                </div>
                <fieldset className="fieldset-type01">
                    <div className="select-wrap">
                        <select
                            id="select"
                            name=""
                            className="select-type01"
                            value={searchType}
                            onChange={(e) => setSearchType(e.target.value)}
                        >
                            <option value="title">제목</option>
                            <option value="content">내용</option>
                        </select>
                    </div>
                    <form className="input-group01">
                        <div className="input-type01">
                            <label htmlFor="search" className="hide">검색키워드</label>
                            <input
                                id="search"
                                type="text"
                                placeholder="검색키워드"
                                value={searchKeyword}
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        </div>
                        <button type="button" className="btn-type005 blue" onClick={handleSearch}>
                            <span className="txt01">검색</span>
                        </button>
                    </form>
                </fieldset>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={filteredNotices}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        pagination
                        getRowId={(row) => row.notice_id}
                        onRowClick={(params) => onNoticeSelect(params.row.notice_id)}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                borderCollapse: 'collapse !important',
                                width: '100% !important',
                                maxWidth: '1400px !important',
                                backgroundColor: 'var(--ui-color011) !important',
                                color: 'var(--font-color005) !important',
                                fontWeight: '600 !important',
                                fontSize: '19.8px !important', // 10% 증가
                                whiteSpace: 'nowrap !important',
                                borderStyle: 'solid !important',
                                borderColor: 'var(--ui-color006) !important',
                                borderWidth: '1px 0 !important',
                                fontFamily: "'Pretendard', '맑은 고딕', 'Malgun Gothic' !important", // 폰트 3개로 제한
                                textRendering: 'optimizeLegibility !important',
                            },
                            '& .MuiDataGrid-columnHeader': {
                                padding: '16px 8px !important',
                                fontFamily: "'Pretendard', '맑은 고딕', 'Malgun Gothic' !important",
                                textRendering: 'optimizeLegibility !important',
                            },
                            '& .MuiDataGrid-row': {
                                borderBottom: '1px solid var(--ui-color006) !important',
                                cursor: 'pointer !important',
                                '&:hover': {
                                    backgroundColor: 'var(--ui-color011) !important',
                                },
                                fontFamily: "'Pretendard', '맑은 고딕', 'Malgun Gothic' !important",
                                textRendering: 'optimizeLegibility !important',
                            },
                            '& .MuiDataGrid-cell': {
                                overflow: 'hidden !important',
                                textOverflow: 'ellipsis !important',
                                whiteSpace: 'nowrap !important',
                                textAlign: 'left !important',
                                padding: '16px 8px !important',
                                fontSize: '17.6px !important', // 10% 증가
                                lineHeight: '1.4 !important',
                                color: 'var(--font-color001) !important',
                                verticalAlign: 'top !important',
                                fontFamily: "'Pretendard', '맑은 고딕', 'Malgun Gothic' !important",
                                textRendering: 'optimizeLegibility !important',
                            },
                            '@media (max-width: 768px)': {
                                '& .MuiDataGrid-columnHeaders': {
                                    padding: '12px 4px !important',
                                    fontSize: '11px !important', // 10% 증가
                                },
                                '& .MuiDataGrid-cell': {
                                    fontSize: '14.3px !important', // 10% 증가
                                },
                            },
                        }}
                    />
                </div>
                <button type="button" className="btn-type005 border close" onClick={onClose}>
                    <span className="txt01">닫기</span>
                </button>
            </div>
            <i className="dimm"></i>
        </div>
    );
}

export default NoticeListModal;