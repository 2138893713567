import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import api from './apiService';
import NoticeEdit from './NoticeEdit';
import { formatDate } from './components/dateUtils';

function NoticeList({selectedMenu}) {
    const [notices, setNotices] = useState([]);
    const [selectedNotice, setSelectedNotice] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    // State hooks for search fields
    const [searchType, setSearchType] = useState('title'); // 'title' 또는 'content'
    const [searchKeyword, setSearchKeyword] = useState('');

    const [allNotices, setAllNotices] = useState([]); // 전체 공지사항을 저장할 상태
    const [filteredNotices, setFilteredNotices] = useState([]); // 검색 결과를 저장할 상태



    useEffect(() => {
        fetchNotices();
        // 필요한 경우 검색 상태 초기화
        setSearchKeyword('');
    }, []);

    const fetchNotices = async () => {
        try {
            const response = await api.get('/api/notices');
            setAllNotices(response.data);
            setFilteredNotices(response.data); // 초기 상태에서는 전체 목록을 보여줍니다.
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const handleSearch = () => {
        const results = allNotices.filter(notice => {
            if (searchType === 'title') {
                return notice.title.toLowerCase().includes(searchKeyword.toLowerCase());
            } else if (searchType === 'content') {
                return notice.content.toLowerCase().includes(searchKeyword.toLowerCase());
            }
            return true;
        });
        setFilteredNotices(results);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
            event.preventDefault();
        }
    };

    const columns = [
        { field: 'notice_id', headerName: 'ID', flex: 0.5 },
        { field: 'title', headerName: '제목', flex: 2 },
        // { field: 'content', headerName: '내용', width: 300, flex: 2 },
        { field: 'author', headerName: '작성자', flex: 0.5 },
        {
            field: 'createdAt',
            headerName: '작성일',
            flex: 0.5,
            renderCell: (params) => <span>{formatDate(params.row.createdAt)}</span>,
        },
        // { field: 'updatedAt', headerName: '수정일', flex:0.5 },
        { field: 'viewCount', headerName: '조회수', flex: 0.5 },
        {
            field: 'attachments',
            headerName: '첨부 파일',
            flex: 1,
            renderCell: (params) => {
                return params.row.attachments.map((attachment, index) => (
                    <IconButton
                        key={index}
                        component="a"
                        href={`${process.env.REACT_APP_API_BASE_URL}/uploads/${attachment.fileName}`} // 첫 번째 첨부 파일만 다운로드
                        download // 바로 다운로드되도록 함
                        onClick={(e) => e.stopPropagation()} // 이벤트 전파 중단
                    >
                        <GetAppIcon /> {/* 다운로드 아이콘 */}
                    </IconButton>
                ));
                /*
                                return params.row.attachments.map((attachment, index) => (
                                    <a
                                        key={index}
                                        href={`http://localhost:8080/uploads/${attachment.fileName}`}
                                        download={attachment.fileName}
                                        onClick={(e) => e.stopPropagation()} // 이벤트 전파 중단
                                    >
                                        {attachment.fileName}
                                    </a>
                                ));
                */
            }
        }
    ];

    const handleRowClick = async (params) => {
        try {
            const response = await api.get(`/api/notices/${params.row.notice_id}`);
            setSelectedNotice(response.data);
            setOpenModal(true);
        } catch (error) {
            console.error('Error fetching notice details: ', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        // fetchNotices(); 를 호출하지 않습니다.
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 공지사항관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <div className="formBox-notice">
                        <fieldset className="fieldset-type01">
                            <div className="select-wrap">
                                <select
                                    value={searchType}
                                    onChange={(e) => setSearchType(e.target.value)}
                                    className="select-type01"
                                >
                                    <option value="title">제목</option>
                                    <option value="content">내용</option>
                                </select>
                            </div>
                            <form className="input-group01" onSubmit={(e) => e.preventDefault()}>
                                <div className="input-type01">
                                    <label htmlFor="search" className="hide">검색 키워드</label>
                                    <input
                                        id="search"
                                        type="text"
                                        placeholder="검색 키워드"
                                        value={searchKeyword}
                                        onChange={(e) => setSearchKeyword(e.target.value)}
                                        onKeyDown={handleKeyPress}
                                    />
                                </div>
                                <button type="button" className="btn-type005 blue" onClick={handleSearch}>
                                    <span className="txt01">검색</span>
                                </button>
                            </form>
                        </fieldset>
                    </div>

                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={filteredNotices}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5, 10, 20]}
                            getRowId={(row) => row.notice_id}
                            onRowClick={handleRowClick}
                        />
                    </div>
                </div>
            </div>

            {openModal && (
                <div className="modal-w active">
                    <div className="modal-inner-type80">
                        <NoticeEdit
                            notice={selectedNotice}
                            onClose={handleCloseModal}
                            onUpdated={fetchNotices}
                        />
                    </div>
                    <i className="dimm"></i>
                </div>
            )}
        </div>
    );
}

export default NoticeList;
