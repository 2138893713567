import React, { useState, useEffect, useRef } from 'react';
import api from './apiService';

// 이미지 렌더링 함수
const renderImages = (statusImg) => {
    if (!statusImg) return null;

    const images = statusImg.split(',').map((imgUrl, index) => (
        <div key={index} className="image-container" style={{ margin: '10px' }}>
            <img src={`/assets/survey/${imgUrl}`} alt={`Status Image ${index + 1}`} className="formBoxTest-img" style={{ maxWidth: '60%', height: 'auto', borderRadius: '8px' }} />
        </div>
    ));

    return (
        <div className="image-wrapper" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {images}
        </div>
    );
};

// 검사 시작 페이지
const InstructionBox = ({ onStart, onCancel }) => {
    return (
        <div className="modal-w active" aria-modal="true">
            <div className="modal-inner-type80">
                <button type="button" className="btn-type001 close" onClick={onCancel} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                </button>
                <div className="title-type001-blue">
                    <strong className="tit01">학생용 학교 장애인식 검사지</strong>
                </div>
                <div className="formBox-testBlue">
                    <div className="title-type002">
                        <strong className="tit01">
                            안녕하세요? 이 설문은 장애인이 등장하는 상황과 그 상황에 대한 몇 가지 생각들이 제시되어 있습니다.
                            여러분은 그 생각에 얼마나 동의하는지 체크 해주십시오. 그 생각들은 좋고 나쁜 것도 아니며 누구나 가질 수 있는 생각이니
                            솔직하게 답하면 됩니다. 감사합니다.
                        </strong>
                    </div>
                    <button type="button" className="btn-type005 yellow" onClick={onStart}>
                        <span className="txt01">검사 시작</span>
                    </button>
                </div>
                <button type="button" className="btn-type005 border close" onClick={onCancel} >
                        <span className="txt01">닫기</span>
                    </button>                
            </div>
            <i className="dimm"></i>
        </div>
    );
};

const StudentSurveyModal = ({ open, onClose, onSuccessfulSubmit, params, schoolCode, studentSeq, schoolYear }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [surveyData, setSurveyData] = useState({ statuses: [], items: [] });
    const [loading, setLoading] = useState(true);
    const [responses, setResponses] = useState({});
    const [startSurvey, setStartSurvey] = useState(false);
    const modalContentRef = useRef(null); // useRef로 modalContentRef 생성
    const closeButtonRef = useRef(null); // useRef로 closeButtonRef 생성

    useEffect(() => {
        async function fetchSurveyData() {
            try {
                setLoading(true);
                const response = await api.get(`/survey/status/${params.surveyId}`);
                if (response.status === 200) {
                    setSurveyData(response.data);
                    initializeResponses(response.data.items);
                }
            } catch (error) {
                console.error("Error fetching survey data:", error);
                alert("Failed to load survey data.");
            } finally {
                setLoading(false);
            }
        }

        if (open) {
            fetchSurveyData();
        }
    }, [open, params.surveyId]);

    useEffect(() => {
        if (closeButtonRef.current) {
            closeButtonRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentPage]);

    const initializeResponses = (items) => {
        const newResponses = {};
        items.forEach(item => {
            newResponses[item.itemSeq] = '';
        });
        setResponses(newResponses);
    };

    const handleResponseChange = (itemSeq, value) => {
        setResponses({
            ...responses,
            [itemSeq]: value
        });
    };

    const handleSubmit = async () => {
        let totalScore = 0;
        const factorScores = { '실천': 0, '관점': 0, '정서': 0, '지식': 0 };

        const factorThresholds = {
            '실천': params.schoolGrade === 'E' ? { min: 21, mid: 27 } : { min: 26, mid: 32 },
            '관점': params.schoolGrade === 'E' ? { min: 35, mid: 51 } : { min: 34, mid: 47 },
            '정서': params.schoolGrade === 'E' ? { min: 16, mid: 23 } : { min: 14, mid: 21 },
            '지식': params.schoolGrade === 'E' ? { min: 24, mid: 34 } : { min: 34, mid: 47 }
        };
        const responseArray = [];

        for (const question of surveyData.items) {
            const response = responses[question.itemSeq];
            if (response === '') {
                alert(`상황 ${question.statusCode}. ${question.itemNo}번 문항을 응답하지 않았습니다.`);
                return;
            }

            let responseValue = parseInt(response, 10);
            if (question.itemReverse === 'Y') {
                responseValue = 6 - responseValue;
            }

            totalScore += responseValue;
            factorScores[question.factorName] += responseValue;
            responseArray.push(responseValue);
        }

        const responseString = responseArray.join('');

        let totalLevel = '';
        if (params.schoolGrade === 'E') {
            if (totalScore < 96) totalLevel = 'RASE';
            else if (totalScore < 135) totalLevel = 'MIDD';
            else totalLevel = 'ACES';
        } else {
            if (totalScore < 108) totalLevel = 'RASE';
            else if (totalScore < 147) totalLevel = 'MIDD';
            else totalLevel = 'ACES';
        }

        const factorLevels = {};
        for (const factor in factorScores) {
            let level = '';
            if (factorScores[factor] < factorThresholds[factor].min) level = 'RASE';
            else if (factorScores[factor] < factorThresholds[factor].mid) level = 'MIDD';
            else level = 'ACES';
            factorLevels[factor] = level;
        }

        try {
            const payload = {
                responseStuSeq: "",
                response: responseString,
                schoolGrade: params.schoolGrade,
                schoolYear: schoolYear,
                studentSeq: studentSeq,
                responseDate: new Date().toISOString().split('T')[0] + 'T09:00:00',
                surveyId: params.surveyId,
                eventId: params.eventId,
                schoolCode: schoolCode,
                score: totalScore,
                level: totalLevel,
                scope1_score: factorScores['실천'],
                scope2_score: factorScores['관점'],
                scope3_score: factorScores['정서'],
                scope4_score: factorScores['지식'],
                scope1_level: factorLevels['실천'],
                scope2_level: factorLevels['관점'],
                scope3_level: factorLevels['정서'],
                scope4_level: factorLevels['지식']
            };
            console.log(payload);

            const response = await api.post('/api/student-response/create', payload);
            if (response.status === 201) {
                alert('제출을 완료했습니다.');
                onSuccessfulSubmit();
                onClose();
            } else {
                throw new Error('제출에 실패했습니다.');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    if (!open || loading) {
        return <div>Loading...</div>;
    }

    if (!startSurvey) {
        return <InstructionBox onStart={() => setStartSurvey(true)} onCancel={onClose} />;
    }

    const currentStatus = surveyData.statuses[currentPage];
    const currentItems = surveyData.items.filter(item => item.statusCode === currentStatus.statusCode);
    const isLastPage = currentPage === surveyData.statuses.length - 1;

    return (
        <div ref={modalContentRef} className="modal-w active" aria-modal="true">
            <div className="modal-inner-type80">
                <button ref={closeButtonRef} type="button" className="btn-type001 close" onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                </button>
                <div className="title-type001">
                    <strong className="tit01">{currentStatus.statusName}</strong>
                </div>
                <div className="formBox-test">
                    <div className="formBoxTest-top">
                        <div className="title-type002">
                            <strong className="tit01">{currentStatus.statusDescription}</strong>
                        </div>
                    </div>
                    <div className="formBoxTest-middle">
                        {renderImages(currentStatus.statusImg)}
                        <div className="testList_w">
                            {currentItems.map((item) => (
                                <div key={item.itemSeq} className="testList_inner">
                                    <div className="title-type002">
                                        <strong className="tit01">{item.statusCode}-{item.itemNo}: {item.item}</strong>
                                    </div>
                                    <div className="radio-form">
                                        {["전혀 동의하지 않는다", "동의하지 않는다", "잘 모르겠다", "동의한다", "매우 동의한다"].map((option, optionIndex) => (
                                            <span className="input-radio01" key={optionIndex}>
                                                <input
                                                    type="radio"
                                                    id={`type${item.itemSeq}-${optionIndex + 1}`}
                                                    name={`question-${item.itemSeq}`}
                                                    className="radio01"
                                                    value={String(optionIndex + 1)}
                                                    checked={responses[item.itemSeq] === String(optionIndex + 1)}
                                                    onChange={(event) => handleResponseChange(item.itemSeq, event.target.value)}
                                                />
                                                <label htmlFor={`type${item.itemSeq}-${optionIndex + 1}`} className="txt-label01">{option}</label>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="btns-group02" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <button type="button" className="btn-type005 border" onClick={goToPreviousPage} disabled={currentPage === 0} style={{ marginRight: '10px' }}>
                        <span className="txt01">이전 페이지</span>
                    </button>
                    <button type="button" className="btn-type005 border" onClick={goToNextPage} disabled={isLastPage} style={{ marginRight: '10px' }}>
                        <span className="txt01">다음 페이지</span>
                    </button>
                    {isLastPage && (
                        <button type="button" className="btn-type005 blue submit" onClick={handleSubmit}>
                            <span className="txt01">제출</span>
                        </button>
                    )}
                </div>
            </div>
            <i className="dimm"></i>
        </div>
    );
};

export default StudentSurveyModal;