import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import api from './apiService';
import SchoolTeacherDetail from './SchoolTeacherDetail';

function ManagerTeacherSearch({ schoolCode, currentSection }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const [searchField, setSearchField] = useState('teacherName'); // 검색 필드를 state로 추가
    const [searchKeyword, setSearchKeyword] = useState(''); // 검색어를 state로 추가

    const [searchParam, setSearchParam] = useState({
        teacherId: "",
        teacherPasswd: "",
        teacherType: "",
        schoolYear: null,
        schoolClass: null,
        teacherName: "",
        registerDate: "",
        schoolCode: schoolCode,
        schoolDTO: {
            schoolCode: schoolCode,
            schoolName: "",
            schoolGrade: "",
            schoolZipcode: "",
            schoolAddress1: "",
            schoolAddress2: "",
            teacherPasswd: ""
        }
    });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const [openDialog, setOpenDialog] = useState(false);  // 추가
    const [selectedTeacherId, setSelectedTeacherId] = useState(null);


    // 컬럼 정의를 새로운 데이터 구조에 맞게 수정
    const columns = [
        { field: 'teacherId', headerName: '선생님ID', flex: 1 },
        { field: 'teacherName', headerName: '성명', flex: 1 },
        { field: 'schoolYear', headerName: '학년', flex: 0.5 },
        { field: 'schoolClass', headerName: '반', flex: 0.5 },
        { field: 'schoolName', headerName: '학교명', flex: 1.5, renderCell: (params) => params.row.schoolDTO.schoolName },
        { field: 'schoolCode', headerName: '학교코드', flex: 1, renderCell: (params) => params.row.schoolDTO.schoolCode },
    ];

    const schoolGradeOptions = [
        { schoolGrade: 'E', name: '초등' },
        { schoolGrade: 'M', name: '중등' },
        { schoolGrade: 'H', name: '고등' },

    ];

    const handleSearch = () => {
        // Override searchParam.schoolDTO.schoolName with the parameter if provided
        // 검색 필드와 검색어를 사용하여 API 호출
        setIsLoading(true);
        const paramsToSearch = {
            ...searchParam,
            [searchField]: searchKeyword, // 검색 필드를 동적으로 설정
        };

        // API 호출 시에는 searchValue 인자를 사용합니다.
        api.post("/schoolmgr/teacher/search", paramsToSearch)
            .then(response => {
                const teacherDatas = response.data.data.map(item => ({ ...item, id: item.teacherId }));
                console.log(paramsToSearch); // <-- 여기도 오타를 고쳐야 합니다. paramToSearch가 아니라 paramsToSearch입니다.
                setSearchParam(paramsToSearch);
                setData(teacherDatas);
                setIsLoading(false);
                //console.log(teacherDatas);

            })
            .catch(error => {
                console.error("API fetch error:", error);
                setIsLoading(false);
            });
    };


    useEffect(() => {
        handleSearch();
    }, []);

    // 검색 필드 변경 핸들러
    const handleSearchFieldChange = (event) => {
        setSearchField(event.target.value);
    };

    // 검색어 변경 핸들러
    const handleSearchKeywordChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    // 행이 클릭될 때 호출되는 함수
    const handleRowClick = (params) => {
        setSelectedTeacherId(params.row.teacherId);  // 선택된 행의 schoolCode 설정
        setOpenDialog(true);  // 모달을 엽니다.
        console.log(params.row);
    };


    // Create a ref for the TextField
    const inputRef = useRef(null);

    // Handler for the Enter key press in the TextField
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e.target.value);
            e.preventDefault();
        }
    };

    const filteredOptions = schoolGradeOptions.filter(option => option.schoolGrade === searchParam.schoolDTO.schoolGrade);

    // This function is called when a delete operation is successful
    const handleDeleteSuccess = (deletedTeacherId) => {
        // Remove the deleted item from the data array
        const newData = data.filter(item => item.teacherId !== deletedTeacherId);
        setData(newData); // Update the state
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 교사관리 &gt; {currentSection}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">교사 검색</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type01">
                        <form className="input-group01">
                            <div className="select-wrap">
                                <select
                                    id="searchField"
                                    className="select-type01"
                                    value={searchField}
                                    onChange={handleSearchFieldChange}
                                >
                                    <option value="teacherId">선생님ID</option>
                                    <option value="teacherName">성명</option>
                                </select>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="searchKeyword" className="hide">검색어</label>
                                <input
                                    id="searchKeyword"
                                    type="text"
                                    placeholder="검색어를 입력하세요"
                                    value={searchKeyword}
                                    onChange={handleSearchKeywordChange}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                            <button
                                type="button"
                                className="btn-type005 blue"
                                onClick={handleSearch}
                            >
                                검색
                            </button>
                        </form>
                    </fieldset>

                    <Box sx={{ height: data && data.length > 0 ? 'calc(100vh - 300px)' : 'auto' }}>

                        <DataGrid
                            rows={data}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[5, 10, 20]}
                            onPageChange={handlePageChange}
                            getRowId={(row) => row.teacherId}
                            onRowClick={handleRowClick}
                            loading={isLoading}
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'lightgray',
                                    fontSize: '1.2rem',
                                },
                                '& .MuiDataGrid-cell': {
                                    fontSize: '1.1rem',
                                },
                                backgroundColor: '#fff',
                            }}
                            slots={{
                                noRowsOverlay: () => (
                                    <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                        <strong className="tit01">검색된 교사가 없습니다.</strong>
                                    </div>
                                )
                            }}
                        />
                    </Box>

                    {openDialog && <SchoolTeacherDetail
                        open={openDialog}
                        onClose={() => {
                            setOpenDialog(false);
                            setSelectedTeacherId(null);
                        }}
                        onDeleteSuccess={handleDeleteSuccess}
                        teacherId={selectedTeacherId}
                    />}
                </div>
            </div>
        </div>
    );
}

export default ManagerTeacherSearch;
