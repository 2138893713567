import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Grid, Dialog, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import api from './apiService';
import ManagerSchoolRegisterInput from './ManagerSchoolRegisterInput';
import ManagerRegisterInput from './ManagerRegisterInput';

function ManagerSchoolRegister() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [searchParam, setSearchParam] = useState({
        schoolCode: "",
        schoolName: "",
        schoolGrade: "",
        schoolZipcode: "",
        schoolAddress1: "",
        schoolAddress2: "",
        teacherPasswd: ""
    });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const inputRef = useRef(null);

    const [selectedSchoolCode, setSelectedSchoolCode] = useState(null);
    const [selectedSchoolName, setSelectedSchoolName] = useState(null);

    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        { field: 'schoolName', headerName: '학교명', width: isMobile ? 100 : 300, flex: isMobile ? 1 : undefined },
        !isMobile && { field: 'schoolCode', headerName: '학교코드', flex: 1 },
        !isMobile && { field: 'schoolAddress1', headerName: '주소1', flex: 1 },
        // 우편번호는 모바일에서 숨김
        !isMobile && { field: 'schoolAddress2', headerName: '주소2', flex: 1 },
        {
            field: 'actions',
            headerName: '등록하기',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpen(params.row)}
                    sx={{ fontSize: '1.2rem' }}
                >
                    학교관리자 등록
                </Button>
            ),
            width: isMobile ? 150 : 200,
            flex: isMobile ? 0 : undefined
        }
    ].filter(Boolean); // undefined 필드를 제거

    const handleOpen = (record) => {
        setSelectedSchoolCode(record.schoolCode);
        setSelectedSchoolName(record.schoolName);
        setIsManagerModalOpen(true);
    };

    const schoolGradeOptions = [
        { schoolGrade: 'E', name: '초' },
        { schoolGrade: 'M', name: '중' },
        { schoolGrade: 'H', name: '고' },
    ];

    const handleSearch = (overrideSchoolName = null) => {
        const paramsToSearch = { ...searchParam, schoolName: overrideSchoolName !== null ? overrideSchoolName : searchParam.schoolName };

        api.post("/schoolmgr/school/search", paramsToSearch)
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => {
                console.error("Search API fetch error:", error);
            });
    };

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(inputRef.current.value);
            e.preventDefault(); // 폼의 기본 제출 동작 방지
          
        }
    };

    const handleOpenRegisterModal = () => {
        setIsRegisterModalOpen(true);
    };

    const handleCloseRegisterModal = () => {
        setIsRegisterModalOpen(false);
    };

    const handleCloseManagerModal = () => {
        setIsManagerModalOpen(false);
    };

    return (
        <div className="wrap">
            <div id="container" className="container page-admin">
                <div className="content-w-form">
                    <h2 className="title-type001">
                        <strong className="tit01">학교등록</strong>
                    </h2>
                    <div className="form-inner">
                        <fieldset className="fieldset-type01">
                            <div className="select-wrap">
                                <select
                                    className="select-type01"
                                    value={searchParam.schoolGrade}
                                    onChange={(e) => setSearchParam({ ...searchParam, schoolGrade: e.target.value })}
                                >
                                    <option value="" disabled>학교급을 선택하세요</option>
                                    {schoolGradeOptions.map(option => (
                                        <option key={option.schoolGrade} value={option.schoolGrade}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <form className="input-group01" onSubmit={(e) => e.preventDefault()}>
                                <div className="input-type01">
                                    <label htmlFor="searchSchool" className="hide">학교명</label>
                                    <input
                                        id="searchSchool"
                                        type="text"
                                        placeholder="학교명"
                                        value={searchParam.schoolName}
                                        onChange={(e) => setSearchParam({ ...searchParam, schoolName: e.target.value })}
                                        ref={inputRef}
                                        onKeyDown={handleKeyPress}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn-type005 blue"
                                    onClick={() => handleSearch(inputRef.current.value)}
                                >
                                    검색
                                </button>
                            </form>
                        </fieldset>

                        <div className="formBox-notice">
                            <div className="title-type002">
                                <strong className="tit01">학교급을 올바르게 선택하고 소속 학교를 검색하세요.</strong>
                                <strong className="tit01">소속 학교가 검색되지 않으면 학교등록을 진행하세요.</strong>
                            </div>
                        </div>

                        <div className="formDatagrid-w" style={{ backgroundColor: '#fff', height: '500px' }}>
                            {data && data.length > 0 ? (
                                <DataGrid
                                    autoHeight
                                    rows={data}
                                    columns={columns}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    pageSizeOptions={[5, 10, 20]}
                                    onPageChange={handlePageChange}
                                    getRowId={(row) => row.schoolCode}
                                    sx={{
                                        '& .MuiDataGrid-columnHeaders': {
                                            backgroundColor: 'lightgray',
                                            fontSize: '1.2rem',
                                        },
                                        '& .MuiDataGrid-cell': {
                                            fontSize: '1.1rem',
                                        },
                                        backgroundColor: '#fff',
                                    }}
                                />
                            ) : (
                                <>
                                    <div className="title-type002">
                                        <strong className="tit01">검색된 학교가 없습니다.</strong>
                                    </div>
                                    <div className="btns-type01 blue">
                                        <button type="button" className="btn-type005 blue" onClick={handleOpenRegisterModal}>
                                            <span className="txt">학교등록을 진행합니다</span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={isRegisterModalOpen} onClose={handleCloseRegisterModal}>
                <DialogContent>
                    <ManagerSchoolRegisterInput
                        open={isRegisterModalOpen}
                        schoolName={searchParam.schoolName}
                        onClose={handleCloseRegisterModal}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={isManagerModalOpen} onClose={handleCloseManagerModal}>
                <DialogContent>
                    <ManagerRegisterInput
                        open={isManagerModalOpen}
                        schoolCode={selectedSchoolCode}
                        schoolName={selectedSchoolName}
                        onClose={handleCloseManagerModal}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ManagerSchoolRegister;