import React, { useState, useEffect } from 'react';
import api from './apiService';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function ManagerSchoolDetail({ schoolCode, currentSection }) {
    const [initialSchoolData, setInitialSchoolData] = useState({});
    const [schoolData, setSchoolData] = useState(null);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        message: '',
    });
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const handleDeleteClick = () => {
        setDeleteConfirmOpen(true);
    };

    const handleModalClose = () => {
        setModal({ ...modal, open: false });
    };

    useEffect(() => {
        if (schoolCode) {
            const fetchData = async () => {
                const response = await api.get(`/schoolmgr/school/${schoolCode}`);
                setInitialSchoolData(response.data.data);
                setSchoolData(response.data.data);
            };
            fetchData();
        }
    }, [schoolCode]);

    const handleCancel = () => {
        setSchoolData(initialSchoolData);
    };

    const handleSave = async () => {
        const dataToSave = {
            schoolCode: schoolData.schoolCode,
            schoolName: schoolData.schoolName,
            schoolGrade: schoolData.schoolGrade,
            schoolZipcode: schoolData.schoolZipcode,
            schoolAddress1: schoolData.schoolAddress1,
            schoolAddress2: schoolData.schoolAddress2,
            teacherPasswd: schoolData.teacherPasswd,
        };

        try {
            const response = await api.put(`/schoolmgr/school/${schoolData.schoolCode}`, dataToSave);

            if (response.status === 200) {
                setModal({
                    open: true,
                    title: '성공',
                    message: '학교 정보가 저장되었습니다.',
                });
            } else {
                setModal({
                    open: true,
                    title: '오류',
                    message: response.data.message || '학교 정보 저장에 실패했습니다.',
                });
            }
        } catch (error) {
            setModal({
                open: true,
                title: '오류',
                message: error.response?.data?.message || '저장 중 오류가 발생했습니다.',
            });
        }
    };

    const handleConfirmDelete = async () => {
        setDeleteConfirmOpen(false);
        try {
            const response = await api.delete(`/schoolmgr/school/${schoolCode}`);
            if (response.status === 200) {
                setModal({
                    open: true,
                    title: '삭제 완료',
                    message: '정상적으로 삭제되었습니다.',
                });
            } else {
                setModal({
                    open: true,
                    title: '오류 발생',
                    message: '삭제에 실패했습니다.',
                });
            }
        } catch (error) {
            setModal({
                open: true,
                title: '오류 발생',
                message: '삭제 중 오류가 발생했습니다. 운영관리자에게 문의하세요.',
            });
        }
    };

    if (!schoolData) return null;

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 학교관리 &gt; {currentSection}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">학교 정보</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">학교코드</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="schoolCode" className="hide">학교코드</label>
                                <input
                                    id="schoolCode"
                                    type="text"
                                    placeholder="학교코드를 입력하세요"
                                    value={schoolData.schoolCode}
                                    disabled
                                />
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">학교명</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="schoolName" className="hide">학교명</label>
                                <input
                                    id="schoolName"
                                    type="text"
                                    placeholder="학교명을 입력하세요"
                                    value={schoolData.schoolName}
                                    onChange={(e) => setSchoolData({ ...schoolData, schoolName: e.target.value })}
                                />
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">학교주소1</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="schoolAddress1" className="hide">학교주소1</label>
                                <input
                                    id="schoolAddress1"
                                    type="text"
                                    placeholder="학교주소1을 입력하세요"
                                    value={schoolData.schoolAddress1}
                                    onChange={(e) => setSchoolData({ ...schoolData, schoolAddress1: e.target.value })}
                                />
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">학교주소2</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="schoolAddress2" className="hide">학교주소2</label>
                                <input
                                    id="schoolAddress2"
                                    type="text"
                                    placeholder="학교주소2를 입력하세요"
                                    value={schoolData.schoolAddress2}
                                    onChange={(e) => setSchoolData({ ...schoolData, schoolAddress2: e.target.value })}
                                />
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">우편번호</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="schoolZipcode" className="hide">우편번호</label>
                                <input
                                    id="schoolZipcode"
                                    type="text"
                                    placeholder="우편번호를 입력하세요"
                                    value={schoolData.schoolZipcode}
                                    onChange={(e) => setSchoolData({ ...schoolData, schoolZipcode: e.target.value })}
                                />
                            </div>
                        </form>

                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">학교급</strong>
                            </div>
                            <div className="radio-form">
                                <span className="input-radio01">
                                    <input
                                        type="radio"
                                        id="type01"
                                        name="schoolGrade"
                                        className="radio01"
                                        value="E"
                                        checked={schoolData.schoolGrade === 'E'}
                                        onChange={(e) => setSchoolData({ ...schoolData, schoolGrade: e.target.value })}
                                    />
                                    <label htmlFor="type01" className="txt-label01">초</label>
                                </span>
                                <span className="input-radio01">
                                    <input
                                        type="radio"
                                        id="type02"
                                        name="schoolGrade"
                                        className="radio01"
                                        value="M"
                                        checked={schoolData.schoolGrade === 'M'}
                                        onChange={(e) => setSchoolData({ ...schoolData, schoolGrade: e.target.value })}
                                    />
                                    <label htmlFor="type02" className="txt-label01">중</label>
                                </span>
                                <span className="input-radio01">
                                    <input
                                        type="radio"
                                        id="type03"
                                        name="schoolGrade"
                                        className="radio01"
                                        value="H"
                                        checked={schoolData.schoolGrade === 'H'}
                                        onChange={(e) => setSchoolData({ ...schoolData, schoolGrade: e.target.value })}
                                    />
                                    <label htmlFor="type03" className="txt-label01">고</label>
                                </span>
                            </div>
                        </form>

                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">교원인증번호(자연수 5자리로 표현)</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="teacherPasswd" className="hide">교원인증번호</label>
                                <input
                                    id="teacherPasswd"
                                    type="text"
                                    placeholder="교원인증번호를 입력하세요"
                                    value={schoolData.teacherPasswd}
                                    onChange={(e) => setSchoolData({ ...schoolData, teacherPasswd: e.target.value })}
                                />
                            </div>
                        </form>
                    </fieldset>

                    <div className="btns-group03">
                        <button type="button" onClick={handleSave} className="btn-type005 blue"><span className="txt">저장</span></button>
                        <button type="button" onClick={handleDeleteClick} className="btn-type005 border"><span className="txt">삭제</span></button>
                        <button type="button" onClick={handleCancel} className="btn-type005 border"><span className="txt">취소</span></button>
                    </div>

                    <Dialog
                        open={modal.open}
                        onClose={handleModalClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{modal.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {modal.message}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleModalClose} color="primary">
                                닫기
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={deleteConfirmOpen}
                        onClose={() => setDeleteConfirmOpen(false)}
                        aria-labelledby="confirm-dialog-title"
                        aria-describedby="confirm-dialog-description"
                    >
                        <DialogTitle id="confirm-dialog-title">정말로 삭제하시겠습니까?</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                                아니오
                            </Button>
                            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                                예
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default ManagerSchoolDetail;