import React from 'react';
import { Box, Paper, Grid, useTheme } from '@mui/material';

const StudentLinks = ({ schoolGrade }) => {
    const theme = useTheme();
    const isElementary = schoolGrade === 'E';

    return (
        <>
            <Box mb={4} sx={{ maxWidth: '100vw', margin: '0' }}>
                <Grid className="grid-container-print" container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '100vw' }}>
                            {isElementary ? (
                                <>
                                    <img
                                        src="/assets/links/ES/all00.png"
                                        alt="웹툰 이미지"
                                        useMap="#webtoon-map"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                    <map name="webtoon-map">
                                        <area
                                            shape="rect"
                                            coords="442,983,683,1249" // 웹툰 2편 QR 코드 영역
                                            href="https://m.site.naver.com/1geJ3"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt="웹툰 2편"
                                        />
                                        <area
                                            shape="rect"
                                            coords="684,983,923,1249" // 웹툰 3편 QR 코드 영역
                                            href="https://m.site.naver.com/1geJ7"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt="웹툰 3편"
                                        />
                                    </map>
                                </>
                            ) : (
                                <a href="https://sites.google.com/view/hangp" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src="/assets/links/MS/all00.jpg"
                                        alt="온라인 방탈출게임"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                </a>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default StudentLinks;