import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import StudentSchoolSearch from './StudentSchoolSearch';


function StudentSignUp() {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const location = useLocation();


  useEffect(() => {
    // 라우트의 상태를 검사하여 selectedMenu를 설정
    const menu = location.state?.selectedMenu || "학생등록";
    setSelectedMenu(menu);
    console.log(menu);
  }, [location]);

  return (
    <div>
      <Header setSelectedMenu={setSelectedMenu} />
      {selectedMenu === "학생등록" && <StudentSchoolSearch />}   
      {<Footer />}
    </div>
  );
}

export default StudentSignUp;