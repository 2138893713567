import React, { useState, useRef, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import api from './apiService';
import SchoolDetail from './SchoolDetail.js';

function SchoolSearch({ selectedMenu }) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const [searchParam, setSearchParam] = useState({
        schoolCode: "",
        schoolName: "",
        schoolGrade: "",
        schoolZipcode: "",
        schoolAddress1: "",
        schoolAddress2: "",
        teacherPasswd: ""
    });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSchoolCode, setSelectedSchoolCode] = useState(null);

    const columns = [
        { field: 'schoolCode', headerName: '학교코드', flex: 1 },
        { field: 'schoolName', headerName: '학교명', flex: 1.5 },
        { field: 'schoolGrade', headerName: '학교급', flex: 1 },
        { field: 'schoolZipcode', headerName: '우편번호', flex: 1 },
        { field: 'schoolAddress1', headerName: '주소1', flex: 1 },
        { field: 'schoolAddress2', headerName: '주소2', flex: 1 },
        { field: 'teacherPasswd', headerName: '학교교원인증번호', flex: 1 },
    ];

    const schoolGradeOptions = [
        { schoolGrade: 'E', name: '초등' },
        { schoolGrade: 'M', name: '중등' },
        { schoolGrade: 'H', name: '고등' },
    ];

    const handleSearch = (overrideSchoolName = null) => {
        if (!searchParam.schoolGrade) {
            alert('학교급을 선택하세요.');
            return;
        }

        const paramsToSearch = {
            ...searchParam,
            schoolName: overrideSchoolName !== null ? overrideSchoolName : searchParam.schoolName,
        };

        setIsLoading(true);

        api.post("/schoolmgr/school/search", paramsToSearch)
            .then(response => {
                const schoolDatas = response.data.data.map(item => ({ ...item, id: item.schoolCode }));
                setData(schoolDatas);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("API fetch error:", error);
                setIsLoading(false);
            });
    };

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    const handleRowClick = (params) => {
        setSelectedSchoolCode(params.row.schoolCode);
        setOpenDialog(true);
    };

    const inputRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e.target.value);
            e.preventDefault();
        }
    };

    const filteredOptions = schoolGradeOptions.filter(option => option.schoolGrade === searchParam.schoolGrade);

    return (
        <div className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 학교관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type01">
                        <div className="select-wrap">
                            <select
                                name="schoolGrade"
                                className="select-type01"
                                value={searchParam.schoolGrade}
                                onChange={(e) => setSearchParam({ ...searchParam, schoolGrade: e.target.value })}
                            >
                                <option value="" disabled>학교급을 선택하세요</option>
                                {schoolGradeOptions.map(option => (
                                    <option key={option.schoolGrade} value={option.schoolGrade}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <form className="input-group01">
                            <div className="input-type01">
                                <label htmlFor="searchSchool" className="hide">학교명</label>
                                <input
                                    id="searchSchool"
                                    type="text"
                                    placeholder="학교명"
                                    defaultValue={searchParam.schoolName}
                                    ref={inputRef}
                                    onKeyDown={handleKeyPress}
                                />
                            </div>
                            <button
                                type="button"
                                className="btn-type005 blue"
                                onClick={() => handleSearch(inputRef.current.value)}
                            >
                                <span className="txt01">검색</span>
                            </button>
                        </form>
                    </fieldset>
                </div>

                <div style={{ width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 20]}
                        onPageChange={handlePageChange}
                        getRowId={(row) => row.schoolCode}
                        onRowClick={handleRowClick}
                        loading={isLoading}
                    />
                </div>

                {openDialog && <SchoolDetail
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                        setSelectedSchoolCode(null);
                    }}
                    schoolCode={selectedSchoolCode}
                    refreshSchoolList={handleSearch}
                    searchParams={searchParam}
                />}
            </div>
        </div>
    );
}

export default SchoolSearch;