import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Modal, Box, Typography } from '@mui/material';
import api from './apiService';
import { styled } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { MenuItem } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '30%',
        maxWidth: '800px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

function SurveyEventEditDialog({ open, onClose, eventId }) {
    const [eventData, setEventData] = useState(null);
    const [modalMessage, setModalMessage] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (eventId) {
            const fetchData = async () => {
                const response = await api.get(`/surveyevent/${eventId}`);
                setEventData(response.data.data);
            };
            fetchData();
        }
    }, [eventId]);

    const handleSave = async () => {
        // 유효성 검사
        if (!eventData.eventTitle || !eventData.eventStartDate || !eventData.eventEndDate || !eventData.schoolGrade || !eventData.surveyTarget || !eventData.survey.surveyId) {
            setModalMessage('모든 필드를 입력해야 합니다.');
            setModalOpen(true);
            return;
        }

        if (!window.confirm('저장하시겠습니까?')) return;
        try {
            const dataToSave = {
                eventId: eventData.eventId,
                eventTitle: eventData.eventTitle,
                eventStartDate: eventData.eventStartDate,
                eventEndDate: eventData.eventEndDate,
                schoolGrade: eventData.schoolGrade,
                surveyTarget: eventData.surveyTarget,
                surveyId: eventData.survey.surveyId
            };
            await api.put(`/surveyevent/${eventId}`, dataToSave);
            setModalMessage('저장했습니다.');
            setModalOpen(true);
        } catch (error) {
            setModalMessage('저장 중에 오류가 발생했습니다.');
            setModalOpen(true);
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('삭제하시겠습니까? 주의 : 학교 응답 정보가 있으면 삭제되지 않습니다.')) return;
        try {
            const response = await api.delete(`/surveyevent/${eventId}`);
            if (response.status === 204) {
                setModalMessage('삭제되었습니다.');
                setTimeout(onClose, 2000);
            }
            setModalOpen(true);
        } catch (error) {
            let errorMessage = '삭제 중에 오류가 발생했습니다.';
            if (error.response && error.response.status === 500) {
                errorMessage = '에러가 발생했습니다. 해당 이벤트로 응답이 있습니다. 삭제를 하려면 해당 응답을 각 학교에서 담임선생님이 삭제하도록 해야 합니다.';
            }
            setModalMessage(errorMessage);
            setModalOpen(true);
        }
    };

    const MessageModal = () => (
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                <Typography id="modal-title" variant="h6" component="h2">
                    {modalMessage.includes('에러') ? '오류' : '성공'}
                </Typography>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                    {modalMessage}
                </Typography>
                <Button onClick={() => setModalOpen(false)}>확인</Button>
            </Box>
        </Modal>
    );


    if (!eventData) return null;  // 데이터가 없으면 아무것도 표시하지 않습니다.


    const currentDate = new Date(); // 현재 날짜와 시간을 가져옵니다.
    const eventStartDate = new Date(eventData.eventStartDate);
    const eventEndDate = new Date(eventData.eventEndDate);

    const isBeforeStartDate = currentDate < eventStartDate;
    const isBetweenStartDateAndEndDate = currentDate >= eventStartDate && currentDate <= eventEndDate;
    const isAfterEndDate = currentDate > eventEndDate;

    const surveyOptions = [
            { surveyId: 'ES0001', name: 'A검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 A검사지' },
            { surveyId: 'ES0002', name: 'B검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 B검사지' },
            { surveyId: 'ES0003', name: 'C검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 C검사지' },
            { surveyId: 'ES0004', name: 'D검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 D검사지' },
            // 학생 E검사지 추가 2024.02.08
            { surveyId: 'ES0005', name: 'E검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 E검사지' },
            { surveyId: 'ET0001', name: 'A검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 A검사지' },
            { surveyId: 'ET0002', name: 'B검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 B검사지' },
            { surveyId: 'ET0003', name: 'C검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 C검사지' },
            { surveyId: 'ET0004', name: 'D검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 D검사지' },
            // 교원 E검사지 추가 2024.02.08
            { surveyId: 'ET0005', name: 'E검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 E검사지' },
            { surveyId: 'HS0001', name: 'A검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 A검사지' },
            { surveyId: 'HS0002', name: 'B검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 B검사지' },
            { surveyId: 'HS0003', name: 'C검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 C검사지' },
            { surveyId: 'HS0004', name: 'D검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 D검사지' },
            // 학생 E검사지 추가 2024.02.08
            { surveyId: 'HS0005', name: 'E검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 E검사지' },
            { surveyId: 'HT0001', name: 'A검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 A검사지' },
            { surveyId: 'HT0002', name: 'B검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 B검사지' },
            { surveyId: 'HT0003', name: 'C검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 C검사지' },
            { surveyId: 'HT0004', name: 'D검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 D검사지' },
            // 교원 E검사지 추가 2024.02.08
            { surveyId: 'HT0005', name: 'E검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 E검사지' },
            { surveyId: 'MS0001', name: 'A검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 A검사지' },
            { surveyId: 'MS0002', name: 'B검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 B검사지' },
            { surveyId: 'MS0003', name: 'C검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 C검사지' },
            { surveyId: 'MS0004', name: 'D검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 D검사지' },
            // 학생 E검사지 추가 2024.02.08
            { surveyId: 'MS0005', name: 'E검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 E검사지' },
            { surveyId: 'MT0001', name: 'A검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 A검사지' },
            { surveyId: 'MT0002', name: 'B검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 B검사지' },
            { surveyId: 'MT0003', name: 'C검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 C검사지' },
            { surveyId: 'MT0004', name: 'D검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 D검사지' },
            // 교원 E검사지 추가 2024.02.08
            { surveyId: 'MT0005', name: 'E검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 E검사지' },
    ];

    const filteredOptions = surveyOptions.filter(option => option.schoolGrade === eventData.schoolGrade && option.target === eventData.surveyTarget);

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth={false}>
            <DialogTitle>검사이벤트 정보</DialogTitle>
            <DialogContent style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                overflow: 'auto',
                maxHeight: '70vh'
            }}>
                <TextField
                    label="Event ID"
                    fullWidth
                    value={eventData.eventId}
                    disabled={true}
                />

                <TextField
                    label="Event Title"
                    fullWidth
                    value={eventData.eventTitle}
                    onChange={(e) => setEventData({ ...eventData, eventTitle: e.target.value })}
                />

                <TextField
                    label="Start Date"
                    type="datetime-local"
                    fullWidth
                    value={eventData.eventStartDate}
                    onChange={(e) => {
                        let dateTimeValue = e.target.value;
                        if (dateTimeValue && dateTimeValue.length === 16) {
                            dateTimeValue += ":00";
                        }
                        setEventData({ ...eventData, eventStartDate: dateTimeValue });
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!isBeforeStartDate}
                />

                <TextField
                    label="End Date"
                    type="datetime-local"
                    fullWidth
                    value={eventData.eventEndDate}
                    onChange={(e) => {
                        let dateTimeValue = e.target.value;
                        if (dateTimeValue && dateTimeValue.length === 16) {
                            dateTimeValue += ":00";
                        }
                        setEventData({ ...eventData, eventEndDate: dateTimeValue });
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <FormControl component="fieldset" disabled={!isBeforeStartDate || isBetweenStartDateAndEndDate || isAfterEndDate}>
                    <FormLabel component="legend">School Grade</FormLabel>
                    <RadioGroup
                        row
                        value={eventData.schoolGrade}
                        onChange={(e) => setEventData({ ...eventData, schoolGrade: e.target.value })}
                    >
                        <FormControlLabel value="E" control={<Radio />} label="초" />
                        <FormControlLabel value="M" control={<Radio />} label="중" />
                        <FormControlLabel value="H" control={<Radio />} label="고" />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" disabled={isBetweenStartDateAndEndDate || isAfterEndDate}>
                    <FormLabel component="legend">Survey Target</FormLabel>
                    <RadioGroup
                        row
                        value={eventData.surveyTarget}
                        onChange={(e) => setEventData({ ...eventData, surveyTarget: e.target.value })}
                    >
                        <FormControlLabel value="S" control={<Radio />} label="학생" />
                        <FormControlLabel value="T" control={<Radio />} label="교원" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    select
                    label="Survey ID"
                    fullWidth
                    value={eventData.survey.surveyId}
                    onChange={(e) => setEventData({ ...eventData, survey: { ...eventData.survey, surveyId: e.target.value } })}
                    disabled={isBetweenStartDateAndEndDate || isAfterEndDate}
                >
                    <option value="">검사지를 선택하세요</option>
                    {filteredOptions.map(option => (
                        <MenuItem key={option.surveyId} value={option.surveyId}>
                            {option.name}({option.description})
                        </MenuItem>
                    ))}
                </TextField>

                <div style={{ display: 'flex', gap: '12px' }}>
                    <Button onClick={handleSave}>저장</Button>
                    <Button onClick={handleDelete}>삭제</Button>
                    <Button onClick={onClose}>닫기</Button>
                </div>
            </DialogContent>
            <MessageModal />
        </StyledDialog>
    );
}

export default SurveyEventEditDialog;
