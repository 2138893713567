import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from './apiService';
import StudentSurveyModal from './StudentSurveyModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

function StudentSurveyPage({ schoolCode, studentSeq, schoolGrade, schoolYear }) {
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const [open, setOpen] = useState(false);
    const [selectedParams, setSelectedParams] = useState(null);
    const [responseModalOpen, setResponseModalOpen] = useState(false);

    const modalContentRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleRowClick = (params) => {
        console.log(params.row);
        setSelectedParams(params.row);
        if (params.row.response === "응답함") {
            setResponseModalOpen(true);
        } else {
            setOpen(true);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        api.get(`/surveyevent/studentresponse?studentSeq=${studentSeq}`)
            .then(response => {
                if (response.error) {
                    console.error('Error fetching data:', response.data.error);
                    setEvents([]);
                } else {
                    const updatedEvents = response.data.data.map(event => {
                        event.response = event.response === null ? "응답하지 않음" : "응답함";
                        event.eventStartDate = new Date(event.eventStartDate).toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        event.eventEndDate = new Date(event.eventEndDate).toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        return event;
                    });
                    setEvents(updatedEvents);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setEvents([]);
                setIsLoading(false);
            });
    }, [studentSeq]);

    const reloadData = async () => {
        setIsLoading(true);
        api.get(`/surveyevent/studentresponse?studentSeq=${studentSeq}`)
            .then(response => {
                if (response.error) {
                    console.error('Error fetching data:', response.data.error);
                    setEvents([]);
                } else {
                    const updatedEvents = response.data.data.map(event => {
                        event.response = event.response === null ? "응답하지 않음" : "응답함";
                        event.eventStartDate = new Date(event.eventStartDate).toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        event.eventEndDate = new Date(event.eventEndDate).toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        return event;
                    });
                    setEvents(updatedEvents);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setEvents([]);
                setIsLoading(false);
            });
    };

    const columns = isMobile ? [
        { field: 'eventTitle', headerName: '검사이벤트명', flex: 2.5 },
        {
            field: 'response',
            headerName: '응답정보',
            flex: 1,
            renderCell: (params) => (
                params.value === "응답하지 않음" ? (
                    <Button variant="contained" color="primary" onClick={() => handleRowClick(params)} sx={{ fontSize: '1.2rem' }}>
                        검사하기
                    </Button>
                ) : (
                    <Button variant="contained" color="secondary" onClick={() => handleRowClick(params)} sx={{ fontSize: '1.2rem' }}>
                        검사완료
                    </Button>
                )
            )
        },
    ] : [
        { field: 'eventTitle', headerName: '검사이벤트명', flex: 2.5 },
        { field: 'eventStartDate', headerName: '시작일', flex: 1 },
        { field: 'eventEndDate', headerName: '종료일', flex: 1 },
        {
            field: 'response',
            headerName: '응답정보',
            flex: 1,
            renderCell: (params) => (
                params.value === "응답하지 않음" ? (
                    <Button variant="contained" color="primary" onClick={() => handleRowClick(params)} sx={{ fontSize: '1.2rem' }}>
                        검사하기
                    </Button>
                ) : (
                    <Button variant="contained" color="secondary" onClick={() => handleRowClick(params)} sx={{ fontSize: '1.2rem' }}>
                        검사완료
                    </Button>
                )
            )
        },
    ];

    return (
        <div className="container page-student">
            <div className="content-w-form">
                <h2 className="title-type001">
                    <strong className="tit01">학생 검사지 조회</strong>
                </h2>
                <div className="form-inner">
                    <div className="title-type003">
                        <strong className="tit01-blue">현재 진행중인 검사만 조회됩니다.</strong>
                    </div>
                    <div className="formDatagrid-w" style={{ width: '100%', height: '500px' }}>
                        <DataGrid
                            rows={events}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[5, 10, 20]}
                            getRowId={(row) => row.eventId}
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'lightgray',
                                    fontSize: '1.2rem',
                                },
                                '& .MuiDataGrid-cell': {
                                    fontSize: '1.1rem',
                                },
                                backgroundColor: '#fff',
                            }}
                            loading={isLoading}
                            slots={{
                                noRowsOverlay: () => (
                                    <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                        <strong className="tit01">조회된 데이터가 없습니다.</strong>
                                    </div>
                                )
                            }}
                        />
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="survey-modal-title"
                aria-describedby="survey-modal-description"
            >
                <Box sx={style}>
                    <StudentSurveyModal
                        open={open}
                        onClose={() => setOpen(false)}
                        onSuccessfulSubmit={reloadData}
                        params={selectedParams}
                        schoolCode={schoolCode}
                        studentSeq={studentSeq}
                        schoolYear={schoolYear}
                    />
                </Box>
            </Modal>
            <Modal
                open={responseModalOpen}
                onClose={() => setResponseModalOpen(false)}
                aria-labelledby="response-modal-title"
                aria-describedby="response-modal-description"
            >
                <div className="modal-w active" aria-modal="true">
                    <div className="modal-inner-type25">
                        <div className="title-type001">
                            <strong className="tit01">알림</strong>
                        </div>
                        <div className="title-type003">
                            <strong className="tit01-grey">이미 응답했습니다. 검사결과에서 확인하세요.</strong>
                        </div>
                        <button type="button" className="btn-type005 border close" onClick={() => setResponseModalOpen(false)}>
                            <span className="txt01">닫기</span>
                        </button>
                    </div>
                    <i className="dimm"></i>
                </div>
            </Modal>
        </div>
    );
}

export default StudentSurveyPage;