import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '30%',
        maxWidth: '800px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

function ManagerSchoolRegisterInput({ open, onClose, schoolName }) {
    const [schoolData, setSchoolData] = useState({
        schoolCode: "",
        schoolName: schoolName,
        schoolAddress1: "",
        schoolAddress2: "",
        schoolZipcode: "",
        schoolGrade: "",
        teacherPasswd: "",
    });

    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setSchoolData(prevSchoolData => ({
            ...prevSchoolData,
            schoolName: schoolName
        }));
    }, [schoolName]);

    const handleSave = async () => {
        if (!schoolData.schoolCode) {
            alert('학교코드를 입력하세요.');
            return;
        }
        if (!schoolData.schoolName) {
            alert('학교명을 입력하세요.');
            return;
        }
        if (!schoolData.schoolGrade) {
            alert('학교급을 선택하세요.');
            return;
        }

        if (!window.confirm('학교정보를 등록하시겠습니까?')) {
            return;
        }

        const dataToSave = {
            schoolCode: schoolData.schoolCode,
            schoolName: schoolData.schoolName,
            schoolAddress1: schoolData.schoolAddress1,
            schoolAddress2: schoolData.schoolAddress2,
            schoolZipcode: schoolData.schoolZipcode,
            schoolGrade: schoolData.schoolGrade,
            teacherPasswd: "",
        };

        try {
            const response = await api.post(`/schoolmgr/school`, dataToSave);
            if (response.status >= 200 && response.status < 300) {
                alert('학교등록에 성공했습니다. 학교 검색을 진행하세요.');
                setSuccessDialogOpen(false);
                onClose();
            } else {
                alert(`학교등록에 실패했습니다. 상태 코드: ${response.status}`);
            }
        } catch (error) {
            alert(`학교등록에 실패했습니다. 오류 메시지: ${error.message}`);
            console.error("There was an error saving the data", error);
        }
    };

    const handleCloseErrorDialog = () => {
        setErrorDialogOpen(false);
    };

    const SuccessDialog = () => (
        <Dialog
            open={successDialogOpen}
            onClose={() => setSuccessDialogOpen(false)}
            aria-labelledby="success-dialog-title"
            aria-describedby="success-dialog-description"
        >
            <DialogTitle id="success-dialog-title">Success</DialogTitle>
            <DialogContent>
                <DialogContentText id="success-dialog-description">
                    {successMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSuccessDialogOpen(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    const ErrorDialog = () => (
        <Dialog
            open={errorDialogOpen}
            onClose={handleCloseErrorDialog}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <DialogTitle id="error-dialog-title">Error</DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-description">
                    {errorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseErrorDialog} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <StyledDialog open={open} onClose={onClose} maxWidth={false}>
                <DialogTitle>학교 정보 입력</DialogTitle>
                <DialogContent style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    overflow: 'auto',
                    maxHeight: '70vh'
                }}>
                    <TextField
                        label="학교코드(예, H1234567890, 학교알리미 정보공시 코드)"
                        fullWidth
                        value={schoolData.schoolCode}
                        onChange={(e) => setSchoolData({ ...schoolData, schoolCode: e.target.value })}
                    />

                    <TextField
                        label="학교명(예, 홍길동초등학교)"
                        fullWidth
                        value={schoolData.schoolName}
                        onChange={(e) => setSchoolData({ ...schoolData, schoolName: e.target.value })}
                    />

                    <TextField
                        label="학교주소1(예, 서울특별시 중구 남대문로 120)"
                        fullWidth
                        value={schoolData.schoolAddress1}
                        onChange={(e) => setSchoolData({ ...schoolData, schoolAddress1: e.target.value })}
                    />

                    <TextField
                        label="학교주소2(예, 을지로 1가 1번지)"
                        fullWidth
                        value={schoolData.schoolAddress2}
                        onChange={(e) => setSchoolData({ ...schoolData, schoolAddress2: e.target.value })}
                    />

                    <TextField
                        label="우편번호(예, 04524)"
                        fullWidth
                        value={schoolData.schoolZipcode}
                        onChange={(e) => setSchoolData({ ...schoolData, schoolZipcode: e.target.value })}
                    />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">학교급</FormLabel>
                        <RadioGroup
                            row
                            value={schoolData.schoolGrade}
                            onChange={(e) => setSchoolData({ ...schoolData, schoolGrade: e.target.value })}
                        >
                            <FormControlLabel value="E" control={<Radio />} label="초" />
                            <FormControlLabel value="M" control={<Radio />} label="중" />
                            <FormControlLabel value="H" control={<Radio />} label="고" />
                        </RadioGroup>
                    </FormControl>

                    <div style={{ display: 'flex', gap: '12px' }}>
                        <Button onClick={handleSave}>저장</Button>
                        <Button onClick={onClose}>닫기</Button>
                    </div>
                </DialogContent>
            </StyledDialog>
            <SuccessDialog />
            <ErrorDialog />
        </>
    );
}

export default ManagerSchoolRegisterInput;
