import * as React from "react";

function LoginTest(props) {
  return (
    <>
       <div className="div">
        <div className="div-2">
          <div className="div-3">
            <div className="div-4">
              <div className="div-5">
                <img
                  loading="lazy"
                  src="/assets/toplogo.png"
                  className="img"
                />
                <div className="div-6">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/2611e429-c539-4e0b-9000-ad681b6f29ac?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-2"
                  />
                  <div className="div-7">홈</div>
                </div>
                <div className="div-8">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c335ab32-9dbe-4193-83bb-44968a52843d?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-3"
                  />
                  <div className="div-9">로그인</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-10">
            <div className="div-11">
              <div className="div-12">
                <div className="div-13">검사소개</div>
                <div className="div-14">사용방법</div>
              </div>
            </div>
          </div>
          <div className="div-15">로그인</div>
          <div className="div-16">
            <div className="div-17">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ec74eb33-1a73-4864-85be-772947cbdc73?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                className="img-4"
              />
              <div className="div-18">교원</div>
            </div>
            <div className="div-19">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/987e01dd-5484-4eba-a22d-a65d328c069e?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                className="img-5"
              />
              <div className="div-20">학교관리자</div>
            </div>
            <div className="div-21">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/cabdbaeb-186b-4877-8191-17c332eb119b?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cabdbaeb-186b-4877-8191-17c332eb119b?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cabdbaeb-186b-4877-8191-17c332eb119b?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cabdbaeb-186b-4877-8191-17c332eb119b?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cabdbaeb-186b-4877-8191-17c332eb119b?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cabdbaeb-186b-4877-8191-17c332eb119b?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cabdbaeb-186b-4877-8191-17c332eb119b?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cabdbaeb-186b-4877-8191-17c332eb119b?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                className="img-6"
              />
              <div className="div-22">교육청</div>
            </div>
          </div>
          <div className="div-23">
            <div className="div-24">
              <div className="div-25">아이디</div>
            </div>
            <div className="div-26">
              <div className="div-27" />
            </div>
          </div>
          <div className="div-28">
            <div className="div-29">
              <div className="div-30">비밀번호</div>
            </div>
            <div className="div-31">
              <div className="div-32" />
            </div>
          </div>
          <div className="div-33">
            <div className="div-34">로그인</div>
          </div>
        </div>
        <div className="div-35">
          <div className="div-36">
            <div className="div-37">
              <div className="div-38">
                <div className="column">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8b5fb495-b76a-434f-8dac-2ec85d34f35c?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-7"
                  />
                </div>
                <div className="column-2">
                  <div className="div-39">
                    개인정보처리방침 | <br />
                    <br />
                    우:12345 ) 인천광역시 계양구 계산로 62
                    <br />
                    Copyright GYEONGIN NATIONAL UNIVERSITY OF EDUCATION ALL
                    RIGHTS RESERVED
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </>
  );
}

export default LoginTest;