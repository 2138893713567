import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
//import Footer from './components/Footer';
import StudentSurveyPage from './StudentSurveyPage';
import StudentSurveyResultPage from './StudentSurveyResultPage';
import StudentMyInfo from './StudentMyInfo';

function Student() {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [schoolCode, setSchoolCode] = useState(null);
  const [studentSeq, setStudentSeq] = useState(null);
  const [schoolGrade, setSchoolGrade] = useState(null);
  const [schoolYear, setSchoolYear] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // URL 쿼리에서 선택된 메뉴를 읽어와 상태를 설정합니다.
    const queryParams = new URLSearchParams(location.search);
    const menuFromURL = queryParams.get('menu');
    if (menuFromURL) {
      setSelectedMenu(menuFromURL);
    } else if (location.state?.selectedMenu) {
      // URL 쿼리가 없다면, location.state에서 선택된 메뉴를 읽어옵니다.
      setSelectedMenu(location.state.selectedMenu);
    } else {
      changeMenu('검사지조회');
    }

  }, [location]);

  useEffect(() => {
    const storedSchoolCode = localStorage.getItem('schoolCode');
    const storedStudentSeq = localStorage.getItem('studentSeq');
    const storedSchoolGrade = localStorage.getItem('schoolGrade');
    const storedSchoolYear = localStorage.getItem('schoolYear');
    
    if (storedSchoolCode) setSchoolCode(storedSchoolCode);
    if (storedStudentSeq) setStudentSeq(storedStudentSeq);
    if (storedSchoolGrade) setSchoolGrade(storedSchoolGrade);
    if (storedSchoolYear) setSchoolYear(storedSchoolYear);

    // selectedMenu 상태가 설정되지 않았고, 필요한 정보가 모두 있는 경우,
    // selectedMenu를 "검사지조회"로 설정합니다.
    if (!selectedMenu && storedStudentSeq && storedSchoolGrade && storedSchoolYear) {
      setSelectedMenu("검사지조회");
    } else if (!storedStudentSeq || !storedSchoolGrade || !storedSchoolYear) {
      // 필요한 정보가 없는 경우, 사용자를 /studentsignup 페이지로 리디렉션합니다.
      //window.location.href = '/studentsignup';
      navigate('/studentsignup');
    }
  }, [selectedMenu]); // selectedMenu와 history를 의존성 배열에 추가합니다.

  const changeMenu = (menu) => {
    setSelectedMenu(menu);
    // URL을 업데이트하면서 선택된 메뉴를 쿼리 파라미터로 추가합니다.
    navigate(`/student?menu=${menu}`, { state: { selectedMenu: menu } });
  };

  return (
    <div>
      <Header setSelectedMenu={changeMenu} />

      {selectedMenu === "검사지조회" && <StudentSurveyPage schoolCode={schoolCode} studentSeq={studentSeq} schoolGrade={schoolGrade} schoolYear={schoolYear} />}
      {selectedMenu === "본인" && <StudentSurveyResultPage schoolCode={schoolCode} studentSeq={studentSeq} />}
      {selectedMenu === "내정보" && <StudentMyInfo />}

      {/* 다른 메뉴에 대한 컴포넌트도 이와 비슷한 방식으로 조건부 렌더링 할 수 있습니다. */}

      {/*<Footer />*/}
    </div>
  );
}

export default Student;
