import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useMediaQuery } from '@mui/material';
import api from './apiService';
import TeacherSurveyResultStudentDetail from './TeacherSurveyResultStudentDetail';
import iconSearch from './image/icon_search.svg'; // 아이콘 경로

function TeacherSurveyResultStudent({ schoolCode, teacherId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSchoolCode, setSelectedSchoolCode] = useState(null);

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleSearch = () => {
        api.post("/surveyresult/school/student?schoolCode=" + schoolCode)
            .then(response => {
                const studentData = response.data.data.map(item => ({ ...item, id: item.eventId }));
                setData(studentData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("API fetch error:", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        handleSearch();
    }, []);

    const handleRowClick = (params) => {
        setSelectedEventId(params.id);
        setSelectedSchoolCode(params.row.schoolCode);
        setOpenDialog(true);
    };

    const columns = isMobile ? [
        { field: 'schoolName', headerName: '학교명', flex: 1 },
        { field: 'totalResponses', headerName: '응답수', flex: 1 },
        {
            field: 'details',
            headerName: '상세보기',
            flex: 0.5,
            renderCell: (params) => (
                <img
                    src={iconSearch}
                    alt="상세보기"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRowClick(params)}
                />
            ),
        },
    ] : [
        { field: 'schoolName', headerName: '학교', flex: 1 },
        { field: 'eventTitle', headerName: '검사이벤트', flex: 1.5 },
        { field: 'totalResponses', headerName: '총 응답수', flex: 0.5 },
        { field: 'totalAces', headerName: 'ACES', flex: 0.5 },
        { field: 'totalMidd', headerName: 'MIDD', flex: 0.5 },
        { field: 'totalRase', headerName: 'RASE', flex: 0.5 },
        {
            field: 'details',
            headerName: '상세보기',
            flex: 0.5,
            renderCell: (params) => (
                <img
                    src={iconSearch}
                    alt="상세보기"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRowClick(params)}
                />
            ),
        },
    ];

    return (
        <div className="container page-student">
            <div className="content-w-form">
                <h2 className="title-type001">
                    <strong className="tit01">학생 검사 결과</strong>
                </h2>
                <div className="form-inner">
                    <div className="formDatagrid-w" style={{ width: '100%', height: '500px', backgroundColor: '#fff' }}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[5, 10, 20]}
                            getRowId={(row) => row.eventId}
                            loading={isLoading}
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'lightgray',
                                    fontSize: '1.2rem',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                },
                                '& .MuiDataGrid-cell': {
                                    fontSize: '1.1rem',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    backgroundColor: '#fff',
                                },
                                '& .MuiDataGrid-overlay': {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                },
                                backgroundColor: '#fff',
                            }}
                            slots={{
                                noRowsOverlay: () => (
                                    <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                        <strong className="tit01">조회된 데이터가 없습니다.</strong>
                                    </div>
                                )
                            }}
                        />
                    </div>
                </div>
            </div>
            {openDialog &&

                <TeacherSurveyResultStudentDetail
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    eventId={selectedEventId}
                    schoolCode={selectedSchoolCode}
                    teacherId={teacherId}
                />
            }
        </div>
    );
}

export default TeacherSurveyResultStudent;