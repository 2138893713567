import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './apiService';

function SupportOfficeManagerCreate({ selectedMenu }) {
    const [supporterID, setsupporterID] = useState('');
    const [supporterPasswd, setsupporterPasswd] = useState('');
    const [supportOfficeNo, setSupportOfficeNo] = useState('');
    const [regionCode, setRegionCode] = useState('');
    const [regionName, setRegionName] = useState('');
    const [supportOffices, setSupportOffices] = useState([]);
    const navigate = useNavigate();

    const eduOfficeMap = {
        'seoul': { no: '1', name: '서울특별시교육청', region_code: '01' },
        'busan': { no: '2', name: '부산광역시교육청', region_code: '02' },
        'daegu': { no: '3', name: '대구광역시교육청', region_code: '03' },
        'incheon': { no: '4', name: '인천광역시교육청', region_code: '04' },
        'gwangju': { no: '5', name: '광주광역시교육청', region_code: '05' },
        'daejeon': { no: '6', name: '대전광역시교육청', region_code: '06' },
        'ulsan': { no: '7', name: '울산광역시교육청', region_code: '07' },
        'sejong': { no: '8', name: '세종특별자치시교육청', region_code: '08' },
        'gyeonggi': { no: '10', name: '경기도교육청', region_code: '10' },
        'gangwon': { no: '11', name: '강원특별자치도교육청', region_code: '11' },
        'chungbuk': { no: '12', name: '충청북도교육청', region_code: '12' },
        'chungnam': { no: '13', name: '충청남도교육청', region_code: '13' },
        'jeonbuk': { no: '14', name: '전북특별자치도교육청', region_code: '14' },
        'jeonnam': { no: '15', name: '전라남도교육청', region_code: '15' },
        'gyeongbuk': { no: '16', name: '경상북도교육청', region_code: '16' },
        'gyeongnam': { no: '17', name: '경상남도교육청', region_code: '17' },
        'jeju': { no: '18', name: '제주특별자치도교육청', region_code: '18' }
    };

    useEffect(() => {
        const subdomain = window.location.hostname.split('.')[0];
        const defaultEduOffice = eduOfficeMap[subdomain] || { no: '', name: 'Unknown', region_code: '' };
        setRegionCode(defaultEduOffice.region_code);
        setRegionName(defaultEduOffice.name);

        const fetchSupportOffices = async () => {
            try {
                const response = await api.get('/api/support-offices');
                setSupportOffices(response.data);
            } catch (error) {
                console.error("There was an error fetching the support offices!", error);
            }
        };

        fetchSupportOffices();
    }, []);

    const handleCreate = async () => {
        if (!supporterID) {
            alert("아이디를 입력하세요.");
            return;
        }
        if (!supporterPasswd) {
            alert("비밀번호를 입력하세요.");
            return;
        }
        if (!supportOfficeNo) {
            alert("지원청을 선택하세요.");
            return;
        }

        if (window.confirm("생성하시겠습니까?")) {
            try {
                const response = await api.post('/api/supporters', {
                    supporterId: supporterID,
                    supporterPasswd: supporterPasswd,
                    supportOfficeNo: parseInt(supportOfficeNo, 10),
                    regionCode: regionCode
                });

                if (response.status >= 200 && response.status < 300) {
                    alert("생성되었습니다.");
                    navigate('/operator?menu=교육지원청관리자조회');
                }
            } catch (error) {
                if (error.response && (error.response.status >= 400 && error.response.status < 600)) {
                    alert("생성에 실패했습니다: " + error.response.data.message);
                } else {
                    console.error("There was an error creating the support office manager!", error);
                }
            }
        }
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 교육지원청관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="input-group02">
                                <div className="title-type002">
                                    <strong className="tit01">아이디</strong>
                                </div>
                                <div className="input-type01">
                                    <label htmlFor="supporterID" className="hide">교육지원청관리자 아이디</label>
                                    <input
                                        id="supporterID"
                                        type="text"
                                        placeholder="교육지원청관리자 아이디를 입력하세요"
                                        value={supporterID}
                                        onChange={(e) => setsupporterID(e.target.value)}
                                        className="input-text"
                                    />
                                </div>
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="input-group02">
                                <div className="title-type002">
                                    <strong className="tit01">비밀번호</strong>
                                </div>
                                <div className="input-type01">
                                    <label htmlFor="supporterPasswd" className="hide">비밀번호</label>
                                    <input
                                        id="supporterPasswd"
                                        type="password"
                                        placeholder="교육지원청관리자 비밀번호를 입력하세요"
                                        value={supporterPasswd}
                                        onChange={(e) => setsupporterPasswd(e.target.value)}
                                        className="input-text"
                                    />
                                </div>
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="input-group02">
                                <div className="title-type002">
                                    <strong className="tit01">지원청 선택</strong>
                                </div>
                                <div className="input-type01">
                                    <label htmlFor="supportOfficeNo" className="hide">교육지원청</label>
                                    <select
                                        id="supportOfficeNo"
                                        className="select-type01"
                                        value={supportOfficeNo}
                                        onChange={(e) => setSupportOfficeNo(e.target.value)}
                                    >
                                        <option value="">지원청을 선택하세요</option>
                                        {supportOffices.map((office) => (
                                            <option key={office.supportOfficeNo} value={office.supportOfficeNo}>
                                                {office.supportOfficeTitle}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="input-group02">
                                <div className="title-type002">
                                    <strong className="tit01">지역</strong>
                                </div>
                                <div className="input-type01">
                                    <label htmlFor="regionName" className="hide">지역</label>
                                    <input
                                        id="regionName"
                                        type="text"
                                        value={regionName}
                                        readOnly
                                        className="input-text"
                                    />
                                </div>
                            </div>
                        </form>
                    </fieldset>
                    <div className="btns-group03">
                        <button onClick={handleCreate} className="btn-type005 blue">생성</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupportOfficeManagerCreate;