import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Button } from '@mui/material';
import api from './apiService';

// 이미지 렌더링 함수
const renderImages = (statusImg) => {
    if (!statusImg) return null;

    const images = statusImg.split(',').map((imgUrl, index) => (
        <div key={index} className="image-container" style={{ margin: '10px' }}>
            <img src={`/assets/survey/${imgUrl}`} alt={`Status Image ${index + 1}`} className="formBoxTest-img" style={{ maxWidth: '60%', height: 'auto', borderRadius: '8px' }} />
        </div>
    ));

    return (
        <div className="image-wrapper" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {images}
        </div>
    );
};

// InstructionBox component
const InstructionBox = ({ onStart, onCancel }) => {
    return (
        <div className="modal-w active" aria-modal="true">
            <div className="modal-inner-type80">
                <button type="button" className="btn-type001 close" onClick={onCancel} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                </button>
                <div className="title-type001-blue">
                    <strong className="tit01">학생용 학교 장애인식 검사지</strong>
                </div>
                <div className="formBox-testBlue">
                    <div className="title-type002">
                        <strong className="tit01">
                            안녕하세요? 이 설문은 장애인이 등장하는 상황과 그 상황에 대한 몇 가지 생각들이 제시되어 있습니다.
                            여러분은 그 생각에 얼마나 동의하는지 체크 해주십시오. 그 생각들은 좋고 나쁜 것도 아니며 누구나 가질 수 있는 생각이니
                            솔직하게 답하면 됩니다. 감사합니다.
                        </strong>
                    </div>
                    <button type="button" className="btn-type005 yellow" onClick={onStart}>
                        <span className="txt01">검사 시작</span>
                    </button>
                </div>
                <button type="button" className="btn-type005 border close" onClick={onCancel} >
                    <span className="txt01">닫기</span>
                </button>
            </div>
            <i className="dimm"></i>
        </div>
    );
};

function OperatorStudentSurveyModalTest({ open, onClose, surveyId }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [surveyData, setSurveyData] = useState({ statuses: [], items: [] });
    const [loading, setLoading] = useState(true);
    const [startSurvey, setStartSurvey] = useState(false);
    const [responses, setResponses] = useState({});
    const modalContentRef = useRef(null);
    const closeButtonRef = useRef(null);

    useEffect(() => {
        async function fetchSurveyData() {
            try {
                setLoading(true);
                const response = await api.get(`/survey/status/${surveyId}`);
                if (response.status === 200) {
                    setSurveyData(response.data);
                    initializeResponses(response.data.items);
                }
            } catch (error) {
                console.error("Error fetching survey data:", error);
                alert("Failed to load survey data.");
            } finally {
                setLoading(false);
            }
        }

        if (open && surveyId) {
            fetchSurveyData();
        }
    }, [open, surveyId]);

    useEffect(() => {
        if (closeButtonRef.current) {
            closeButtonRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentPage]);

    const initializeResponses = (items) => {
        const newResponses = {};
        items.forEach(item => {
            newResponses[item.itemSeq] = '';
        });
        setResponses(newResponses);
    };

    const handleResponseChange = (itemSeq, value) => {
        setResponses(prevResponses => ({
            ...prevResponses,
            [itemSeq]: value
        }));
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    if (!open || loading) {
        return <div>Loading...</div>;
    }

    if (!startSurvey) {
        return <InstructionBox onStart={() => setStartSurvey(true)} onCancel={onClose} />;
    }

    const currentStatus = surveyData.statuses[currentPage];
    const currentItems = surveyData.items.filter(item => item.statusCode === currentStatus.statusCode);
    const isLastPage = currentPage === surveyData.statuses.length - 1;

    return (
        <div ref={modalContentRef} className="modal-w active" aria-modal="true">
            <div className="modal-inner-type80">
                <button ref={closeButtonRef} type="button" className="btn-type001 close" onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                </button>
                <div className="title-type001">
                    <strong className="tit01">{currentStatus.statusName}</strong>
                </div>
                <div className="formBox-test">
                    <div className="formBoxTest-top">
                        <div className="title-type002">
                            <strong className="tit01">{currentStatus.statusDescription}</strong>
                        </div>
                    </div>
                    <div className="formBoxTest-middle">
                        {renderImages(currentStatus.statusImg)}
                        <div className="testList_w">
                            {currentItems.map((item) => (
                                <div key={item.itemSeq} className="testList_inner">
                                    <div className="title-type002">
                                        <strong className="tit01">{item.statusCode}-{item.itemNo}: {item.item}</strong>
                                    </div>
                                    <div className="radio-form">
                                        {["전혀 동의하지 않는다", "동의하지 않는다", "잘 모르겠다", "동의한다", "매우 동의한다"].map((option, optionIndex) => (
                                            <span className="input-radio01" key={optionIndex}>
                                                <input
                                                    type="radio"
                                                    id={`type${item.itemSeq}-${optionIndex + 1}`}
                                                    name={`question-${item.itemSeq}`}
                                                    className="radio01"
                                                    value={String(optionIndex + 1)}
                                                    checked={responses[item.itemSeq] === String(optionIndex + 1)}
                                                    onChange={(event) => handleResponseChange(item.itemSeq, event.target.value)}
                                                />
                                                <label htmlFor={`type${item.itemSeq}-${optionIndex + 1}`} className="txt-label01">{option}</label>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="btns-group02" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <button type="button" className="btn-type005 border" onClick={goToPreviousPage} disabled={currentPage === 0} style={{ marginRight: '10px' }}>
                        <span className="txt01">이전 페이지</span>
                    </button>
                    <button type="button" className="btn-type005 border" onClick={goToNextPage} disabled={isLastPage} style={{ marginRight: '10px' }}>
                        <span className="txt01">다음 페이지</span>
                    </button>
                </div>
            </div>
            <i className="dimm"></i>
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default OperatorStudentSurveyModalTest;