import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import api from './apiService';
import SurveyEventEditDialog from './SurveyEventEditDialog';

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="240"
                height="200"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Rows</Box>
        </StyledGridOverlay>
    );
}

function OperatorPrivateInfoManage({selectedMenu}) {
    const [data, setData] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });
    const [selectedEventId, setSelectedEventId] = useState(null);  // 추가
    const [openDialog, setOpenDialog] = useState(false);  // 추가

    const handleRowClick = (params) => {
        setSelectedEventId(params.id);  // 선택된 행의 eventId 설정
        setOpenDialog(true);  // 모달을 엽니다.
    };

    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const handleDeleteClick = (params, event) => {
        event.stopPropagation();
        setSelectedRowData(params.row);
        setOpenModal(true);
    };

    const fetchData = async () => {
        api.get("/event/all")
            .then(response => {
                console.log(response.data);
                const dataWithIds = response.data.data.map(item => ({ ...item, id: item.eventId }));
                setData(dataWithIds);
            })
            .catch(error => {
                console.error("API fetch error:", error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    /*
        const confirmDelete = () => {
            api.post(`/response/delete?eventId=${selectedRowData.eventId}&surveyTarget=${selectedRowData.surveyTarget}`)
                .then(response => {
                    if (response.status === 200 && response.data === "Deleted Successfully!") {
                        alert("정상적으로 삭제되었습니다.");
                        fetchData();
                    } else {
                        alert(response.data);
                    }
                })
                .catch(error => {
                    alert("Error Deleting Data!");
                    console.error("API delete error:", error);
                });
            setOpenModal(false);
        };
    */
    const confirmDelete = () => {
        api.post(`/response/delete?eventId=${selectedRowData.eventId}&surveyTarget=${selectedRowData.surveyTarget}`)
            .then(response => {
                let message = `응답정보삭제: ${response.data.deleteResponse}, `;
                message += selectedRowData.surveyTarget === 'T'
                    ? `개인정보삭제: ${response.data.deleteTeacher}`
                    : `개인정보삭제: ${response.data.deleteStudent}`;

                alert(message);
                fetchData();
            })
            .catch(error => {
                alert("Error Deleting Data!");
                console.error("API delete error:", error);
            });
        setOpenModal(false);
    };


    const columns = [
        { field: 'eventId', headerName: 'EventID', flex: 0.5 },
        { field: 'eventTitle', headerName: 'Title', flex: 2 },
        { field: 'schoolGrade', headerName: 'Grade', flex: 0.5 },
        { field: 'surveyTarget', headerName: 'Target', flex: 0.5 },
        { field: 'responseCount', headerName: 'Count', flex: 0.5 },
        { field: 'status', headerName: 'Status', flex: 0.5 },
        {
            field: 'delete',
            headerName: 'Delete',
            renderCell: (params) => (
                params.row.status === '종료' ? (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={(event) => handleDeleteClick(params, event)}
                        disabled={params.row.responseCount === 0}
                    >
                        Delete
                    </Button>
                ) : null
            ),
            flex: 0.5
        },
    ];

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    return (
        <div id="container" className="container page-admin">
        <div className="content-w-form">
            <div className="admin-indicator">
                <div className="txt-type001">
                    <span className="txt01">홈 &gt; 학교관리 &gt; {selectedMenu}</span>
                </div>
            </div>

            <h2 className="title-type001">
                <strong className="tit01">{selectedMenu}</strong>
            </h2>
        <Box sx={{ width: '100%' }}>
            {data && data.length > 0 ? (
                <DataGrid
                    rows={data}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[5, 10, 20]}
                    onPageChange={handlePageChange}
                    getRowId={(row) => row.eventId}
                    onRowClick={handleRowClick}  // 추가
                />
            ) : (
                <DataGrid
                    autoHeight
                    columns={columns}
                    rows={[]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[5, 10, 20]}
                    slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                    sx={{ '--DataGrid-overlayHeight': '300px' }}
                />
            )}

            {openDialog && <SurveyEventEditDialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                    fetchData(); // 모달창이 닫힐 때마다 데이터를 다시 불러옵니다.
                }}
                eventId={selectedEventId}
            />}

            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>{"Delete Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        개인 정보가 삭제됩니다. 개인정보가 삭제되면 총 집계 정보만 남고 개별 응답 상세 정보와 개인 정보가 삭제됩니다. 계속 진행하시겠습니까?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                    <Button onClick={confirmDelete} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            </Box>
        </div>
        </div>
                

    );
}

export default OperatorPrivateInfoManage;
