import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './apiService';

function StudentRegisterInput({ open, onClose, schoolCode, schoolName, schoolGrade }) {
    const [studentData, setStudentData] = useState({
        schoolCode: schoolCode,
        schoolYear: "",
        schoolClass: "",
        studentNumber: "",
        studentName: "",
        registerDate: new Date().toISOString().split('T')[0] + 'T09:00:00'
    });

    const navigate = useNavigate();

    const [allClassData, setAllClassData] = useState([]);
    const [classData, setClassData] = useState({
        schoolYear: [],
        schoolClass: []
    });

    const [selectedYear, setSelectedYear] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [classPasswd, setClassPasswd] = useState('');
    const [inputClassPasswd, setInputClassPasswd] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    useEffect(() => {
        const fetchClassData = async () => {
            try {
                const response = await api.get(`/school/class?schoolCode=${schoolCode}`);
                if (response.status === 200 && response.data.data) {
                    const fetchedData = response.data.data;
                    setAllClassData(fetchedData);

                    const uniqueYears = new Set();
                    const uniqueClasses = new Set();

                    fetchedData.forEach(item => {
                        uniqueYears.add(item.schoolYear);
                        uniqueClasses.add(item.schoolClass);
                    });

                    setClassData({
                        schoolYear: [...uniqueYears].sort((a, b) => a - b),
                        schoolClass: [...uniqueClasses].sort((a, b) => a - b)
                    });
                } else {
                    setClassData({ schoolYear: [], schoolClass: [] });
                }
            } catch (error) {
                console.error("Error fetching class data:", error);
                setClassData({ schoolYear: [], schoolClass: [] });
            }
        };

        if (open) {
            fetchClassData();
        }
    }, [open, schoolCode]);

    const handleYearChange = (event) => {
        //console.log("Year changed:", event.target.value);
        setSelectedYear(event.target.value);
        setStudentData(prevData => ({
            ...prevData,
            schoolYear: event.target.value
        }));
    };

    const handleClassChange = (event) => {
        const selectedClassString = String(event.target.value);
        setSelectedClass(selectedClassString);

        const classNumberMatch = selectedClassString.match(/\d+/);
        const classNumber = classNumberMatch ? parseInt(classNumberMatch[0], 10) : null;

        // console.log("Selected Year:", selectedYear);
        // console.log("Selected Class:", classNumber);
        // console.log("All Class Data:", allClassData);

        const matchedClassData = allClassData.find(item =>
            String(item.schoolYear) === String(selectedYear) &&
            String(item.schoolClass) === String(classNumber)
        );

        if (matchedClassData) {
            setClassPasswd(matchedClassData.classPasswd);
        } else {
            console.error('No matching class data found.');
        }

        // Update studentData with the selected class
        setStudentData(prevData => ({
            ...prevData,
            schoolClass: selectedClassString
        }));
    };

    const handleNumberChange = (event) => {
        setStudentData({ ...studentData, studentNumber: event.target.value });
    };

    const handleNameChange = (event) => {
        setStudentData({ ...studentData, studentName: event.target.value });
    };

    const handleClassPasswordChange = (event) => {
        setInputClassPasswd(event.target.value);
    };

    const handleAuthenticate = () => {
        // console.log("Input Password:", inputClassPasswd);
        // console.log("Stored Password:", classPasswd);

        if (String(inputClassPasswd) === String(classPasswd)) {
            setSuccessDialogOpen(true);
        } else {
            console.error("Authentication failed: Passwords do not match.");
            setErrorDialogOpen(true);
        }
    };

    const handleSave = async () => {
        const now = new Date();
        const koreanTime = now.toLocaleString('sv', { timeZone: 'Asia/Seoul' }).replace(' ', 'T');
        
        const dataToSave = {
            schoolCode: schoolCode,
            schoolYear: studentData.schoolYear,
            schoolClass: studentData.schoolClass,
            studentNumber: studentData.studentNumber,
            studentName: studentData.studentName,
            registerDate: koreanTime  // "2024-01-23T21:34:56" 형식
        };

        try {
            const response = await api.post(`/student`, dataToSave);
            if (response.status === 201 && response.data.data) {
                localStorage.setItem('schoolCode', response.data.data.schoolCode);
                localStorage.setItem('studentSeq', response.data.data.studentSeq);
                localStorage.setItem('schoolYear', response.data.data.schoolYear);
                localStorage.setItem('schoolGrade', schoolGrade);
                const serializedLoginInfo = JSON.stringify({ "data": { "usergroup": "4", "token": `${response.data.data.token}` } });
                localStorage.setItem('loginInfo', serializedLoginInfo);
                setIsRegistering(false);
                navigate('/student');
            } else {
                setErrorDialogOpen(true);
            }
        } catch (error) {
            console.error("Error saving student data:", error);
            setErrorDialogOpen(true);
        }
    };

    const handleCloseErrorDialog = () => {
        setErrorDialogOpen(false);
    };

    const resetFields = () => {
        setSelectedYear('');
        setSelectedClass('');
        setInputClassPasswd('');
        setStudentData({
            studentNumber: '',
            studentName: '',
        });
        setIsRegistering(false);
        setIsAuthenticated(false);
    };

    const handleSuccessDialogClose = () => {
        setSuccessDialogOpen(false);
        setIsAuthenticated(true);
    };

    return (
        <div className="wrap">
            <div id="modalRegist" className={`modal-w ${open ? 'active' : ''}`} aria-modal="true">
                <div className="modal-inner-type45">
                    <div className="title-type001">
                        <strong className="tit01">학생등록인증</strong>
                    </div>
                    <fieldset className="fieldset-type02">
                        <select id="type1" name="" className="select-type01" value={selectedYear} onChange={handleYearChange}>
                            <option value="" disabled hidden>학년을 선택하세요</option>
                            {classData.schoolYear.map((year, index) => (
                                <option key={index} value={year}>{year}학년</option>
                            ))}
                        </select>
                        <select id="type2" name="" className="select-type01" value={selectedClass} onChange={handleClassChange} disabled={!selectedYear}>
                            <option value="" disabled hidden>반을 선택하세요</option>
                            {classData.schoolClass.map((schoolClass, index) => (
                                <option key={index} value={schoolClass}>{schoolClass}반</option>
                            ))}
                        </select>
                        <div className="input-type01">
                            <label htmlFor="num" className="hide">번호</label>
                            <input id="num" type="text" placeholder="번호(숫자만 입력하세요)" value={studentData.studentNumber} onChange={handleNumberChange} />
                        </div>
                        <div className="input-type01">
                            <label htmlFor="name" className="hide">이름</label>
                            <input id="name" type="text" placeholder="이름" value={studentData.studentName} onChange={handleNameChange} />
                        </div>
                        <div className="input-type01">
                            <label htmlFor="schoolPwd" className="hide">학급 비밀번호</label>
                            <input id="schoolPwd" type="text" placeholder="학급 비밀번호" value={inputClassPasswd} onChange={handleClassPasswordChange} />
                        </div>
                        <button type="button" className={`btn-type005 ${!selectedYear || !selectedClass || !studentData.studentName || !inputClassPasswd ? 'grey' : 'blue'}`} onClick={handleAuthenticate} disabled={!selectedYear || !selectedClass || !studentData.studentName || !inputClassPasswd}>
                            <span className="txt01">학생 인증</span>
                        </button>
                    </fieldset>
                    <button type="button" className="btn-type005 border close" onClick={onClose}>
                        <span className="txt01">닫기</span>
                    </button>
                </div>
                <i className="dimm"></i>
            </div>

            {/* 인증 성공 모달 */}
            <div id="modalAlert" className={`modal-w ${successDialogOpen ? 'active' : ''}`} aria-modal="true">
                <div className="modal-inner-type25">
                    <div className="title-type001">
                        <strong className="tit01">인증 성공</strong>
                    </div>
                    <div className="title-type003">
                        <strong className="tit01-grey">학생 인증에 성공했습니다.</strong>
                    </div>
                    <button type="button" className="btn-type005 border close" onClick={handleSuccessDialogClose}>
                        <span className="txt01">닫기</span>
                    </button>
                </div>
                <i className="dimm"></i>
            </div>

            {/* 인증 실패 모달 */}
            <div id="modalError" className={`modal-w ${errorDialogOpen ? 'active' : ''}`} aria-modal="true">
                <div className="modal-inner-type25">
                    <div className="title-type001">
                        <strong className="tit01">인증 실패</strong>
                    </div>
                    <div className="title-type003">
                        <strong className="tit01-grey">학생 인증에 실패했습니다. 담임 선생님께 학급 비밀번호를 확인하세요.</strong>
                    </div>
                    <button type="button" className="btn-type005 border close" onClick={handleCloseErrorDialog}>
                        <span className="txt01">닫기</span>
                    </button>
                </div>
                <i className="dimm"></i>
            </div>

            {/* 학생 정보 확인 모달 */}
            {isAuthenticated && (
                <div id="modalCert" className="modal-w active" aria-modal="true">
                    <div className="modal-inner-type45">
                        <div className="title-type001">
                            <strong className="tit01">학생등록인증</strong>
                        </div>
                        <div className="formBox-confirm">
                            <div className="title-type002">
                                <strong className="tit01-blue">학교: {schoolName}</strong>
                                <strong className="tit01-blue">학년: {studentData.schoolYear}학년</strong>
                                <strong className="tit01-blue">반: {studentData.schoolClass}반</strong>
                                <strong className="tit01-blue">번호: {studentData.studentNumber}번</strong>
                                <strong className="tit01-blue">이름: {studentData.studentName}</strong>
                                <strong className="tit01-black">입력한 학생 정보가 맞습니까?</strong>
                            </div>
                        </div>
                        <div className="btns-group03">
                            <button onClick={handleSave} className="btn-type005 blue"><span className="txt">확인</span></button>
                            <button onClick={resetFields} className="btn-type005 border"><span className="txt">다시 입력하기</span></button>
                        </div>
                        <button type="button" className="btn-type005 border close" onClick={onClose}>
                            <span className="txt01">닫기</span>
                        </button>
                    </div>
                    <i className="dimm"></i>
                </div>
            )}
        </div>
    );
}

export default StudentRegisterInput;