import React, { useState } from 'react';
import api from './apiService';
import ManagerInfoDetail from './ManagerInfoDetail'; // Import ManagerInfoDetail component
import TeacherInfoDetail from './TeacherInfoDetail'; // Import TeacherDetail component
import SupporterInfoDetail from './SupporterInfoDetail'; // Import SupporterInfoDetail component

function MyInfo() {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [showManagerModal, setShowManagerModal] = useState(false);
    const [showTeacherModal, setShowTeacherModal] = useState(false);
    const [showSupporterModal, setShowSupporterModal] = useState(false);

    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    const userId = loginInfo ? loginInfo.data.userid : '';
    const userGroup = loginInfo ? loginInfo.data.usergroup : ''; // Get the usergroup value

    const handlePasswordCheck = async () => {
        try {
            const userDTO = {
                usergroup: userGroup,
                userid: userId,
                password: password
            };

            const response = await api.post("/login/myinfo", userDTO);

            if (response.status === 200) {
                if (userGroup === '1') {
                    setShowModal(true);
                } else if (userGroup === '2') {
                    setShowManagerModal(true);
                } else if (userGroup === '3') {
                    setShowTeacherModal(true);
                } else if (userGroup === '5') {
                    setShowSupporterModal(true);
                }
            } else {
                alert('패스워가 올바르지 않습니다.');
            }
        } catch (error) {
            if (error.response.status === 404)
                alert('비밀번호가 일치하지 않습니다.');
            else
                alert('문제가 발생했습니다.');
            console.error('Password check failed:', error);
        }
    };

    const handlePasswordChange = async () => {
        if (newPassword !== confirmNewPassword) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }
        try {
            const userDTO = {
                usergroup: userGroup,
                userid: userId,
                password: newPassword
            };
            const response = await api.put(`/login/operatorChangePassword`, userDTO);
            if (response.status === 200) {
                alert('비밀번호가 변경되었습니다.');
                setShowModal(false);
            } else {
                alert('비밀번호 변경에 오류가 발생했습니다.');
                setShowModal(false);
            }
        } catch (error) {
            alert('비밀번호 변경에 오류가 발생했습니다.');
            console.error('Password change failed:', error);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setNewPassword('');
        setConfirmNewPassword('');
    };

    const handleCloseManagerModal = () => {
        setShowManagerModal(false);
    };

    const handleCloseTeacherModal = () => {
        setShowTeacherModal(false);
    };

    const handleCloseSupporterModal = () => {
        setShowSupporterModal(false);
    };


    return (
        <div className="container page-teacher">
            <div className="content-w-my">
                <h2 className="title-type001">
                    <strong className="tit01">내정보 확인</strong>
                </h2>
                <div className="my-inner">
                    <fieldset className="fieldset-type02">
                        <div className="input-type01">
                            <label htmlFor="id1" className="hide">아이디</label>
                            <input id="id1" type="text" value={userId} readOnly placeholder="아이디(최대 10자리)" />
                        </div>
                        <div className="input-type01">
                            <label htmlFor="pwd1" className="hide">패스워드</label>
                            <input id="pwd1" type="password" placeholder="패스워드" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <button type="button" className="btn-type005 blue" onClick={handlePasswordCheck}>
                            <span className="txt01">확인</span>
                        </button>
                    </fieldset>
                </div>
            </div>

            {/* Modal for usergroup 1 */}
            {userGroup === '1' && showModal && (
                <div id="modalEdit" className={`modal-w ${showModal ? 'active' : ''}`}>
                    <div className="modal-inner-type45">
                        <div className="title-type001">
                            <strong className="tit01">비밀번호 변경</strong>
                        </div>
                        <fieldset className="fieldset-type02">
                            <div className="input-type01">
                                <label htmlFor="newPwd" className="hide">새로운 비밀번호</label>
                                <input id="newPwd" type="password" placeholder="새로운 비밀번호" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            </div>
                            <div className="input-type01">
                                <label htmlFor="confirmPwd" className="hide">새로운 비밀번호 확인</label>
                                <input id="confirmPwd" type="password" placeholder="새로운 비밀번호 확인" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                            </div>
                            <button type="button" className="btn-type005 blue" onClick={handlePasswordChange}>
                                <span className="txt01">저장</span>
                            </button>
                            <button type="button" className="btn-type005 border close" onClick={handleClose}>
                                <span className="txt01">닫기</span>
                            </button>
                        </fieldset>
                    </div>
                    <i className="dimm"></i>
                </div>
            )}

            {/* Modal for usergroup 2 (Manager) */}
            {userGroup === '2' && showManagerModal && (
                <ManagerInfoDetail onClose={handleCloseManagerModal} userId={userId} />
            )}

            {/* Modal for usergroup 3 (Teacher) */}
            {userGroup === '3' && showTeacherModal && (

                <TeacherInfoDetail onClose={handleCloseTeacherModal} userId={userId} />

            )}

            {/* Modal for usergroup 5 (Supporter) */}
            {userGroup === '5' && showSupporterModal && (

                <SupporterInfoDetail onClose={handleCloseSupporterModal} userId={userId} />

            )}
        </div>
    );
}

export default MyInfo;