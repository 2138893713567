import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import api from './apiService';
import SurveyEventEditDialog from './SurveyEventEditDialog';

function SurveyEvent({ selectedMenu }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });
    const [selectedEventId, setSelectedEventId] = useState(null);  // 추가
    const [openDialog, setOpenDialog] = useState(false);  // 추가
    const handleRowClick = (params) => {
        setSelectedEventId(params.id);  // 선택된 행의 eventId 설정
        setOpenDialog(true);  // 모달을 엽니다.
    };

    const fetchData = async () => {
        api.get("/surveyevent")
            .then(response => {
                const dataWithIds = response.data.data.map(item => ({ ...item, id: item.eventId }));
                setData(dataWithIds);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("API fetch error:", error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        { field: 'eventId', headerName: 'Event ID', flex: 0.5 },
        { field: 'eventTitle', headerName: 'Title', flex: 2 },
        {
            field: 'eventStartDate',
            headerName: 'Start Date',
            flex: 1,
            renderCell: (params) => (
                <span>{new Date(params.value).toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' })}</span>
            )
        },
        {
            field: 'eventEndDate',
            headerName: 'End Date',
            flex: 1,
            renderCell: (params) => (
                <span>{new Date(params.value).toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' })}</span>
            )
        },
        {
            field: 'schoolGrade',
            headerName: 'Grade',
            flex: 0.5,
            renderCell: (params) => {
                const gradeMap = { 'E': '초', 'M': '중', 'H': '고' };
                return <span>{gradeMap[params.value] || params.value}</span>;
            }
        },
        {
            field: 'surveyTarget',
            headerName: 'Target',
            flex: 0.5,
            renderCell: (params) => {
                const targetMap = { 'S': '학생', 'T': '교사' };
                return <span>{targetMap[params.value] || params.value}</span>;
            }
        },
        {
            field: 'surveyTitle',
            headerName: 'Survey Title',
            flex: 0.5,
            renderCell: (params) => (
                <span>{params.row.survey ? params.row.survey.surveyTitle : 'N/A'}</span>
            )
        }
    ];

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 검사이벤트관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>
                {/* <div className="form-inner"> */}
                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 20]}
                        onPageChange={handlePageChange}
                        getRowId={(row) => row.eventId}
                        onRowClick={handleRowClick}
                        slots={{
                            noRowsOverlay: () => (
                                <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                    <strong className="tit01">조회된 데이터가 없습니다.</strong>
                                </div>
                            ),
                        }}
                        loading={isLoading}
                    />

                    {openDialog && <SurveyEventEditDialog
                        open={openDialog}
                        onClose={() => {
                            setOpenDialog(false);
                            fetchData(); // 모달창이 닫힐 때마다 데이터를 다시 불러옵니다.
                        }}
                        eventId={selectedEventId}
                    />}
                </Box>
                {/* </div> */}
            </div>
        </div>
    );
}

export default SurveyEvent;
