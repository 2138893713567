import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Modal, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from './apiService';
import TeacherSurveyResultDetail from './TeacherSurveyResultDetail';
import { useReactToPrint } from 'react-to-print';

const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

const TeacherSurveyResultPage = ({ schoolCode, teacherId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const [openModal, setOpenModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    // react-to-print
    const componentRef = useRef();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Teacher Survey Result",
        removeAfterPrint: true
    });

    useEffect(() => {
        setIsLoading(true);
        api.get(`/surveyresult/teacher?schoolCode=${schoolCode}&teacherId=${teacherId}`)
            .then(response => {
                if (response.error) {
                    console.error('Error fetching data:', response.data.error);
                    setEvents([]);
                } else {
                    const updatedEvents = response.data.data.map(event => {
                        event.response = event.response === null ? "응답안함" : "확인가능";
                        event.eventStartDate = new Date(event.eventStartDate).toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        event.eventEndDate = new Date(event.eventEndDate).toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        // Transform schoolGrade and surveyTarget
                        event.schoolGrade = event.schoolGrade === 'E' ? '초' : event.schoolGrade === 'M' ? '중' : '고';
                        event.surveyTarget = event.surveyTarget === 'S' ? '학생' : '교사';
                        return event;
                    });
                    setEvents(updatedEvents);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setEvents([]);
                setIsLoading(false);
            });
    }, [schoolCode, teacherId]);

    const columns = isMobile ? [
        { field: 'eventTitle', headerName: '검사이벤트명', flex: 2 },
        {
            field: 'response',
            headerName: '결과확인',
            flex: 1,
            renderCell: (params) => (
                params.value === "확인가능" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRowClick(params)}
                        sx={{ fontSize: '1.2rem' }}
                    >
                        결과지보기
                    </Button>
                ) : (
                    <span>응답안함</span>
                )
            )
        },
    ] : [
        { field: 'eventTitle', headerName: '검사이벤트', flex: 1.2 },
        { field: 'eventStartDate', headerName: '시작일', flex: 0.5 },
        { field: 'eventEndDate', headerName: '종료일', flex: 0.5 },
        { field: 'schoolGrade', headerName: '학교급', flex: 0.3 },
        { field: 'surveyTarget', headerName: '대상', flex: 0.3 },
        {
            field: 'response',
            headerName: '결과확인',
            flex: 0.5,
            renderCell: (params) => (
                params.value === "확인가능" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRowClick(params)}
                        sx={{ fontSize: '1.2rem' }}
                    >
                        결과지보기
                    </Button>
                ) : (
                    <span>응답안함</span>
                )
            )
        },
    ];

    const handleRowClick = (params) => {
        setSelectedRowData(params.row);
        if (params.row.response === "확인가능") {
            setOpenModal(true);
        } else {
            alert("응답 정보가 없습니다.");
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedRowData(null);
    };

    return (
        <div className="container page-teacher">
            <div className="content-w-form">
                <h2 className="title-type001">
                    <strong className="tit01">교원 검사 결과</strong>
                </h2>
                <div className="form-inner">
                    <div className="formDatagrid-w" style={{ width: '100%', height: '500px', backgroundColor: '#fff' }}>
                        <DataGrid
                            rows={events}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[5, 10, 20]}
                            onPageChange={(page) => setPaginationModel({ ...paginationModel, page })}
                            getRowId={(row) => row.eventId}
                            loading={isLoading}
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'lightgray',
                                    fontSize: '1.2rem',
                                },
                                '& .MuiDataGrid-cell': {
                                    fontSize: '1.1rem',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    backgroundColor: '#fff',
                                },
                                '& .MuiDataGrid-overlay': {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                },
                                backgroundColor: '#fff',
                            }}
                            slots={{
                                noRowsOverlay: () => (
                                    <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                        <strong className="tit01">조회된 데이터가 없습니다.</strong>
                                    </div>
                                )
                            }}
                            //onRowClick={handleRowClick}
                        />
                    </div>
                </div>
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="teacher-response-modal"
            >
                <Box sx={style}>
                    {selectedRowData && (
                        <TeacherSurveyResultDetail
                            eventId={selectedRowData.eventId}
                            schoolCode={schoolCode}
                            teacherId={teacherId}
                            onPrint={handlePrint}
                            onClose={handleCloseModal}
                            ref={componentRef}
                        />
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default TeacherSurveyResultPage;