import React, { useState, useEffect } from 'react';

function LoginTop({ setSelectedMenu: setExternalSelectedMenu }) {
    const regionNames = {
        'seoul': '서울',
        'busan': '부산',
        'daegu': '대구',
        'incheon': '인천',
        'gwangju': '광주',
        'daejeon': '대전',
        'ulsan': '울산',
        'sejong': '세종',
        'gyeonggi': '경기',
        'gangwon': '강원',
        'chungbuk': '충북',
        'chungnam': '충남',
        'jeonbuk': '전북',
        'jeonnam': '전남',
        'gyeongbuk': '경북',
        'gyeongnam': '경남',
        'jeju': '제주'
    };

    const [isListVisible, setIsListVisible] = useState(false);

    const subdomain = window.location.hostname.split('.')[0];
    const currentRegionName = regionNames[subdomain] || 'Unknown';

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('loginInfo');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleRegionClick = (region) => {
        const newSubdomain = Object.keys(regionNames).find(key => regionNames[key] === region);
        if (newSubdomain) {
            localStorage.removeItem('loginInfo');
            const protocol = process.env.REACT_APP_ENV === 'development' ? 'http' : 'https';
            const domain = process.env.REACT_APP_ENV === 'development' ? 'schoolawareness.org' : 'schoolawareness.kr';
            window.location.href = `${protocol}://${newSubdomain}.${domain}`;
        }
    };

    const toggleListVisibility = () => {
        setIsListVisible(!isListVisible);
    };

    const handleMyInfoClick = () => {
        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));

        if (loginInfo) {
            setExternalSelectedMenu('내정보');
        } else {
            window.location.href = '/login';
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('loginInfo');
        window.location.href = '/login';
    };

    const handleLogoutAndHome = () => {
        localStorage.removeItem('loginInfo');
        window.location.href = '/home';
    };

    return (
        <div className="header-inner">
            <h1 className="headerLogo-w">
                <a className="logo-system" href="#none">
                    <strong className="hide">학교장애인식지수 온라인검사시스템</strong>
                </a>
            </h1>
            <div className="btns-group01">
                <div className="btns-group04">
                    <button type="button" className="btn-type001 local" onClick={toggleListVisibility}>
                        <span className="ico-type001 local"><i className="hide">지역 선택</i></span>
                        <span className="txt01">{currentRegionName}</span>
                    </button>
                    <div className={`list-wrap02 ${isListVisible ? 'active' : ''}`}>
                        <ul>
                            {Object.entries(regionNames).map(([key, region]) => (
                                <li key={key}>
                                    <a href="#" onClick={() => handleRegionClick(region)}>{region}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <button type="button" className="btn-type001 home" onClick={handleLogoutAndHome}>
                    <span className="ico-type001 home"><i className="hide">홈으로 이동</i></span>
                    <span className="txt01">홈</span>
                </button>
                <button type="button" className="btn-type004 login-type01" onClick={handleMyInfoClick}>
                    <span className="ico-type001 mypage"><i className="hide">마이페이지로 이동</i></span>
                    <span className="txt01">내정보</span>
                </button>
                <button type="button" className="btn-type004 login-type01 off" onClick={handleLogout}>
                    <span className="ico-type001 logout"><i className="hide"></i></span>
                    <span className="txt01">로그아웃</span>
                </button>
            </div>
        </div>
    );
}

export default LoginTop;