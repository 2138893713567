import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled, Box, TextField, Button } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import api from './apiService';
import Grid from '@mui/material/Grid'; // Grid를 import 합니다
import SchoolManagerDetail from './SchoolManagerDetail.js';

function SchoolManagerSearch({ selectedMenu }) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchParam, setSearchParam] = useState({
        schoolManagerId: "",
        schoolManagerPasswd: "",
        schoolManagerName: "",
        schoolManagerPhone: "",
        registerDate: "",
        updatetimeDate: null,
        schoolCode: "",
        schoolDTO: {
            schoolCode: "",
            schoolName: "",
            schoolGrade: "",
            schoolZipcode: "",
            schoolAddress1: "",
            schoolAddress2: "",
            teacherPasswd: ""
        }
    });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const [openDialog, setOpenDialog] = useState(false);  // 추가
    const [selectedSchoolManagerId, setSelectedSchoolManagerId] = useState(null);

    const columns = [
        { field: 'schoolManagerId', headerName: '학교관리자ID', flex: 1 },
        { field: 'schoolManagerName', headerName: '성명', flex: 1 },
        { field: 'schoolManagerPhone', headerName: '연락처', flex: 1 },
        { field: 'schoolName', headerName: '학교명', flex: 1.5, renderCell: (params) => params.row.schoolDTO.schoolName },
        { field: 'schoolCode', headerName: '학교코드', flex: 1, renderCell: (params) => params.row.schoolDTO.schoolCode },
    ];

    const schoolGradeOptions = [
        { schoolGrade: 'E', name: '초등' },
        { schoolGrade: 'M', name: '중등' },
        { schoolGrade: 'H', name: '고등' },
    ];

    const handleSearch = (overrideSchoolName = null) => {
        const paramsToSearch = {
            ...searchParam,
            schoolDTO: {
                ...searchParam.schoolDTO,
                schoolName: overrideSchoolName !== null ? overrideSchoolName : searchParam.schoolDTO.schoolName,
            }
        };

        setIsLoading(true);

        api.post("/schoolmgr/schoolmanager/search", paramsToSearch)
            .then(response => {
                const managerDatas = response.data.data.map(item => ({ ...item, id: item.schoolManagerId }));
                setSearchParam(paramsToSearch);
                setData(managerDatas);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("API fetch error:", error);
                setIsLoading(false);
            });
    };


    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    // 행이 클릭될 때 호출되는 함수
    const handleRowClick = (params) => {
        setSelectedSchoolManagerId(params.row.schoolManagerId);
        setOpenDialog(true);
    };

    const inputRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e.target.value);
            e.preventDefault();
        }
    };

    const handleDeleteSuccess = (deletedSchoolManagerId) => {
        const newData = data.filter(item => item.schoolManagerId !== deletedSchoolManagerId);
        setData(newData);
    };

    return (
        <div className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 학교관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type01">
                        <div className="select-wrap">
                            <select
                                name="schoolGrade"
                                className="select-type01"
                                value={searchParam.schoolDTO.schoolGrade}
                                onChange={(e) => setSearchParam({
                                    ...searchParam,
                                    schoolDTO: {
                                        ...searchParam.schoolDTO,
                                        schoolGrade: e.target.value
                                    }
                                })}
                            >
                                <option value="" disabled>학교급을 선택하세요</option>
                                {schoolGradeOptions.map(option => (
                                    <option key={option.schoolGrade} value={option.schoolGrade}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <form className="input-group01">
                            <div className="input-type01">
                                <label htmlFor="searchSchoolManager" className="hide">학교명</label>
                                <input
                                    id="searchSchoolManager"
                                    type="text"
                                    placeholder="학교명"
                                    defaultValue={searchParam.schoolDTO.schoolName}
                                    ref={inputRef}
                                    onKeyDown={handleKeyPress}
                                />
                            </div>
                            <button
                                type="button"
                                className="btn-type005 blue"
                                onClick={() => handleSearch(inputRef.current.value)}
                            >
                                <span className="txt01">검색</span>
                            </button>
                        </form>
                    </fieldset>
                </div>

                <div style={{ width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 20]}
                        onPageChange={handlePageChange}
                        getRowId={(row) => row.schoolManagerId}
                        onRowClick={handleRowClick}  // 추가
                        loading={isLoading}
                    />
                </div>

                {openDialog && <SchoolManagerDetail
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                        setSelectedSchoolManagerId(null);
                    }}
                    onDeleteSuccess={handleDeleteSuccess}
                    schoolManagerId={selectedSchoolManagerId}
                />}
            </div>
        </div>
    );
}

export default SchoolManagerSearch;
