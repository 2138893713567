import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import OperatorStudentSurveyModalTest from './OperatorStudentSurveyModalTest';
import OperatorTeacherSurveyModalTest from './OperatorTeacherSurveyModalTest';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

function SurveyManage({ selectedMenu }) {
    const [eventData, setEventData] = useState({
        schoolGrade: 'E',
        surveyTarget: 'S',
        surveyId: '',
    });
    const [openStudentModal, setOpenStudentModal] = useState(false);
    const [openTeacherModal, setOpenTeacherModal] = useState(false);

    const surveyOptions = [
        { surveyId: 'ES0001', name: 'A검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 A검사지' },
        { surveyId: 'ES0002', name: 'B검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 B검사지' },
        { surveyId: 'ES0003', name: 'C검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 C검사지' },
        { surveyId: 'ES0004', name: 'D검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 D검사지' },
        // 학생 E검사지 추가 2024.02.08
        { surveyId: 'ES0005', name: 'E검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 E검사지' },
        { surveyId: 'ET0001', name: 'A검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 A검사지' },
        { surveyId: 'ET0002', name: 'B검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 B검사지' },
        { surveyId: 'ET0003', name: 'C검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 C검사지' },
        { surveyId: 'ET0004', name: 'D검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 D검사지' },
        // 교원 E검사지 추가 2024.02.08
        { surveyId: 'ET0005', name: 'E검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 E검사지' },
        { surveyId: 'HS0001', name: 'A검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 A검사지' },
        { surveyId: 'HS0002', name: 'B검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 B검사지' },
        { surveyId: 'HS0003', name: 'C검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 C검사지' },
        { surveyId: 'HS0004', name: 'D검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 D검사지' },
        // 학생 E검사지 추가 2024.02.08
        { surveyId: 'HS0005', name: 'E검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 E검사지' },
        { surveyId: 'HT0001', name: 'A검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 A검사지' },
        { surveyId: 'HT0002', name: 'B검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 B검사지' },
        { surveyId: 'HT0003', name: 'C검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 C검사지' },
        { surveyId: 'HT0004', name: 'D검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 D검사지' },
        // 교원 E검사지 추가 2024.02.08
        { surveyId: 'HT0005', name: 'E검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 E검사지' },
        { surveyId: 'MS0001', name: 'A검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 A검사지' },
        { surveyId: 'MS0002', name: 'B검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 B검사지' },
        { surveyId: 'MS0003', name: 'C검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 C검사지' },
        { surveyId: 'MS0004', name: 'D검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 D검사지' },
        // 학생 E검사지 추가 2024.02.08
        { surveyId: 'MS0005', name: 'E검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 E검사지' },
        { surveyId: 'MT0001', name: 'A검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 A검사지' },
        { surveyId: 'MT0002', name: 'B검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 B검사지' },
        { surveyId: 'MT0003', name: 'C검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 C검사지' },
        { surveyId: 'MT0004', name: 'D검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 D검사지' },
        // 교원 E검사지 추가 2024.02.08
        { surveyId: 'MT0005', name: 'E검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 E검사지' },

    ];

    const filteredOptions = surveyOptions.filter(option => option.schoolGrade === eventData.schoolGrade && option.target === eventData.surveyTarget);

    const handleViewSurvey = () => {
        if (!eventData.surveyId) {
            alert('검사지 유형을 선택하세요.');
            return;
        }
        if (eventData.surveyTarget === 'S') {
            setOpenStudentModal(true);
        } else {
            setOpenTeacherModal(true);
        }
    };

    const handleSchoolGradeChange = (e) => {
        setEventData({ ...eventData, schoolGrade: e.target.value, surveyId: '' });
    };

    const handleSurveyTargetChange = (e) => {
        setEventData({ ...eventData, surveyTarget: e.target.value, surveyId: '' });
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 검사이벤트관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">학교급</strong>
                            </div>
                            <div className="radio-form">
                                <span className="input-radio01">
                                    <input
                                        type="radio"
                                        id="gradeE"
                                        name="schoolGrade"
                                        className="radio01"
                                        value="E"
                                        checked={eventData.schoolGrade === 'E'}
                                        onChange={handleSchoolGradeChange}
                                    />
                                    <label htmlFor="gradeE" className="txt-label01">초</label>
                                </span>
                                <span className="input-radio01">
                                    <input
                                        type="radio"
                                        id="gradeM"
                                        name="schoolGrade"
                                        className="radio01"
                                        value="M"
                                        checked={eventData.schoolGrade === 'M'}
                                        onChange={handleSchoolGradeChange}
                                    />
                                    <label htmlFor="gradeM" className="txt-label01">중</label>
                                </span>
                                <span className="input-radio01">
                                    <input
                                        type="radio"
                                        id="gradeH"
                                        name="schoolGrade"
                                        className="radio01"
                                        value="H"
                                        checked={eventData.schoolGrade === 'H'}
                                        onChange={handleSchoolGradeChange}
                                    />
                                    <label htmlFor="gradeH" className="txt-label01">고</label>
                                </span>
                            </div>
                        </form>
                    </fieldset>

                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">검사 대상</strong>
                            </div>
                            <div className="radio-form">
                                <span className="input-radio01">
                                    <input
                                        type="radio"
                                        id="targetS"
                                        name="surveyTarget"
                                        className="radio01"
                                        value="S"
                                        checked={eventData.surveyTarget === 'S'}
                                        onChange={handleSurveyTargetChange}
                                    />
                                    <label htmlFor="targetS" className="txt-label01">학생</label>
                                </span>
                                <span className="input-radio01">
                                    <input
                                        type="radio"
                                        id="targetT"
                                        name="surveyTarget"
                                        className="radio01"
                                        value="T"
                                        checked={eventData.surveyTarget === 'T'}
                                        onChange={handleSurveyTargetChange}
                                    />
                                    <label htmlFor="targetT" className="txt-label01">교원</label>
                                </span>
                            </div>
                        </form>
                    </fieldset>

                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">검사유형</strong>
                            </div>
                            <select
                                id="surveyId"
                                name="surveyId"
                                className="select-type01"
                                value={eventData.surveyId}
                                onChange={(e) => setEventData({ ...eventData, surveyId: e.target.value })}
                            >
                                <option value="" disabled>검사지를 선택하세요.</option>
                                {filteredOptions.map(option => (
                                    <option key={option.surveyId} value={option.surveyId}>
                                        {option.name}({option.description})
                                    </option>
                                ))}
                            </select>
                        </form>
                    </fieldset>

                    <div className="btns-group03">
                        <button type="button" className="btn-type005 blue" onClick={handleViewSurvey} >검사지보기</button>
                    </div>
                </div>

                {/* Student Survey Modal */}
                <Modal
                    open={openStudentModal}
                    onClose={() => setOpenStudentModal(false)}
                    aria-labelledby="survey-modal-title"
                    aria-describedby="survey-modal-description"
                >
                    <Box sx={style}>
                        <OperatorStudentSurveyModalTest
                            open={openStudentModal}
                            onClose={() => setOpenStudentModal(false)}
                            surveyId={eventData.surveyId} // Pass the surveyId
                        />
                    </Box>
                </Modal>

                {/* Teacher Survey Modal */}
                <Modal
                    open={openTeacherModal}
                    onClose={() => setOpenTeacherModal(false)}
                    aria-labelledby="teacher-survey-modal-title"
                    aria-describedby="teacher-survey-modal-description"
                >
                    <Box sx={style}>
                        <OperatorTeacherSurveyModalTest
                            open={openTeacherModal}
                            onClose={() => setOpenTeacherModal(false)}
                            surveyId={eventData.surveyId} // Pass the surveyId
                        />
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default SurveyManage; 