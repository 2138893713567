import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';
import CustomToolbar from './components/CustomToolbar'; // CustomToolbar를 임포트합니다

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '80vw',
        height: '80vh',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

function SurveyResultGradeStudentDetail({ open, onClose, eventId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (eventId) {
            const fetchData = async () => {
                const response = await api.get(`/surveyresult/grade_student/${eventId}`);
                if (response.data && response.data.data) {
                    setData(response.data.data); // 첫 번째 객체로 설정
                }
                setIsLoading(false);
            };
            fetchData();
        }
    }, [eventId]);

    if (!data) return null;  // 데이터가 없으면 아무것도 표시하지 않습니다.

        // DataGrid의 컬럼 정의
        const columns = [
            { field: 'schoolYear', headerName: '학년', flex: 1 },
            { field: 'totalResponses', headerName: '총 응답 수', flex: 1 },
            { field: 'acesCount', headerName: 'ACES 수', flex: 1 },
            { field: 'middCount', headerName: 'MIDD 수', flex: 1 },
            { field: 'raseCount', headerName: 'RASE 수', flex: 1 },
            // 여기에 더 많은 컬럼을 추가할 수 있습니다.
        ];

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth={false}>
            <DialogTitle>{data[0].eventTitle}</DialogTitle>
            <DialogContent style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                overflow: 'auto', // 스크롤바 추가
                maxHeight: '70vh' // 최대 높이 설정. 필요에 따라 조절 가능
            }}>
                 <DataGrid slots={{ toolbar: () => <CustomToolbar fileName="학교급별결과상세(학생)" /> }} 
                    rows={data}
                    columns={columns}
                    getRowId={(row) => row.schoolYear} // 여기에 getRowId prop을 추가합니다.
                    disableColumnMenu={true}
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    disableRowSelectionOnClick={true}
                    loading={isLoading}
                />

                <div style={{ display: 'flex', gap: '12px' }}>
                    {/* Cancel 버튼은 항상 활성 상태입니다. */}
                    <Button onClick={onClose}>Cancel</Button>
                </div>

            </DialogContent>
        </StyledDialog>
    );
}

export default SurveyResultGradeStudentDetail;
