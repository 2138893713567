import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import api from './apiService'; // apiService.js에서 api 인스턴스 임포트
import { useNavigate } from 'react-router-dom';

function NoticeCreate({ selectedMenu }) {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        console.log(selectedFile); // 디버깅을 위한 로그
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        formData.append('author', '관리자');
        if (file) {
            formData.append('file', file, file.name);
        }

        try {
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }

            const response = await api.post('/api/notices', formData, {
                headers: {
                    // 멀티파트 폼 데이터를 위한 헤더 설정
                    'Content-Type': 'multipart/form-data'
                }
            });

            // ... 공지사항 생성 요청 로직 ...
            console.log('공지사항 생성 성공');

            navigate('/operator?menu=공지사항조회');
        } catch (error) {
            console.error('Error submitting notice: ', error);
            // 에러 처리 로직을 여기에 추가합니다.
        }
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 공지사항관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>


                <form onSubmit={handleSubmit} className="form-inner">
                    <fieldset className="fieldset-type03">
                        <div className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">제목</strong>
                            </div>
                            <div className="input-type01">
                                <input
                                    type="text"
                                    placeholder="제목을 입력하세요"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="input-text"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>

                        <div className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">내용</strong>
                            </div>
                            <div className="input-type002" style={{ height: '400px', width: '100%' }}>
                                <ReactQuill
                                    value={content}
                                    onChange={setContent}
                                    style={{ height: '90%', width: '100%' }} // 편집기의 높이 조정
                                />
                            </div>
                        </div>

                        <div className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">첨부 파일</strong>
                            </div>
                            <div className="input-type002">
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ width: '100%', padding: '10px 0' }} // 스타일 추가
                                />
                            </div>
                        </div>

                        <div className="btns-group03">
                            <button type="submit" className="btn-type005 blue">제출</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}

export default NoticeCreate;
