import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './apiService';
import './css/reset.css';
import './css/layout.css';
import './css/module.css';
import Modal from './components/Modal'; // Modal 컴포넌트 임포트

function ManagerAuthCheck() {
    const navigate = useNavigate();
    const [passwd, setPasswd] = useState('');
    const [checkStatus, setCheckStatus] = useState('default');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCheck = async () => {
        try {
            const response = await api.post('/schoolauth', { password: passwd });
            if (response.status === 200) {
                navigate('/managerauth', { state: { selectedMenu: '학교관리자-학교등록' } });
                setCheckStatus('success');
            } else {
                setCheckStatus('error');
                setErrorMessage(response.data.error + "[인증 비밀번호가 일치하지 않습니다. 교육청 관리자에게 문의하세요.]");
                setErrorDialogOpen(true);
            }
        } catch (error) {
            setCheckStatus('error');
            setErrorMessage(error.response.data.error + "[인증 실패. 교육청 관리자에게 문의하세요.]");
            setErrorDialogOpen(true);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // 폼의 기본 제출 동작 방지
            handleCheck();
        }
    };

    const handleCloseErrorDialog = () => {
        setErrorDialogOpen(false);
    };

    return (
        <div className="wrap">
            <div id="container" className="container page-admin">
                <div className="content-w-form">
                    <h2 className="title-type001">
                        <strong className="tit01">학교등록인증</strong>
                    </h2>
                    <div className="form-inner">
                        <div className="fieldset-type01">
                            <form className="input-group01" onSubmit={(e) => e.preventDefault()}>
                                <div className="input-type01">
                                    <label htmlFor="code" className="hide">인증비밀번호</label>
                                    <input
                                        id="code"
                                        type="password"
                                        placeholder="인증비밀번호를 입력하세요."
                                        value={passwd}
                                        onChange={(e) => setPasswd(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        className="input-field"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="btns-group03">
                            <button type="button" className="btn-type005 blue" onClick={handleCheck}>
                                <span className="txt">인증</span>
                            </button>
                        </div>                        
                        <div className="formBox-notice">
                            <div className="title-type002">
                                <strong className="tit01">인증비밀번호는 관리자에게 문의하세요.</strong>
                            </div>
                        </div>

                        <Modal
                            isOpen={errorDialogOpen}
                            onClose={handleCloseErrorDialog}
                            title="인증 실패"
                            message={errorMessage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManagerAuthCheck;