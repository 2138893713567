import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Top from './Top';
import LoginTop from './LoginTop';
import Menu from './Menu';
import OperatorMenu from './OperatorMenu';
import SupporterMenu from './SupporterMenu'; // Import your SupporterMenu
import SchoolManagerMenu from './SchoolManagerMenu';
import TeacherMenu from './TeacherMenu';
import StudentMenu from './StudentMenu';


function Header({ setSelectedMenu }) {
    const [loginInfo, setLoginInfo] = useState(null);
    const location = useLocation();

    useEffect(() => {
        // 로그인 정보를 localStorage에서 불러옴
        const storedLoginInfo = localStorage.getItem('loginInfo');
        if (storedLoginInfo) {
            setLoginInfo(JSON.parse(storedLoginInfo));
        }
    }, []);

    useEffect(() => {
        // 로그인 정보가 변경될 때마다 localStorage에 저장
        if (loginInfo) {
            localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
        }
    }, [loginInfo]);

    const showMenuBasedOnUserGroup = () => {
        if (loginInfo) {
            switch (loginInfo.data.usergroup) {
                case '1':
                    return <OperatorMenu setSelectedMenu={setSelectedMenu} />;
                case '2':
                    return <SchoolManagerMenu setSelectedMenu={setSelectedMenu} />;
                case '3':
                    return <TeacherMenu setSelectedMenu={setSelectedMenu} />;
                case '5': // Assuming '4' is the usergroup for Supporter
                    return <SupporterMenu setSelectedMenu={setSelectedMenu} />;
                default:
                    return <StudentMenu setSelectedMenu={setSelectedMenu} />;
            }
        }
        return null;
    };

    return (
        <header id="header">
            <div className="header-w">
            {loginInfo ? <LoginTop setSelectedMenu={setSelectedMenu} /> : <Top />}
            {location.pathname !== '/managerauth' ? (  // Menu가 학교관리자-등록인증인 경우 하위 메뉴 없음
                loginInfo ? (
                    showMenuBasedOnUserGroup()
                ) : (
                    <Menu />
                )
            ) : null}
            </div>
        </header>
    );
}

export default Header;
