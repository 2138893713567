import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './apiService';

function SupportOfficeCreate({ selectedMenu }) {
    const navigate = useNavigate();

    const eduOfficeMap = {
        'seoul': { no: '1', name: '서울특별시교육청' },
        'busan': { no: '2', name: '부산광역시교육청' },
        'daegu': { no: '3', name: '대구광역시교육청' },
        'incheon': { no: '4', name: '인천광역시교육청' },
        'gwangju': { no: '5', name: '광주광역시교육청' },
        'daejeon': { no: '6', name: '대전광역시교육청' },
        'ulsan': { no: '7', name: '울산광역시교육청' },
        'sejong': { no: '8', name: '세종특별자치시교육청' },
        'gyeonggi': { no: '10', name: '경기도교육청' },
        'gangwon': { no: '11', name: '강원특별자치도교육청' },
        'chungbuk': { no: '12', name: '충청북도교육청' },
        'chungnam': { no: '13', name: '충청남도교육청' },
        'jeonbuk': { no: '14', name: '전북특별자치도교육청' },
        'jeonnam': { no: '15', name: '전라남도교육청' },
        'gyeongbuk': { no: '16', name: '경상북도교육청' },
        'gyeongnam': { no: '17', name: '경상남도교육청' },
        'jeju': { no: '18', name: '제주특별자치도교육청' }
    };

    const subdomain = window.location.hostname.split('.')[0];
    const defaultEduOffice = eduOfficeMap[subdomain] || { no: '', name: 'Unknown' };

    const [officeTitle, setOfficeTitle] = useState('');
    const [eduOfficeNo, setEduOfficeNo] = useState(defaultEduOffice.no);

    const handleCreate = async () => {
        if (window.confirm("저장하시겠습니까?")) {
            try {
                const response = await api.post('/api/support-offices', {
                    supportOfficeTitle: officeTitle,
                    eduOfficeNo: parseInt(eduOfficeNo, 10)
                });
                if (response.status >= 200 && response.status < 300) {
                    alert("저장되었습니다.");
                    navigate('/operator?menu=교육지원청조회');
                }
            } catch (error) {
                alert("저장에 실패했습니다: " + error.message);
            }
        }
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 교육지원청관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="input-group02">
                                <div className="title-type002">
                                    <strong className="tit01">지원청 이름</strong>
                                </div>
                                <div className="input-type01">
                                    <input
                                        type="text"
                                        placeholder="지원청 이름을 입력하세요"
                                        value={officeTitle}
                                        onChange={(e) => setOfficeTitle(e.target.value)}
                                        className="input-text"
                                    />
                                </div>
                            </div>
                        </form>
                    </fieldset>
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="input-group02">
                                <div className="title-type002">
                                    <strong className="tit01">교육청</strong>
                                </div>
                                <div className="input-type01">
                                    <input
                                        type="text"
                                        value={defaultEduOffice.name}
                                        readOnly
                                        className="input-text"
                                    />
                                </div>
                            </div>
                        </form>
                    </fieldset>
                    <div className="btns-group03">
                        <button onClick={handleCreate} className="btn-type005 blue">저장</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupportOfficeCreate;