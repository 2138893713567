import React, { useState } from 'react';

function StudentMenu({ setSelectedMenu: setExternalSelectedMenu }) {
  const [selectedMenu, setSelectedMenu] = useState('');
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(''); // 클릭된 서브메뉴 상태
  const [activeMainMenu, setActiveMainMenu] = useState(''); // 클릭된 대메뉴 상태
  const [hoveredSubMenu, setHoveredSubMenu] = useState(''); // 마우스 오버된 서브메뉴 상태

  const subMenus = {
    '검사지': ['검사지조회'],
    '검사결과': ['본인']
  };

  const handleMenuClick = (menu) => {
    setSelectedMenu(selectedMenu === menu ? '' : menu); // Toggle menu
  };

  const handleSubMenuClick = (subMenu, mainMenu) => {
    console.log("subMenu clicked:" + subMenu);
    setExternalSelectedMenu(subMenu);
    setActiveSubMenu(subMenu); // 클릭된 서브메뉴 설정
    setActiveMainMenu(mainMenu); // 클릭된 대메뉴 설정
    setSelectedMenu(''); // 서브메뉴 클릭 시 메뉴 닫기
    setMobileMenuOpen(false); // 모바일 메뉴 닫기
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div className="headerLnb-w">
      <button type="button" className="btn-type001 menu" onClick={toggleMobileMenu}>
        <span className="ico-type001 menu"><i className="hide">메뉴열기</i></span>
      </button>
      <div className="headerLnb-inner">
        {Object.keys(subMenus).map(menu => (
          <div key={menu} className="menu-item" style={{ position: 'relative' }}>
            <a
              href="#none"
              className={`title-type002 ${selectedMenu === menu || activeMainMenu === menu ? 'current' : ''}`}
              onClick={() => handleMenuClick(menu)}
              style={{
                color: activeMainMenu === menu ? '#00796B' : 'white' // 대메뉴 글자색 변경
              }}
            >
              <span className="tit01-white">{menu}</span>
            </a>
            {selectedMenu === menu && (
              <div className="subMenu" style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                backgroundColor: '#E0F7FA', // 옅은 하늘색
                padding: '10px',
                borderRadius: '5px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                zIndex: 1000,
                whiteSpace: 'nowrap' // 한 줄에 표시
              }}>
                {subMenus[menu].map(subMenu => (
                  <a
                    key={subMenu}
                    href="#none"
                    className={`title-type002 ${activeSubMenu === subMenu ? 'active' : ''} ${hoveredSubMenu === subMenu ? 'hovered' : ''}`}
                    onClick={() => handleSubMenuClick(subMenu, menu)}
                    onMouseEnter={() => setHoveredSubMenu(subMenu)}
                    onMouseLeave={() => setHoveredSubMenu('')}
                  >
                    <span className="tit01">{subMenu}</span>
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {
        isMobileMenuOpen && (
          <div className="headerMobileMenu-w current">
            <div className="headerMobileMenu-inner">
              {Object.keys(subMenus).map(menu => (
                <div key={menu} className="mobile-menu-category">
                  <span className="mobile-menu-title">{menu}</span>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                    {subMenus[menu].map(subMenu => (
                      <a
                        key={subMenu}
                        href="#none"
                        className={`title-type002 ${activeSubMenu === subMenu ? 'active' : ''} ${hoveredSubMenu === subMenu ? 'hovered' : ''}`}
                        onClick={() => handleSubMenuClick(subMenu, menu)}
                        onMouseEnter={() => {
                          console.log(`Mouse entered: ${subMenu}`);
                          setHoveredSubMenu(subMenu);
                        }}
                        onMouseLeave={() => {
                          console.log(`Mouse left: ${subMenu}`);
                          setHoveredSubMenu('');
                        }}
                      >
                        <span className="tit01">{subMenu}</span>
                      </a>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <button type="button" className="btn-type001 close" onClick={closeMobileMenu}>
              <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
            </button>
          </div>
        )
      }
    </div >
  );
}

export default StudentMenu;