import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import api from './apiService'; // Import the custom api instance

// Import or define the eduOfficeMap
const eduOfficeMap = {
    '1': '서울특별시교육청',
    '2': '부산광역시교육청',
    '3': '대구광역시교육청',
    '4': '인천광역시교육청',
    '5': '광주광역시교육청',
    '6': '대전광역시교육청',
    '7': '울산광역시교육청',
    '8': '세종특별자치시교육청',
    '10': '경기도교육청',
    '11': '강원특별자치도교육청',
    '12': '충청북도교육청',
    '13': '충청남도교육청',
    '14': '전북특별자치도교육청',
    '15': '전라남도교육청',
    '16': '경상북도교육청',
    '17': '경상남도교육청',
    '18': '제주특별자치도교육청'
};

function SupportOfficeList({ selectedMenu }) {
    const [supportOffices, setSupportOffices] = useState([]);
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchSupportOffices();
    }, []);

    const fetchSupportOffices = async () => {
        try {
            const response = await api.get('/api/support-offices');
            // Map eduOfficeNo to eduOfficeName
            const officesWithNames = response.data.map(office => ({
                ...office,
                eduOfficeName: eduOfficeMap[office.eduOfficeNo] || 'Unknown'
            }));
            setSupportOffices(officesWithNames);
        } catch (error) {
            console.error("There was an error fetching the support offices!", error);
        }
    };

    const handleRowClick = (params) => {
        setSelectedOffice(params.row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedOffice(null);
    };

    const handleSave = async () => {
        if (selectedOffice) {
            if (window.confirm("저장하시겠습니까?")) {
                try {
                    const response = await api.put(`/api/support-offices/${selectedOffice.supportOfficeNo}`, selectedOffice);
                    if (response.status >= 200 && response.status < 300) {
                        alert("저장되었습니다.");
                        fetchSupportOffices();
                        setOpen(false);
                    }
                } catch (error) {
                    alert("저장에 실패했습니다: " + error.message);
                }
            }
        }
    };

    const handleDelete = async () => {
        if (selectedOffice) {
            if (window.confirm("삭제하시겠습니까?")) {
                try {
                    const response = await api.delete(`/api/support-offices/${selectedOffice.supportOfficeNo}`);
                    if (response.status >= 200 && response.status < 300) {
                        alert("삭제되었습니다.");
                        fetchSupportOffices();
                        setOpen(false);
                    }
                } catch (error) {
                    alert("삭제에 실패했습니다: " + error.message);
                }
            }
        }
    };

    const columns = [
        { field: 'supportOfficeNo', headerName: '번호', flex: 1 },
        { field: 'supportOfficeTitle', headerName: '이름', flex: 3 },
        { field: 'eduOfficeName', headerName: '교육청', flex: 2 } // Changed from eduOfficeNo to eduOfficeName
    ];

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 교육지원청관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={supportOffices}
                            columns={columns}
                            pageSize={5}
                            onRowClick={handleRowClick}
                            getRowId={(row) => row.supportOfficeNo} // Use supportOfficeNo as the unique ID
                        />
                    </div>
                </div>
            </div>

            {open && (
               <div id="modalJoin2" className="modal-w active" aria-modal="true">
                    <div className="modal-inner-type45">
                        <fieldset className="fieldset-type001">
                            <div className="title-type001">
                                <strong className="tit01">교육지원청 수정</strong>
                            </div>
                            <div className="form-inner">

                                <form className="input-group01">
                                    <div className="input-type01">
                                        <label htmlFor="supportOfficeTitle" className="hide">이름</label>
                                        <input
                                            id="supportOfficeTitle"
                                            type="text"
                                            value={selectedOffice?.supportOfficeTitle || ''}
                                            onChange={(e) => setSelectedOffice({ ...selectedOffice, supportOfficeTitle: e.target.value })}
                                            className="input-text"
                                        />
                                    </div>
                                </form>

                            </div>
                            <div className="btns-group03">
                                <button onClick={handleClose} className="btn-type005 border">닫기</button>
                                <button onClick={handleDelete} className="btn-type005 border">삭제</button>
                                <button onClick={handleSave} className="btn-type005 blue">저장</button>
                            </div>
                        </fieldset>
                    </div>
                    <i className="dimm"></i>
                </div>
            )}
        </div>
    );
}

export default SupportOfficeList;