// ginue-react-app/src/components/Footer.js
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; // Import downward arrow icon


function Footer() {
    const [isVisible, setIsVisible] = useState(true); // State to control visibility

    const handleHideFooter = () => {
        setIsVisible(false); // Hide the footer
    };

    if (!isVisible) return null; // Don't render the footer if not visible

    return (
        <footer id="footer" className="footer-w" style={{ position: 'relative' }}>
            <div className="footerCont-top">
                <a href="#" className="title-type003">
                    <span className="tit01-white">개인정보처리방침</span>
                </a>
            </div>
            <div className="footerCont-bottom">
                <div className="footerCont-l">
                    <a href="https://www.ginue.ac.kr/" target="_blank" rel="noopener noreferrer">
                        <span className="logo-univ"><i className="hide">경인교육대학교</i></span>
                    </a>
                </div>
                <div className="footerCont-r">
                    <div className="footerList-w">
                        <div className="footerList-inner">
                            <strong className="title-type003"><span className="tit01-white">인천캠퍼스</span></strong>
                            <span className="title-type003"><span className="tit01-white">[21044] 인천광역시 계양구 계산로 62 TEL : <a href="tel:032-540-114">032) 540-1114</a> FAX : 032) 541-0580</span></span>
                        </div>
                        <div className="footerList-inner">
                            <strong className="title-type003"><span className="tit01-white">경기캠퍼스</span></strong>
                            <span className="title-type003"><span className="tit01-white">[13910] 경기도 안양시 만안구 삼막로 155 TEL : <a href="tel:031-470-6114">031) 470-6114</a> FAX : 031) 470-6139</span></span>
                        </div>
                        <div className="footerList-inner">
                            <span className="title-type003"><span className="tit01-white">Copyright Gyeongin National University of Education All rights reserved.</span></span>
                        </div>
                    </div>
                </div>
            </div>
            {/* Dismiss button */}
            <button onClick={handleHideFooter} style={{ color: 'white', position: 'absolute', right: '10px', bottom: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
                <KeyboardArrowDownIcon />
            </button>
        </footer>
    );
}

export default Footer;