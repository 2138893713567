import React from 'react';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

// fileName을 prop으로 받는 CustomToolbar 컴포넌트
function CustomToolbar({ fileName }) {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    fileName: fileName, // prop으로 받은 fileName을 사용
                    utf8WithBom: true,
                }}
                printOptions={{
                    hideFooter: true,
                    hideToolbar: true,
                }}
            />
        </GridToolbarContainer>
    );
}

export default CustomToolbar;