import React from 'react';

function Modal({ isOpen, onClose, message, title }) {
  if (!isOpen) return null;

  return (
    <div className={`modal-w ${isOpen ? 'active' : ''}`} aria-modal="true">
      <div className="modal-inner-type25">
        <div className="title-type001">
          <strong className="tit01">{title}</strong>
        </div>
        <div className="title-type003">
          <strong className="tit01-grey">{message}</strong>
        </div>
        <button type="button" className="btn-type005 border close" onClick={onClose}>
          <span className="txt01">닫기</span>
        </button>
      </div>
      <i className="dimm"></i>
    </div>
  );
}

export default Modal;