import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import api from './apiService';
import CustomToolbar from './components/CustomToolbar'; // CustomToolbar를 임포트합니다

function SurveyResultbySchoolTeacher({ selectedMenu }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    // 컬럼 정의를 새로운 데이터 구조에 맞게 수정
    const columns = [
        { field: 'schoolName', headerName: '학교명', flex: 1.5 },
        { field: 'schoolCode', headerName: '학교 코드', flex: 1 },
        { field: 'eventId', headerName: '이벤트ID', flex: 1 },
        { field: 'totalResponses', headerName: '총 응답 수', flex: 1 },
        { field: 'totalAces', headerName: 'ACES 수', flex: 1 },
        { field: 'totalMidd', headerName: 'MIDD 수', flex: 1 },
        { field: 'totalRase', headerName: 'RASE 수', flex: 1 },
    ];

    const handleSearch = (searchValue) => {
        // API 호출 시에는 searchValue 인자를 사용합니다.
        api.post("/surveyresult/bySchool/teacher", { school_name: searchValue })
            .then(response => {
                const newData = response.data.data.map(item => ({ ...item, id: item.eventId }));
                setData(newData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("API fetch error:", error);
            });
    };

    useEffect(() => {
        handleSearch();
    }, []);

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    // Create a ref for the TextField
    const inputRef = useRef(null);

    // Handler for the Enter key press in the TextField
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e.target.value);
        }
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 검사결과관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <fieldset style={{ border: '0px solid #ccc', padding: '10px', marginBottom: '20px' }}>
                    <legend>검색</legend>
                    <form className="input-group01" onSubmit={(e) => e.preventDefault()}>
                        <div className="input-type01">
                            <label htmlFor="search" className="hide">학교명</label>
                            <input
                                id="search"
                                type="text"
                                placeholder="학교명을 입력하세요"
                                ref={inputRef}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                        <button
                            type="button"
                            className="btn-type005 blue"
                            onClick={() => handleSearch(inputRef.current.value)}
                        >
                            <span className="txt01">검색</span>
                        </button>
                    </form>
                </fieldset>

                <div style={{ width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 20]}
                        onPageChange={handlePageChange}
                        getRowId={(row) => `${row.teacherCode}-${row.eventId}`}
                        slots={{
                            toolbar: () => <CustomToolbar fileName="학교별결과(교원)" />,
                            noRowsOverlay: () => (
                                <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                    <strong className="tit01">조회된 데이터가 없습니다.</strong>
                                </div>
                            )
                        }}
                        loading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
}

export default SurveyResultbySchoolTeacher;
