import React from 'react';
import { Box, Paper, Grid, useTheme } from '@mui/material';

const TeacherLinks = ({ schoolGrade }) => {
    const theme = useTheme();
    const isElementary = schoolGrade === 'E';

    /* 통합 이미지 */
    const webtoonImages = isElementary
        ? Array.from({ length: 1 }, (_, index) => `/assets/links/T/all0${index}.png`)
        : Array.from({ length: 1 }, (_, index) => `/assets/links/T/all0${index}.png`);

    return (
        <>
            <Box mb={4} sx={{ maxWidth: '100vw', margin: 'auto' }}>
                <Grid className="grid-container-print" container spacing={2}>
                    {webtoonImages.map((image, index) => (
                        <Grid item xs={12} md={12} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '100vw' }}>
                                <img
                                    src={image}
                                    alt={`웹툰 이미지 ${index}`}
                                    useMap="#webtoon-map"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                                <map name="webtoon-map">
                                    <area
                                        shape="rect"
                                        coords="46,1042,224,1295" // 첫 번째 링크 영역
                                        href="https://m.site.naver.com/1geAX"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        alt="교사용 2화"
                                    />
                                    <area
                                        shape="rect"
                                        coords="227,1042,406,1295" // 두 번째 링크 영역
                                        href="https://m.site.naver.com/1geIk"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        alt="교사용 3화"
                                    />
                                    <area
                                        shape="rect"
                                        coords="407,1042,584,1295" // 세 번째 링크 영역
                                        href="https://m.site.naver.com/1geIv"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        alt="교사용 4화"
                                    />
                                    <area
                                        shape="rect"
                                        coords="589,1042,763,1295" // 네 번째 링크 영역
                                        href="https://m.site.naver.com/1geIA"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        alt="교사용 5화"
                                    />
                                    <area
                                        shape="rect"
                                        coords="766,1042,940,1295" // 다섯 번째 링크 영역
                                        href="https://m.site.naver.com/1geIF"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        alt="교사용 6화"
                                    />
                                </map>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default TeacherLinks;