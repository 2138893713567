import React, { useState, useEffect } from 'react';
import api from './apiService';

function TeacherInfoDetail({ userId, onClose }) {
    const initialTeacherState = {
        teacherId: '',
        teacherPasswd: '',
        teacherType: '',
        schoolYear: '',
        schoolClass: '',
        teacherName: '',
        registerDate: '',
        schoolCode: '',
        schoolDTO: {
            schoolCode: '',
            schoolName: '',
            schoolGrade: '',
            schoolZipcode: '',
            schoolAddress1: '',
            schoolAddress2: '',
            teacherPasswd: ''
        }
    };

    const [teacherData, setTeacherData] = useState(initialTeacherState);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [classData, setClassData] = useState({
        schoolYear: [],
        schoolClass: []
    });
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedClass, setSelectedClass] = useState('');

    const [schoolCode, setSchoolCode] = useState('');
    
    useEffect(() => {
        const storedSchoolCode = localStorage.getItem('schoolCode');
        if (storedSchoolCode) {
            setSchoolCode(storedSchoolCode);
        }
    }, []);

    useEffect(() => {
        setTeacherData(prev => ({
            ...prev,
            schoolYear: selectedYear,
            schoolClass: selectedClass
        }));
    }, [selectedYear, selectedClass]);

    const handleTeacherTypeChange = (event) => {
        const newType = event.target.value;
        setTeacherData({ ...teacherData, teacherType: newType });

        if (newType === 'B') {
            setSelectedYear('');
            setSelectedClass('');
            setTeacherData(prev => ({
                ...prev,
                schoolYear: '',
                schoolClass: ''
            }));
        }
    };

    useEffect(() => {
        if (schoolCode) {
            fetchClassData();
        }
    }, [schoolCode]);

    const fetchClassData = async () => {
        try {
            const response = await api.get(`/school/class?schoolCode=${schoolCode}`);
            if (response.status === 200 && response.data.data) {
                const fetchedData = response.data.data;

                const uniqueYears = new Set();
                const uniqueClasses = new Set();

                fetchedData.forEach(item => {
                    uniqueYears.add(item.schoolYear);
                    uniqueClasses.add(item.schoolClass);
                });

                setClassData({
                    schoolYear: [...uniqueYears],
                    schoolClass: [...uniqueClasses]
                });

            } else {
                setClassData(generateDefaultClassData());
            }
        } catch (error) {
            console.error("Error fetching class data:", error);
            setClassData(generateDefaultClassData());
        }
    };

    const generateDefaultClassData = () => {
        const defaultData = {
            schoolYear: [],
            schoolClass: []
        };

        for (let year = 1; year <= 6; year++) {
            defaultData.schoolYear.push(year);
            for (let classNum = 1; classNum <= 10; classNum++) {
                if (year === 1) {
                    defaultData.schoolClass.push(classNum);
                }
            }
        }

        return defaultData;
    };

    useEffect(() => {
        const fetchTeacherData = async () => {
            try {
                const response = await api.get(`/schoolmgr/teacher/${userId}`);
                if (response.status === 200 && response.data.data) {
                    setTeacherData(response.data.data);
                } else {
                    console.log('Response status is not 200 or data is missing');
                }
            } catch (error) {
                console.error('Error fetching school manager data:', error);
            }
        };

        if (userId) {
            fetchTeacherData();
        } else {
            console.log('userId is missing');
        }
    }, [userId]);

    const handleSave = async () => {
        if (!window.confirm('저장하시겠습니까?')) {
            return;
        }

        if (!newPassword) {
            alert('패스워드를 입력하세요.');
            return;
        }
        
        if (newPassword !== confirmNewPassword) {
            alert('패스워드가 일치하지 않습니다.');
            return;
        }

        try {
            const updatedData = {
                ...teacherData,
                teacherPasswd: newPassword,
                registerDate: new Date().toISOString()
            };

            console.log(updatedData);
            const response = await api.put(`/schoolmgr/teacher/${userId}`, updatedData);

            if (response.status === 200 && response.data.data) {
                alert('정상적으로 변경했습니다.');
                onClose();
            } else {
                alert('문제가 발생했습니다.');
            }
        } catch (error) {
            alert('문제가 발생했습니다.');
            console.error(error);
        }
    };

    return (
        <div id="modalEdit" className="modal-w active">
            <div className="modal-inner-type45">
                <div className="title-type001">
                    <strong className="tit01">선생님 정보 수정</strong>
                </div>
                <fieldset className="fieldset-type02">
                    <div className="input-type01">
                        <label htmlFor="id" className="hide">아이디</label>
                        <input id="id" type="text" value={teacherData.teacherId} readOnly placeholder="아이디(최대 10자리)" />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="pwd2" className="hide">새로운 비밀번호</label>
                        <input id="pwd2" type="password" placeholder="새로운 비밀번호" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="pwd3" className="hide">새로운 비밀번호 확인</label>
                        <input id="pwd3" type="password" placeholder="새로운 비밀번호 확인" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="name" className="hide">이름</label>
                        <input id="name" type="text" placeholder="이름" value={teacherData.teacherName} onChange={(e) => setTeacherData({ ...teacherData, teacherName: e.target.value })} />
                    </div>
                </fieldset>

                <fieldset className="fieldset-type02">
                    <div className="title-type002">
                        <strong className="tit01">담임</strong>
                    </div>
                    <div className="radio-form">
                        <span className="input-radio01">
                            <input type="radio" id="type01" name="types" className="radio01" value="C" checked={teacherData.teacherType === 'C'} onChange={handleTeacherTypeChange} />
                            <label htmlFor="type01" className="txt-label01">담임</label>
                        </span>
                        <span className="input-radio01">
                            <input type="radio" id="type02" name="types" className="radio01" value="B" checked={teacherData.teacherType === 'B'} onChange={handleTeacherTypeChange} />
                            <label htmlFor="type02" className="txt-label01">비담임</label>
                        </span>
                    </div>
                    <select id="type1" name="" className="select-type01" value={teacherData.schoolYear} onChange={(e) => setTeacherData({ ...teacherData, schoolYear: e.target.value })} disabled={teacherData.teacherType === 'B'}>
                        <option disabled selected hidden>학년을 선택하세요</option>
                        {classData.schoolYear.map((year, index) => (
                            <option key={index} value={year}>{year}학년</option>
                        ))}
                    </select>
                    <select id="type2" name="" className="select-type01" value={teacherData.schoolClass} onChange={(e) => setTeacherData({ ...teacherData, schoolClass: e.target.value })} disabled={teacherData.teacherType === 'B'}>
                        <option disabled selected hidden>반을 선택하세요</option>
                        {classData.schoolClass.map((classNum, index) => (
                            <option key={index} value={classNum}>{classNum}반</option>
                        ))}
                    </select>
                    <button type="button" className="btn-type005 blue" onClick={handleSave}><span className="txt">저장</span></button>
                </fieldset>

                <button type="button" className="btn-type005 border close" onClick={onClose}>
                    <span className="txt01">닫기</span>
                </button>
            </div>
            <i className="dimm"></i>
        </div>
    );
}

export default TeacherInfoDetail;