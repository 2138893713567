import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ManagerSchoolDetail from './ManagerSchoolDetail';
import ManagerClass from './ManagerClass';
import ManagerTeacherSearch from './ManagerTeacherSearch';
import ManagerSurveyResultTeacher from './ManagerSurveyResultTeacher';
import ManagerSurveyResultStudent from './ManagerSurveyResultStudent';
import MyInfo from './MyInfo';

function Manager() {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [schoolCode, setSchoolCode] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const menuFromURL = queryParams.get('menu');
    if (menuFromURL) {
      setSelectedMenu(menuFromURL);
    } else if (location.state?.selectedMenu) {
      setSelectedMenu(location.state.selectedMenu);
    } else {
      setSelectedMenu('학교정보관리');
    }

    const storedSchoolCode = localStorage.getItem('schoolCode');
    if (storedSchoolCode) {
      setSchoolCode(storedSchoolCode);
    }
  }, [location]);

  useEffect(() => {
    const loginInfo = localStorage.getItem('loginInfo');
    if (!loginInfo) {
      navigate('/login');
    }
  }, [selectedMenu]);

  const changeMenu = (menu) => {
    setSelectedMenu(menu);
    navigate(`/manager?menu=${menu}`, { state: { selectedMenu: menu } });
  };

  return (
    <div id="wrap">
      <Header setSelectedMenu={changeMenu} />

      {selectedMenu === "학교정보관리" && (
        <ManagerSchoolDetail schoolCode={schoolCode} currentSection={selectedMenu} />
      )}
      {selectedMenu === "학급관리" && (
        <ManagerClass schoolCode={schoolCode}  currentSection={selectedMenu}/>
      )}
      {selectedMenu === "교원관리" && <ManagerTeacherSearch schoolCode={schoolCode} currentSection={selectedMenu}/>}
      {selectedMenu === "교원" && <ManagerSurveyResultTeacher schoolCode={schoolCode} currentSection={selectedMenu}/>}
      {selectedMenu === "학생" && <ManagerSurveyResultStudent schoolCode={schoolCode} currentSection={selectedMenu}/>}
      {selectedMenu === "내정보" && <MyInfo />}

      {/* 다른 메뉴에 대한 컴포넌트도 이와 비슷한 방식으로 조건부 렌더링 할 수 있습니다. */}

      {/*<Footer />*/}
    </div>
  );
}

export default Manager;
