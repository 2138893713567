import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        fontSize: '0.8rem',
    },
    '& .MuiInputBase-input': {
        fontSize: '0.9rem',
    },
    '& .MuiInputLabel-root': {
        fontSize: '0.9rem',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&:hover fieldset': {
            borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
        },
    },
});

// Similarly for FormControlLabel, if you want to adjust styles
const StyledFormControlLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        // Adjust the font size here for the labels next to the radios
        fontSize: '0.9rem',
    },
});

// StyledDialog은 동일하므로 이전 컴포넌트에서 가져온 스타일을 그대로 사용합니다.
const SurveyEventCreatePage = styled('div')(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '30%',
        maxWidth: '800px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

const initialState = {
    eventTitle: '',
    eventStartDate: '',
    eventEndDate: '',
    schoolGrade: 'E', // 기본값으로 'E' 설정
    surveyTarget: 'S', // 기본값으로 'S' 설정
    surveyId: '',
};


function SurveyEventCreate({ selectedMenu }) {
    const navigate = useNavigate();
    // 새 이벤트의 초기 상태 정의
    const [eventData, setEventData] = useState({
        eventTitle: '',
        eventStartDate: '',
        eventEndDate: '',
        schoolGrade: 'E', // 기본값으로 'E' 설정
        surveyTarget: 'S', // 기본값으로 'S' 설정
        surveyId: '',
    });

    const handleSave = async () => {
        // 입력값 검증
        if (!eventData.eventTitle) {
            alert("이벤트명을 입력하세요.");
            return;
        }
        if (!eventData.eventStartDate) {
            alert("시작일을 선택하세요.");
            return;
        }
        if (!eventData.eventEndDate) {
            alert("종료일을 선택하세요.");
            return;
        }
        if (!eventData.surveyId) {
            alert("검사 유형을 선택하세요.");
            return;
        }

        const dataToSave = {
            eventTitle: eventData.eventTitle,
            eventStartDate: eventData.eventStartDate,
            eventEndDate: eventData.eventEndDate,
            schoolGrade: eventData.schoolGrade,
            surveyTarget: eventData.surveyTarget,
            surveyId: eventData.surveyId
        };
        try {
            const response = await api.post(`/surveyevent`, dataToSave);
            if (response.status === 201) {
                navigate('/operator', { state: { selectedMenu: '검사이벤트조회' } });
            } else {
                // Handle any other statuses appropriately
                console.error("Event was not created. Status code:", response.status);
                // Show a message to the user if needed
            }
        } catch (error) {
            console.error("There was an error saving the event", error);
            // Handle error cases here, potentially show a message to the user
        }
    };

    const handleCancel = () => {
        setEventData(initialState); // Reset the eventData state to its initial state
    };
    // 새 이벤트를 위한 필터링된 옵션을 제공하는 로직을 작성합니다.
    // 아래의 surveyOptions는 동일한 배열을 사용하거나, 서버에서 가져올 수도 있습니다.
    // ...surveyOptions와 filteredOptions 정의...

    const surveyOptions = [
        { surveyId: 'ES0001', name: 'A검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 A검사지' },
        { surveyId: 'ES0002', name: 'B검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 B검사지' },
        { surveyId: 'ES0003', name: 'C검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 C검사지' },
        { surveyId: 'ES0004', name: 'D검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 D검사지' },
        // 학생 E검사지 추가 2024.02.08
        { surveyId: 'ES0005', name: 'E검사지(학생)', schoolGrade: 'E', target: 'S', description: '초등학교 학생용 E검사지' },
        { surveyId: 'ET0001', name: 'A검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 A검사지' },
        { surveyId: 'ET0002', name: 'B검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 B검사지' },
        { surveyId: 'ET0003', name: 'C검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 C검사지' },
        { surveyId: 'ET0004', name: 'D검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 D검사지' },
        // 교원 E검사지 추가 2024.02.08
        { surveyId: 'ET0005', name: 'E검사지(교원)', schoolGrade: 'E', target: 'T', description: '초등학교 교원용 E검사지' },
        { surveyId: 'HS0001', name: 'A검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 A검사지' },
        { surveyId: 'HS0002', name: 'B검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 B검사지' },
        { surveyId: 'HS0003', name: 'C검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 C검사지' },
        { surveyId: 'HS0004', name: 'D검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 D검사지' },
        // 학생 E검사지 추가 2024.02.08
        { surveyId: 'HS0005', name: 'E검사지(학생)', schoolGrade: 'H', target: 'S', description: '고등학교 학생용 E검사지' },
        { surveyId: 'HT0001', name: 'A검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 A검사지' },
        { surveyId: 'HT0002', name: 'B검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 B검사지' },
        { surveyId: 'HT0003', name: 'C검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 C검사지' },
        { surveyId: 'HT0004', name: 'D검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 D검사지' },
        // 교원 E검사지 추가 2024.02.08
        { surveyId: 'HT0005', name: 'E검사지(교원)', schoolGrade: 'H', target: 'T', description: '고등학교 교원용 E검사지' },
        { surveyId: 'MS0001', name: 'A검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 A검사지' },
        { surveyId: 'MS0002', name: 'B검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 B검사지' },
        { surveyId: 'MS0003', name: 'C검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 C검사지' },
        { surveyId: 'MS0004', name: 'D검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 D검사지' },
        // 학생 E검사지 추가 2024.02.08
        { surveyId: 'MS0005', name: 'E검사지(학생)', schoolGrade: 'M', target: 'S', description: '중학교 학생용 E검사지' },
        { surveyId: 'MT0001', name: 'A검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 A검사지' },
        { surveyId: 'MT0002', name: 'B검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 B검사지' },
        { surveyId: 'MT0003', name: 'C검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 C검사지' },
        { surveyId: 'MT0004', name: 'D검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 D검사지' },
        // 교원 E검사지 추가 2024.02.08
        { surveyId: 'MT0005', name: 'E검사지(교원)', schoolGrade: 'M', target: 'T', description: '중학교 교원용 E검사지' },

    ];

    const filteredOptions = surveyOptions.filter(option => option.schoolGrade === eventData.schoolGrade && option.target === eventData.surveyTarget);

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 검사이벤트관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">이벤트명</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="eventTitle" className="hide">이벤트명</label>
                                <input
                                    id="eventTitle"
                                    type="text"
                                    placeholder="이벤트명을 입력하세요"
                                    value={eventData.eventTitle}
                                    onChange={(e) => setEventData({ ...eventData, eventTitle: e.target.value })}
                                    
                                />
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">시작일</strong>
                            </div>
                            <div className="input-date001">
                                <label htmlFor="dateStart" className="hide">시작날짜</label>
                                <input
                                    id="dateStart"
                                    type="datetime-local"
                                    placeholder="날짜 선택"
                                    value={eventData.eventStartDate}
                                    onChange={(e) => {
                                        let dateTimeValue = e.target.value;
                                        if (dateTimeValue && dateTimeValue.length === 16) {
                                            dateTimeValue += ":00";
                                        }
                                        setEventData({ ...eventData, eventStartDate: dateTimeValue });
                                    }}
                                />
                            </div>
                        </form>
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">종료일</strong>
                            </div>
                            <div className="input-date001">
                                <label htmlFor="dateEnd" className="hide">종료날짜</label>
                                <input
                                    id="dateEnd"
                                    type="datetime-local"
                                    placeholder="날짜 선택"
                                    value={eventData.eventEndDate}
                                    onChange={(e) => {
                                        let dateTimeValue = e.target.value;
                                        if (dateTimeValue && dateTimeValue.length === 16) {
                                            dateTimeValue += ":00";
                                        }
                                        setEventData({ ...eventData, eventEndDate: dateTimeValue });
                                    }}
                                />
                            </div>
                        </form>
                    </fieldset>

                    <fieldset className="fieldset-type02">
                        <div className="title-type002">
                            <strong className="tit01">학교급</strong>
                        </div>
                        <div className="radio-form">
                            <span className="input-radio01">
                                <input
                                    type="radio"
                                    id="type01"
                                    name="types"
                                    className="radio01"
                                    value="E"
                                    checked={eventData.schoolGrade === 'E'}
                                    onChange={(e) => setEventData({ ...eventData, schoolGrade: e.target.value })}
                                />
                                <label htmlFor="type01" className="txt-label01">초</label>
                            </span>
                            <span className="input-radio01">
                                <input
                                    type="radio"
                                    id="type02"
                                    name="types"
                                    className="radio01"
                                    value="M"
                                    checked={eventData.schoolGrade === 'M'}
                                    onChange={(e) => setEventData({ ...eventData, schoolGrade: e.target.value })}
                                />
                                <label htmlFor="type02" className="txt-label01">중</label>
                            </span>
                            <span className="input-radio01">
                                <input
                                    type="radio"
                                    id="type03"
                                    name="types"
                                    className="radio01"
                                    value="H"
                                    checked={eventData.schoolGrade === 'H'}
                                    onChange={(e) => setEventData({ ...eventData, schoolGrade: e.target.value })}
                                />
                                <label htmlFor="type03" className="txt-label01">고</label>
                            </span>
                        </div>
                    </fieldset>

                    <fieldset className="fieldset-type02">
                        <div className="title-type002">
                            <strong className="tit01">검사 대상</strong>
                        </div>
                        <div className="radio-form">
                            <span className="input-radio01">
                                <input
                                    type="radio"
                                    id="type01"
                                    name="types2"
                                    className="radio01"
                                    value="S"
                                    checked={eventData.surveyTarget === 'S'}
                                    onChange={(e) => setEventData({ ...eventData, surveyTarget: e.target.value })}
                                />
                                <label htmlFor="type01" className="txt-label01">학생</label>
                            </span>
                            <span className="input-radio01">
                                <input
                                    type="radio"
                                    id="type02"
                                    name="types2"
                                    className="radio01"
                                    value="T"
                                    checked={eventData.surveyTarget === 'T'}
                                    onChange={(e) => setEventData({ ...eventData, surveyTarget: e.target.value })}
                                />
                                <label htmlFor="type02" className="txt-label01">교원</label>
                            </span>
                        </div>
                    </fieldset>

                    <fieldset className="fieldset-type02">
                        <div className="title-type002">
                            <strong className="tit01">검사유형</strong>
                        </div>
                        <select
                            id="type1"
                            name=""
                            className="select-type01"
                            value={eventData.surveyId}
                            onChange={(e) => setEventData({ ...eventData, surveyId: e.target.value })}
                        >
                            <option value="">검사지를 선택하세요</option>
                            {filteredOptions.map(option => (
                                <option key={option.surveyId} value={option.surveyId}>
                                    {option.name}({option.description})
                                </option>
                            ))}
                        </select>
                    </fieldset>

                    <div className="btns-group03">
                        <button type="submit" className="btn-type005 blue" onClick={handleSave}>저장</button>
                        <button type="button" className="btn-type005 border" onClick={handleCancel}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SurveyEventCreate;