import React, { useState } from 'react';
import ReactQuill from 'react-quill'; // 편집 도구 import
import 'react-quill/dist/quill.snow.css'; // 스타일 import
import api from './apiService'; // API 호출을 위한 서비스 import

function NoticeEdit({ notice, onClose, onUpdated }) {
    const [title, setTitle] = useState(notice.title);
    const [content, setContent] = useState(notice.content);
    const [author, setAuthor] = useState(notice.author);
    const [file, setFile] = useState(null);
    const [existingFile, setExistingFile] = useState(notice.attachments[0] || null);

    // 파일 변경 핸들러
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpdate = async () => {
        if (!window.confirm("저장하시겠습니까?")) {
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        formData.append('author', author);
        if (file) {
            formData.append('file', file, file.name);
        }

        try {
            const response = await api.post(`/api/notices/${notice.notice_id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status >= 200 && response.status < 300) {
                alert("저장되었습니다.");
                onClose();
                onUpdated();
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status >= 400 && error.response.status < 600) {
                    alert("저장에 실패했습니다: " + error.response.data.message);
                }
            } else {
                console.error('Error updating notice: ', error);
            }
        }
    };

    const handleDelete = async () => {
        if (!window.confirm("삭제하시겠습니까?")) {
            return;
        }

        try {
            const response = await api.delete(`/api/notices/${notice.notice_id}`);
            
            if (response.status >= 200 && response.status < 300) {
                alert("삭제되었습니다.");
                onClose(); // 모달 닫기
                onUpdated(); // 공지사항 목록 다시 로딩
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status >= 400 && error.response.status < 600) {
                    alert("삭제에 실패했습니다: " + error.response.data.message);
                }
            } else {
                console.error('Error deleting notice: ', error);
            }
        }
    };

    return (
        <>
            <div className="title-type001">
                <strong className="tit01">공지사항 수정</strong>
            </div>
            <fieldset className="fieldset-type02">
                <form className="input-group02">
                    <div className="title-type002">
                        <strong className="tit01">제목</strong>
                    </div>
                    <label htmlFor="title" className="hide">제목</label>
                    <div className="input-type01">
                        <input
                            id="title"
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="input-text"
                        />
                    </div>
                </form>
                <form className="input-group02">
                    <div className="title-type002">
                        <strong className="tit01">내용</strong>
                    </div>
                    <div className="input-type002" style={{ height: '300px', width: '100%' }}>
                        <ReactQuill
                            value={content}
                            onChange={setContent}
                            style={{ height: '80%', width: '100%' }} // 전체 너비와 더 높은 높이로 설정
                        />
                    </div>
                </form>

                <form className="input-group02">
                    <div className="title-type002">
                        <strong className="tit01">첨부 파일</strong>
                    </div>
                    <div className="input-type002">
                        {existingFile && <p>현재 파일: {existingFile.fileName}</p>}
                        <input type="file" onChange={handleFileChange} />
                    </div>
                </form>

                <form className="input-group02">
                    <div className="title-type002">
                        <strong className="tit01">작성자</strong>
                    </div>
                    <div className="input-type01">
                        <input
                            type="text"
                            value={author}
                            onChange={(e) => setAuthor(e.target.value)}
                            className="input-text"
                        />
                    </div>
                </form>
                <div className="btns-group03">
                    <button className="btn-type005 blue" onClick={handleUpdate}>저장</button>
                    <button className="btn-type005 border" onClick={handleDelete}>삭제</button>
                    <button className="btn-type005 border" onClick={onClose}>닫기</button>
                </div>
            </fieldset>
        </>
    );
}

export default NoticeEdit;
