import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import api from './apiService';

function SupportOfficeManagerList({ selectedMenu }) {
    const [supporters, setSupporters] = useState([]);
    const [selectedSupporter, setSelectedSupporter] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchSupporters();
    }, []);

    const fetchSupporters = async () => {
        try {
            const response = await api.get('/api/supporters');
            setSupporters(response.data);
        } catch (error) {
            console.error("There was an error fetching the supporters!", error);
        }
    };

    const handleRowClick = (params) => {
        setSelectedSupporter(params.row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedSupporter(null);
    };

    const handleSave = async () => {
        if (selectedSupporter) {
            if (window.confirm("저장하시겠습니까?")) {
                try {
                    await api.put(`/api/supporters/${selectedSupporter.supporterId}`, selectedSupporter);
                    alert("저장되었습니다.");
                    fetchSupporters();
                    setOpen(false);
                } catch (error) {
                    alert("저장에 실패했습니다: " + error.message);
                }
            }
        }
    };

    const handleDelete = async () => {
        if (selectedSupporter) {
            if (window.confirm("삭제하시겠습니까?")) {
                try {
                    await api.delete(`/api/supporters/${selectedSupporter.supporterId}`);
                    alert("삭제되었습니다.");
                    fetchSupporters();
                    setOpen(false);
                } catch (error) {
                    alert("삭제에 실패했습니다: " + error.message);
                }
            }
        }
    };

    const columns = [
        { field: 'supporterId', headerName: '교육청ID', flex: 2 },
        { field: 'supporterPasswd', headerName: '비밀번호', flex: 2 },
        { field: 'supportOfficeName', headerName: '교육지원청', flex: 3 },
        { field: 'regionName', headerName: '지역', flex: 2 }
    ];

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 교육지원청관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={supporters}
                            columns={columns}
                            pageSize={5}
                            onRowClick={handleRowClick}
                            getRowId={(row) => row.supporterId}
                        />
                    </div>
                </div>
            </div>

            {open && (
                <div className="modal-w active">

                    <div className="modal-inner-type45">
                        <fieldset className="fieldset-type02">
                            <div className="title-type001">
                                <strong className="tit01">교육지원청 관리자 수정</strong>
                            </div>
                            <div className="form-inner">
                                <form className="input-group02">
                                    <div className="title-type002">
                                        <strong className="tit01">교육청ID</strong>
                                    </div>
                                    <div className="input-type01">
                                        <label htmlFor="supportOfficeTitle" className="hide">교육청ID</label>
                                        <input
                                            id="supportOfficeTitle"
                                            type="text"
                                            value={selectedSupporter?.supporterId || ''}
                                            readOnly
                                            className="input-text"
                                        />
                                    </div>
                                </form>
                                <form className="input-group02">
                                    <div className="title-type002">
                                        <strong className="tit01">비밀번호</strong>
                                    </div>
                                    <div className="input-type01">
                                        <label htmlFor="supportOfficeTitle" className="hide">비밀번호</label>
                                        <input
                                            id="supportOfficeTitle"
                                            type="text"
                                            value={selectedSupporter?.supporterPasswd || ''}
                                            onChange={(e) => setSelectedSupporter({ ...selectedSupporter, supporterPasswd: e.target.value })}
                                            className="input-text"
                                        />
                                    </div>
                                </form>
                            </div>
                        </fieldset>
                        <div className="btns-group03">
                            <button onClick={handleClose} className="btn-type005 border">닫기</button>
                            <button onClick={handleDelete} className="btn-type005 border">삭제</button>
                            <button onClick={handleSave} className="btn-type005 blue">저장</button>
                        </div>
                    </div>
                    <i className="dimm"></i>
                </div>
            )}
        </div>
    );
}

export default SupportOfficeManagerList;