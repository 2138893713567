import React, { useState, useEffect } from 'react';
import api from './apiService';
import './css/layout.css';
import './css/module.css';
import './css/reset.css';

function StudentMyInfo() {
    const [studentData, setStudentData] = useState({});
    const [schoolData, setSchoolData] = useState({});

    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    const userGroup = loginInfo ? loginInfo.data.usergroup : '';
    const studentSeq = (userGroup && userGroup === '4') ? JSON.parse(localStorage.getItem('studentSeq')) : '';

    useEffect(() => {
        if (studentSeq) {
            api.get(`/student/${studentSeq}`)
                .then(response => {
                    if (response.status === 200) {
                        setStudentData(response.data);
                        fetchSchoolData(response.data.schoolCode);
                    }
                })
                .catch(error => console.error('Error fetching student data:', error));
        }
    }, [studentSeq]);

    const fetchSchoolData = (schoolCode) => {
        api.get(`/schoolmgr/school/${schoolCode}`)
            .then(response => {
                if (response.status === 200 && response.data.data) {
                    setSchoolData(response.data.data);
                }
            })
            .catch(error => console.error('Error fetching school data:', error));
    };

    return (
        <div className="container page-student-mypage">
            <div className="content-w-form">
                <h2 className="title-type001">
                    <strong className="tit01">내 정보</strong>
                </h2>
                <div className="formBox-confirm">
                    <ul>
                        <li className="title-type002">
                            <strong className="tit01-black">학교명:</strong>
                            <strong className="tit01-blue">{schoolData.schoolName}</strong>
                        </li>
                        <li className="title-type002">
                            <strong className="tit01-black">학년:</strong>
                            <strong className="tit01-blue">{studentData.schoolYear} 학년</strong>
                        </li>
                        <li className="title-type002">
                            <strong className="tit01-black">반:</strong>
                            <strong className="tit01-blue">{studentData.schoolClass} 반</strong>
                        </li>
                        <li className="title-type002">
                            <strong className="tit01-black">번호:</strong>
                            <strong className="tit01-blue">{studentData.studentNumber} 번</strong>
                        </li>
                        <li className="title-type002">
                            <strong className="tit01-black">이름:</strong>
                            <strong className="tit01-blue">{studentData.studentName}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default StudentMyInfo;