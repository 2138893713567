import * as React from "react";

function LogoutTest(props) {
  return (
 <>
      <div className="div">
        <div className="div-2">
          <div className="div-3">
            <div className="div-4">
              <div className="div-5">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b835873a-fecd-4279-a172-619e2a217b98?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b835873a-fecd-4279-a172-619e2a217b98?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b835873a-fecd-4279-a172-619e2a217b98?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b835873a-fecd-4279-a172-619e2a217b98?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b835873a-fecd-4279-a172-619e2a217b98?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b835873a-fecd-4279-a172-619e2a217b98?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b835873a-fecd-4279-a172-619e2a217b98?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b835873a-fecd-4279-a172-619e2a217b98?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                  className="img"
                />
                <div className="div-6">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/393b3662-d0b1-4366-976e-9371cc9a7382?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-2"
                  />
                  <div className="div-7">홈</div>
                </div>
                <div className="div-8">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/03da65f4-0e42-43a7-aa0c-1791ba7a856e?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-3"
                  />
                  <div className="div-9">
                    내정보
                    <br />
                  </div>
                </div>
                <div className="div-10">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f4dd987-a46a-4f0f-be85-70c48a22bdb9?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-4"
                  />
                  <div className="div-11">로그아웃</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-12">
            <div className="div-13">
              <div className="div-14">
                <div className="div-15">
                  <div className="div-16">검사이벤트관리</div>
                  <div className="div-17">검사결과관리</div>
                </div>
                <div className="div-18">
                  <div className="div-19">학교관리</div>
                  <div className="div-20">공지사항관리</div>
                  <div className="div-21">문의게시판관리</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-22">
            <div className="div-23">
              <div className="div-24">검사이벤트조회</div>
            </div>
            <div className="div-25">
              <div className="div-26">검사이벤트생성</div>
            </div>
          </div>
          <div className="div-27">
            <div className="div-28">
              <div className="div-29">
                <div className="div-30">홈</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/43e6957b-fd14-490a-84a8-06b573245b30?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                  className="img-5"
                />
              </div>
              <div className="div-31">검사이벤트관리</div>
              <div className="div-32">검사이벤트생성</div>
            </div>
          </div>
        </div>
        <div className="div-33">
          <div className="div-34">
            <div className="div-35">
              <div className="div-36">검사 이벤트명</div>
            </div>
            <div className="div-37">
              <div className="div-38">2023년 학생장애인식 조사 2차</div>
            </div>
          </div>
          <div className="div-39">
            <div className="div-40">
              <div className="div-41">이벤트 시작일</div>
            </div>
            <div className="div-42">
              <div className="div-43">2023.11.01</div>
            </div>
          </div>
          <div className="div-44">
            <div className="div-45">
              <div className="div-46">이벤트 종료일</div>
            </div>
            <div className="div-47">
              <div className="div-48">2023.12.31</div>
            </div>
          </div>
          <div className="div-49">
            <div className="div-50">
              <div className="div-51">학교급</div>
            </div>
            <div className="div-52">
              <div className="div-53">
                <div className="div-54">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/04b9cdd4-89fa-4020-9883-efd6c74ef777?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-6"
                  />
                  <div className="div-55">초</div>
                </div>
                <div className="div-56">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b0bc663-a1ab-4906-afa3-05da5a04ea58?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b0bc663-a1ab-4906-afa3-05da5a04ea58?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b0bc663-a1ab-4906-afa3-05da5a04ea58?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b0bc663-a1ab-4906-afa3-05da5a04ea58?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b0bc663-a1ab-4906-afa3-05da5a04ea58?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b0bc663-a1ab-4906-afa3-05da5a04ea58?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b0bc663-a1ab-4906-afa3-05da5a04ea58?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b0bc663-a1ab-4906-afa3-05da5a04ea58?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-7"
                  />
                  <div className="div-57">중</div>
                </div>
                <div className="div-58">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b264168a-f922-4971-8e9d-e2fa830c4a9e?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-8"
                  />
                  <div className="div-59">고</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-60">
            <div className="div-61">
              <div className="div-62">검사대상</div>
            </div>
            <div className="div-63">
              <div className="div-64">
                <div className="div-65">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/345fb9ef-83c4-4ff2-88d2-5d25ac4e5b9a?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-9"
                  />
                  <div className="div-66">학생</div>
                </div>
                <div className="div-67">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/de433105-48c1-4a46-aae6-95df1ef547c3?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/de433105-48c1-4a46-aae6-95df1ef547c3?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/de433105-48c1-4a46-aae6-95df1ef547c3?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/de433105-48c1-4a46-aae6-95df1ef547c3?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/de433105-48c1-4a46-aae6-95df1ef547c3?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/de433105-48c1-4a46-aae6-95df1ef547c3?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/de433105-48c1-4a46-aae6-95df1ef547c3?apiKey=2f92c21aa8ad4f1481df6aa98d601084&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/de433105-48c1-4a46-aae6-95df1ef547c3?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-10"
                  />
                  <div className="div-68">교원</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-69">
            <div className="div-70">
              <div className="div-71">검사유형</div>
            </div>
            <div className="div-72">
              <div className="div-73">
                <div className="div-74">B검사지</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/41709ff3-bd54-4673-8439-481a0be49682?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                  className="img-11"
                />
              </div>
            </div>
          </div>
          <div className="div-75">
            <div className="div-76">
              <div className="div-77">저장</div>
            </div>
            <div className="div-78">
              <div className="div-79">취소</div>
            </div>
          </div>
        </div>
        <div className="div-80">
          <div className="div-81">
            <div className="div-82">
              <div className="div-83">
                <div className="column">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/dc2407fe-0b2f-4ca7-b9b6-53a8b8047d68?apiKey=2f92c21aa8ad4f1481df6aa98d601084&"
                    className="img-12"
                  />
                </div>
                <div className="column-2">
                  <div className="div-84">
                    개인정보처리방침 | <br />
                    <br />
                    우:12345 ) 인천광역시 계양구 계산로 62
                    <br />
                    Copyright GYEONGIN NATIONAL UNIVERSITY OF EDUCATION ALL
                    RIGHTS RESERVED
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
}

export default LogoutTest;