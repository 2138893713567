import React, { useState, useEffect } from 'react';
import api from './apiService';

const regionNames = {
    '01': '서울특별시교육청',
    '02': '부산광역시교육청',
    '03': '대구광역시교육청',
    '04': '인천광역시교육청',
    '05': '광주광역시교육청',
    '06': '대전광역시교육청',
    '07': '울산광역시교육청',
    '08': '세종특별자치시교육청',
    '10': '경기도교육청',
    '11': '강원특별자치도교육청',
    '12': '충청북도교육청',
    '13': '충청남도교육청',
    '14': '전북특별자치도교육청',
    '15': '전라남도교육청',
    '16': '경상북도교육청',
    '17': '경상남도교육청',
    '18': '제주특별자치도교육청'
};

function SupportInfoDetail({ userId, onClose }) {
    const [supporterData, setSupporterData] = useState({
        supporterId: '',
        supporterPasswd: '',
        supportOfficeNo: '',
        regionCode: '',
    });
    const [supportOfficeName, setSupportOfficeName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    useEffect(() => {
        const fetchSupporterData = async () => {
            try {
                const response = await api.get(`/api/supporters/${userId}`);
                const data = response.data;
                setSupporterData(data);
                console.log(data);

                const supportOfficeResponse = await api.get(`/api/support-offices/${data.supportOfficeNo}`);
                setSupportOfficeName(supportOfficeResponse.data.supportOfficeTitle);
            } catch (error) {
                console.error('Error fetching supporter data:', error);
            }
        };

        fetchSupporterData();
    }, [userId]);

    const handleSave = async () => {
        if (!newPassword) {
            alert('패스워드를 입력하세요.');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            alert('패스워드가 일치하지 않습니다.');
            return;
        }

        if (!window.confirm('저장하시겠습니까?')) {
            return;
        }

        try {
            const updatedData = {
                ...supporterData,
                supporterPasswd: newPassword,
            };

            const response = await api.put(`/api/supporters/${userId}`, updatedData);
            if (response.status === 200) {
                alert('비밀번호가 성공적으로 변경되었습니다.');
                onClose();
            } else {
                alert('비밀번호 변경에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error updating password:', error);
            alert('비밀번호 변경 중 오류가 발생했습니다.');
        }
    };

    return (
        <div id="modalEdit" className="modal-w active">
            <div className="modal-inner-type45">
                <div className="title-type001">
                    <strong className="tit01">교육지원청 정보</strong>
                </div>
                <fieldset className="fieldset-type02">
                    <div className="input-type01">
                        <label htmlFor="id" className="hide">아이디</label>
                        <input id="id" type="text" value={supporterData.supporterId} readOnly placeholder="아이디(최대 10자리)" />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="pwd2" className="hide">새로운 비밀번호</label>
                        <input id="pwd2" type="password" placeholder="새로운 비밀번호" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="pwd3" className="hide">새로운 비밀번호 확인</label>
                        <input id="pwd3" type="password" placeholder="새로운 비밀번호 확인" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="supportOfficeName" className="hide">교육지원청 이름: </label>
                        <input
                            id="supportOfficeName"
                            type="text"
                            value={supportOfficeName}
                            readOnly
                            className="input-text"
                        />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="regionName" className="hide">지역 이름: </label>
                        <input
                            id="regionName"
                            type="text"
                            value={regionNames[supporterData.regionCode]}
                            readOnly
                            className="input-text"
                        />
                    </div>
                    <div className="btns-group03">
                        <button type="button" className="btn-type005 blue" onClick={handleSave}><span className="txt01">저장</span></button>
                        <button type="button" className="btn-type005 border" onClick={onClose}><span className="txt01">닫기</span></button>
                    </div>
                </fieldset>

                   
            </div>

            <i className="dimm"></i>
        </div>
    );
}

export default SupportInfoDetail;