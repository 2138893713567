import React, { useState, useEffect } from 'react';
import api from './apiService';

function SchoolTeacherDetail({ open, onClose, teacherId, onDeleteSuccess }) {
    const [teacherData, setTeacherData] = useState(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [messageTitle, setMessageTitle] = useState("");
    const [messageContent, setMessageContent] = useState("");

    useEffect(() => {
        if (teacherId) {
            const fetchData = async () => {
                const response = await api.get(`/schoolmgr/teacher/${teacherId}`);
                setTeacherData(response.data.data);
            };
            fetchData();
        }
    }, [teacherId]);

    const handleDelete = () => {
        setDeleteConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        setDeleteConfirmOpen(false);
        try {
            const response = await api.delete(`/schoolmgr/teacher/${teacherId}`);
            if (response.status >= 200 && response.status < 300) {
                setMessageTitle("삭제 성공");
                setMessageContent("삭제되었습니다.");
                onDeleteSuccess(teacherId);
                onClose();
            }
        } catch (error) {
            setMessageTitle("삭제 실패");
            setMessageContent("삭제에 실패했습니다. 검사정보가 있는 경우 삭제되지 않습니다.");
            console.error('Failed to delete:', error);
        } finally {
            setMessageModalOpen(true);
        }
    };

    if (!teacherData) return null;

    const isHomeroomTeacher = teacherData.teacherType === 'C';
    const teacherRole = isHomeroomTeacher ? '담임' : '비담임';

    return (
        <>
            <div id="modalJoin2" className={`modal-w ${open ? 'active' : ''}`} aria-modal="true">
                <div className="modal-inner-type45">
                    <div className="title-type001">
                        <strong className="tit01">교원 정보</strong>
                    </div>
                    <div className="modal-body">
                        <div className="input-type01">
                            <input type="text" value={`교원ID: ${teacherData.teacherId}`} disabled />
                        </div>
                        <div className="input-type01">
                            <input type="text" value={`이름: ${teacherData.teacherName}`} disabled />
                        </div>
                        <div className="input-type01">
                            <input type="text" value={`역할: ${teacherRole}`} disabled />
                        </div>
                        {isHomeroomTeacher && (
                            <>
                                <div className="input-type01">
                                    <input type="text" value={`학년: ${teacherData.schoolYear}학년`} disabled />
                                </div>
                                <div className="input-type01">
                                    <input type="text" value={`반: ${teacherData.schoolClass}반`} disabled />
                                </div>
                            </>
                        )}
                    </div>
                    <fieldset className="fieldset-type02" style={{ marginTop: '20px' }}>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button type="button" className="btn-type005 blue" onClick={handleDelete}>삭제</button>
                            <button type="button" className="btn-type005 border" onClick={onClose}>닫기</button>
                        </div>
                    </fieldset>
                </div>
                <i className="dimm"></i>
            </div>

            {/* 삭제 확인 모달 */}
            {deleteConfirmOpen && (
                <div id="modalJoin2" className="modal-w active" aria-modal="true">
                    <div className="modal-inner-type45">
                        <div className="title-type001">
                            <strong className="tit01">정말로 삭제하시겠습니까?</strong>
                        </div>
                        <fieldset className="fieldset-type02" style={{ marginTop: '20px' }}>
                            <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button type="button" className="btn-type005 border" onClick={() => setDeleteConfirmOpen(false)}>아니오</button>
                                <button type="button" className="btn-type005 blue" onClick={handleConfirmDelete}>예</button>
                            </div>
                        </fieldset>
                    </div>
                    <i className="dimm"></i>
                </div>
            )}

            {/* 메시지 모달 */}
            {messageModalOpen && (
                <div id="modalMessage" className="custom-modal active" aria-modal="true">
                    <div className="modal-inner-type25">
                        <div className="title-type001">
                            <strong className="tit01">{messageTitle}</strong>
                        </div>
                        <div className="title-type003">
                            <strong className="tit01-grey">{messageContent}</strong>
                        </div>
                        <button type="button" className="btn-type005 border close" onClick={() => setMessageModalOpen(false)}>
                            <span className="txt01">닫기</span>
                        </button>
                    </div>
                    <i className="dimm"></i>
                </div>
            )}
        </>
    );
}

export default SchoolTeacherDetail;