// Menu.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Menu() {
    const navigate = useNavigate();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleNavigateToIntro = () => {
        //navigate('/intro'); // /public/main_intro.html
        window.location.href = '/main_intro.html';
        closeMobileMenu();
    };

    const handleNavigateToUsage = () => {
        //navigate('/usage'); // /public/main_usage.html
        window.location.href = '/main_usage.html';
        closeMobileMenu();
    };

    const handleNavigateToShorts = () => {
        window.location.href = '/main_shorts.html';
        closeMobileMenu();
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <div className="headerLnb-w">
            {/* 모바일 메뉴 열기 버튼 */}
            <button type="button" className="btn-type001 menu" onClick={toggleMobileMenu}>
                <span className="ico-type001 menu"><i className="hide">메뉴열기</i></span>
            </button>

            {/* PC 메뉴 */}
            <div className="headerLnb-inner">
                <a href="#none" className="headerLnb-link" onClick={handleNavigateToIntro}>
                    <div className="title-type002">
                        <span className="tit01-white">검사소개</span>
                    </div>
                </a>

                <a href="#none" className="headerLnb-link" onClick={handleNavigateToUsage}>
                    <div className="title-type002">
                        <span className="tit01-white">사용방법</span>
                    </div>
                </a>

                <a href="#none" className="headerLnb-link" onClick={handleNavigateToShorts}>
                    <div className="title-type002">
                        <span className="tit01-white">쇼츠보기</span>
                    </div>
                </a>
            </div>

            {/* 모바일 메뉴 */}
            <div className={`headerMobileMenu-w ${isMobileMenuOpen ? 'current' : ''}`}>
                <div className="headerMobileMenu-inner">
                    <a href="#none" onClick={handleNavigateToIntro}>
                        <div className="title-type002">
                            <span className="tit01">검사소개</span>
                        </div>
                    </a>
                    <a href="#none" onClick={handleNavigateToUsage}>
                        <div className="title-type002">
                            <span className="tit01">사용방법</span>
                        </div>
                    </a>
                    <a href="#none" onClick={handleNavigateToShorts}>
                        <div className="title-type002">
                            <span className="tit01">쇼츠보기</span>
                        </div>
                    </a>
                </div>
                <button type="button" className="btn-type001 close" onClick={closeMobileMenu}>
                    <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                </button>
            </div>
        </div>
    );
}

export default Menu;