import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import SurveyEvent from './SurveyEvent';
import SurveyEventCreate from './SurveyEventCreate'; // SurveyEventCreate 컴포넌트를 import 합니다.
import SurveyResultGradeStudent from './SurveyResultGradeStudent';
import SurveyResultGradeTeacher from './SurveyResultGradeTeacher';
import SurveyResultbySchool from './SurveyResultbySchool';
import SurveyResultbySchoolTeacher from './SurveyResultbySchoolTeacher';
import SchoolAuth from './SchoolAuth';
import SchoolSearch from './SchoolSearch';
import SchoolManagerSearch from './SchoolManagerSearch';
import SchoolTeacherSearch from './SchoolTeacherSearch';
import NoticeList from './NoticeList';
import NoticeCreate from './NoticeCreate';
import MyInfo from './MyInfo';
import OperatorPrivateInfoManage from './OperatorPrivateInfoManage';
import SupportOfficeList from './SupportOfficeList'; // Import the new component
import SupportOfficeManagerList from './SupportOfficeManagerList'; // Import the new component
import SupportOfficeCreate from './SupportOfficeCreate'; // Import the new component
import SupportOfficeManagerCreate from './SupportOfficeManagerCreate'; // Import the new component
import SurveyManage from './SurveyManage'; // Import the new component

function Operator() {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleMouseOver = () => {
      setIsFooterVisible(true);
    };

    const handleMouseOut = () => {
      setIsFooterVisible(false);
    };

    // Footer에 마우스 이벤트 리스너를 추가합니다.
    const footerElement = document.querySelector('.footer');
    footerElement.addEventListener('mouseover', handleMouseOver);
    footerElement.addEventListener('mouseout', handleMouseOut);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리합니다.
    return () => {
      footerElement.removeEventListener('mouseover', handleMouseOver);
      footerElement.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

/*
  useEffect(() => {
    // location.state의 selectedMenu가 없을 때 currentMenu를 확인하여 설정
    if (!location.state?.selectedMenu) {
      if (currentMenu === "내정보") {
        setSelectedMenu(currentMenu);
        console.log("currentMenu:",currentMenu);
      }
    } else {
      setCurrentMenu(location.state.selectedMenu);
      setSelectedMenu(location.state.selectedMenu); // location의 selectedMenu 값 사용
    }
  }, [currentMenu, location, selectedMenu]);
  */
  
  useEffect(() => {
    // URL 쿼리에서 선택된 메뉴를 읽어와 상태를 설정합니다.
    const queryParams = new URLSearchParams(location.search);
    const menuFromURL = queryParams.get('menu');
    if (menuFromURL) {
      setSelectedMenu(menuFromURL);
    } else if (location.state?.selectedMenu) {
      // URL 쿼리가 없다면, location.state에서 선택된 메뉴를 읽어옵니다.
      setSelectedMenu(location.state.selectedMenu);
    } else {
      setSelectedMenu('검사이벤트조회');
    }

  }, [location]);

  useEffect(() => {
    const loginInfo = localStorage.getItem('loginInfo');
    
    // 로그인 정보가 없으면 로그인 페이지로 이동
    if (!loginInfo) {
      navigate('/login');
    }
  }, [selectedMenu]); // selectedMenu와 history를 의존성 배열에 추가합니다.

  
  const changeMenu = (menu) => {
    setSelectedMenu(menu);
    // URL을 업데이트하면서 선택된 메뉴를 쿼리 파라미터로 추가합니다.
    navigate(`/operator?menu=${menu}`, { state: { selectedMenu: menu } });
  };

  return (
    <div style={{ paddingBottom: '60px' }}> {/* Footer의 높이에 따라 조정 */}
      <Header setSelectedMenu={changeMenu} />

      {selectedMenu === "검사이벤트조회" && <SurveyEvent selectedMenu={selectedMenu} />}
      {selectedMenu === "검사이벤트생성" && <SurveyEventCreate selectedMenu={selectedMenu} />}
      {selectedMenu === "검사지관리" && <SurveyManage selectedMenu={selectedMenu} />}
      {selectedMenu === "학교급별결과(학생)" && <SurveyResultGradeStudent selectedMenu={selectedMenu} />}
      {selectedMenu === "학교급별결과(교원)" && <SurveyResultGradeTeacher selectedMenu={selectedMenu} />}
      {selectedMenu === "학교별결과(학생)" && <SurveyResultbySchool selectedMenu={selectedMenu} />}
      {selectedMenu === "학교별결과(교원)" && <SurveyResultbySchoolTeacher selectedMenu={selectedMenu} />}
      {selectedMenu === "학교인증관리" && <SchoolAuth selectedMenu={selectedMenu} />}
      {selectedMenu === "학교정보관리" && <SchoolSearch selectedMenu={selectedMenu} />}
      {selectedMenu === "학교관리자관리" && <SchoolManagerSearch selectedMenu={selectedMenu} />}
      {selectedMenu === "교원관리" && <SchoolTeacherSearch selectedMenu={selectedMenu} />}
      {selectedMenu === "공지사항조회" && <NoticeList selectedMenu={selectedMenu} />}
      {selectedMenu === "공지사항생성" && <NoticeCreate selectedMenu={selectedMenu} />}
      {selectedMenu === "내정보" && <MyInfo selectedMenu={selectedMenu} />}
      {selectedMenu === "개인정보관리" && <OperatorPrivateInfoManage selectedMenu={selectedMenu} />}
      {selectedMenu === "교육지원청조회" && <SupportOfficeList selectedMenu={selectedMenu} />} 
      {selectedMenu === "교육지원청생성" && <SupportOfficeCreate selectedMenu={selectedMenu} />}
      {selectedMenu === "교육지원청관리자조회" && <SupportOfficeManagerList selectedMenu={selectedMenu} />}
      {selectedMenu === "교육지원청관리자생성" && <SupportOfficeManagerCreate selectedMenu={selectedMenu} />}


      {/* 다른 메뉴에 대한 컴포넌트도 이와 비슷한 방식으로 조건부 렌더링 할 수 있습니다. */}

      <div 
        className={`footer ${isFooterVisible ? 'visible' : 'hidden'}`}
        onMouseOver={() => setIsFooterVisible(false)}
        onMouseOut={() => setIsFooterVisible(false)}
      >
        {/*<Footer />*/}
      </div>
    </div>
  );
}

export default Operator;