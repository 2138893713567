// ginue-react-app/src/HomeAll.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal'; // MUI의 Modal 컴포넌트 임포트
import Box from '@mui/material/Box'; // MUI의 Box 컴포넌트 임포트

import { formatDate } from './components/dateUtils'; // formatDate 함수 임포트

import Header from './components/Header';
import Footer from './components/Footer';

import NoticeDetailModal from './NoticeDetailModal';
import NoticeListModal from './NoticeListModal';

import api from './apiService'; // apiService.js에서 api 인스턴스 임포트

import './css/reset.css';
import './css/layout.css';
import './css/module.css';

function HomeAll() {
    const navigate = useNavigate();
    const [announcements, setAnnouncements] = useState([]);
    const [selectedNotice, setSelectedNotice] = useState(null);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isListModalOpen, setIsListModalOpen] = useState(false);
    const [allNotices, setAllNotices] = useState([]);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

    const handleNavigate = (role) => {
        if (role === '학생용') {
            navigate('/studentsignup');
        } else if (role === '교사용') {
            navigate('/login?group=3');
        } else if (role === '교육청용') {
            navigate('/login?group=1');
        }
    };

    useEffect(() => {
        const fetchNotices = async () => {
            try {
                const response = await api.get('/api/notices');
                setAllNotices(response.data);
                setAnnouncements(response.data.slice(0, 3));
            } catch (error) {
                console.error('공지사항을 불러오는데 실패했습니다.', error);
            }
        };

        fetchNotices();
    }, []);

    const handleOpenDetailModal = async (noticeId) => {
        try {
            const response = await api.get(`/api/notices/${noticeId}`);
            setSelectedNotice(response.data);
            setIsDetailModalOpen(true);
        } catch (error) {
            console.error('공지사항 상세 정보를 불러오는데 실패했습니다.', error);
        }
    };

    const handleCloseDetailModal = () => {
        setIsDetailModalOpen(false);
    };

    const handleOpenDetailFromList = async (noticeId) => {
        const notice = allNotices.find(notice => notice.notice_id === noticeId);
        if (notice) {
            setSelectedNotice(notice);
            setIsDetailModalOpen(true);
        }
    };

    const handleOpenListModal = () => {
        setIsListModalOpen(true);
    };

    const handleCloseListModal = () => {
        setIsListModalOpen(false);
    };

    const handleOpenVideoModal = () => {
        setIsVideoModalOpen(true);
    };

    const handleCloseVideoModal = () => {
        setIsVideoModalOpen(false);
    };

    return (
        <div className="wrap">
            <Header />

            <div id="container" className="container page-main">
                <div className="content-w-top">
                    <div className="top-inner">
                        <h2 className="title-type005">
                            <strong className="tit01"> <span style={{ fontSize: '1.5em', color: '#0000ff' }}>우</span>린 <span style={{ fontSize: '1.5em', color: '#0000ff' }}>다</span>르니까 <span style={{ fontSize: '1.5em', color: '#0000ff' }}>다</span>함께 <span style={{ fontSize: '1.5em', color: '#0000ff' }}>다</span>정하게</strong>
                            <i className="logo_top"></i>
                        </h2>
                        <i className="bg_school"></i>
                        <i className="bg_line grey_right"></i>
                        <i className="bg_line grey_left"></i>
                        <i className="bg_line white_left"></i>
                        <i className="bg_line white_right"></i>
                    </div>
                </div>

                <div className="content-w-middle">
                    <div className="title-type001">
                        <strong className="tit01">학교장애인식 검사</strong>
                    </div>
                    <div className="btns-group02">
                        <button onClick={() => handleNavigate('학생용')} className="btn-type003"><span className="ico-student"></span><span className="txt">학생용</span></button>
                        <button onClick={() => handleNavigate('교사용')} className="btn-type003"><span className="ico-teacher"></span><span className="txt">교사용</span></button>
                        <button onClick={() => handleNavigate('교육청용')} className="btn-type003"><span className="ico-office"></span><span className="txt">교육청용</span></button>
                    </div>
                </div>

                <div className="content-w-bottom">
                    <div className="list-wrap01">
                        <div className="title-wrap01">
                            <div className="title-type002"><strong className="tit01">공지사항</strong></div>
                            <button className="title-type003" onClick={handleOpenListModal}>
                                <strong className="tit01-blue">더보기<span className="ico-arrow"></span></strong>
                            </button>
                        </div>
                        <ul className="list-type01">
                            {announcements.map((announcement, index) => (
                                <li key={index}>
                                    <button className="list-text001" onClick={() => handleOpenDetailModal(announcement.notice_id)}>
                                        <span className="tit01">{announcement.title}</span>
                                        <span className="txt01">{formatDate(announcement.createdAt)}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="list-wrap01">
                        <div className="title-wrap01">
                            <div className="title-type002"><strong className="tit01">홍보영상</strong></div>
                        </div>
                        <ul className="list-type01">
                            <li>
                                <button onClick={handleOpenVideoModal} className="list-text001">
                                    <span className="tit01">학교장애인식지수 활용 프로그램 홍보 영상</span>
                                    <span className="txt01">2024. 12. 16.</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {isListModalOpen && (
                <NoticeListModal
                    open={isListModalOpen}
                    onClose={handleCloseListModal}
                    notices={allNotices}
                    onNoticeSelect={handleOpenDetailFromList}
                />
            )}
            {isDetailModalOpen && (
                <NoticeDetailModal
                    open={isDetailModalOpen}
                    onClose={handleCloseDetailModal}
                    notice={selectedNotice}
                />
            )}

            <Modal
                open={isVideoModalOpen}
                onClose={handleCloseVideoModal}
                aria-labelledby="video-modal-title"
                aria-describedby="video-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                    }}
                >
                    <button
                        type="button"
                        className="btn-type001 close no-print"
                        onClick={handleCloseVideoModal}
                        style={{ position: 'absolute', top: '10px', right: '30px' }}
                    >
                        <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                    </button>
                    <iframe
                        width="100%"
                        height="450"
                        src="https://www.youtube.com/embed/T8O8XQKJQlk"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </Box>
            </Modal>

            <Footer />
        </div>
    );
}

export default HomeAll;