import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import TeacherSurveyPage from './TeacherSurveyPage';
import TeacherSurveyResultPage from './TeacherSurveyResultPage';
import TeacherSurveyResultStudent from './TeacherSurveyResultStudent';
import MyInfo from './MyInfo';

function Teacher() {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [schoolCode, setSchoolCode] = useState('');
  const [teacherId, setTeacherId] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // URL 쿼리에서 선택된 메뉴를 읽어와 상태를 설정합니다.
    const queryParams = new URLSearchParams(location.search);
    const menuFromURL = queryParams.get('menu');
    if (menuFromURL) {
      setSelectedMenu(menuFromURL);
    } else if (location.state?.selectedMenu) {
      // URL 쿼리가 없다면, location.state에서 선택된 메뉴를 읽어옵니다.
      setSelectedMenu(location.state.selectedMenu);
    } else {
      setSelectedMenu('검사지조회')
    }

    // Read schoolCode from localStorage and set it in state
    const storedSchoolCode = localStorage.getItem('schoolCode');
    if (storedSchoolCode) {
      setSchoolCode(storedSchoolCode);
    }

    // Read teacherId from localStorage and set it in state
    const storedTeacherId = localStorage.getItem('teacherId');
    if (storedTeacherId) {
      setTeacherId(storedTeacherId);
    }

  }, [location]);

  useEffect(() => {
    const loginInfo = localStorage.getItem('loginInfo');
    
    // 로그인 정보가 없으면 로그인 페이지로 이동
    if (!loginInfo) {
      navigate('/login');
    }
  }, [selectedMenu]); // selectedMenu와 history를 의존성 배열에 추가합니다.

  useEffect(() => {
    // 라우트의 상태를 검사하여 selectedMenu를 설정
    if (location.state?.selectedMenu) {
      console.log(location.state.selectedMenu);
      setSelectedMenu(location.state.selectedMenu);
    }

    // Read schoolCode from localStorage and set it in state
    const storedSchoolCode = localStorage.getItem('schoolCode');
    if (storedSchoolCode) {
      setSchoolCode(storedSchoolCode);
    }

    // Read teacherId from localStorage and set it in state
    const storedTeacherId = localStorage.getItem('teacherId');
    if (storedTeacherId) {
      setTeacherId(storedTeacherId);
    }
  }, []);
 

  const changeMenu = (menu) => {
    setSelectedMenu(menu);
    // URL을 업데이트하면서 선택된 메뉴를 쿼리 파라미터로 추가합니다.
    navigate(`/teacher?menu=${menu}`, { state: { selectedMenu: menu } });
  };

  return (
    <div>
      <Header setSelectedMenu={changeMenu} />

      {selectedMenu === "검사지조회" && <TeacherSurveyPage schoolCode={schoolCode} teacherId={teacherId}/>}
      {selectedMenu === "본인" && <TeacherSurveyResultPage schoolCode={schoolCode} teacherId={teacherId}/>}
      {selectedMenu === "학생" && <TeacherSurveyResultStudent schoolCode={schoolCode}  teacherId={teacherId}/>}
      {selectedMenu === "내정보" && <MyInfo />}
      {/* 다른 메뉴에 대한 컴포넌트도 이와 비슷한 방식으로 조건부 렌더링 할 수 있습니다. */}

      {<Footer />}
    </div>
  );
}

export default Teacher;
