import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import api from './apiService';
import { Box } from '@mui/material';
import CustomToolbar from './components/CustomToolbar'; // CustomToolbar를 임포트합니다

function SurveyResultGradeTeacher({ selectedMenu }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const [selectedEventId, setSelectedEventId] = useState(null);  // 추가
    const [openDialog, setOpenDialog] = useState(false);  // 추가

    // DataGrid의 컬럼 정의
    const columns = [
        { field: 'eventTitle', headerName: '검사이벤트', flex: 1.5 },
        { field: 'eventId', headerName: '이벤트ID', flex: 1 },
        { field: 'schoolGrade', headerName: '학교급', flex: 1 },
        { field: 'totalResponses', headerName: '총 응답 수', flex: 1 },
        { field: 'acesCount', headerName: 'ACES 수', flex: 1 },
        { field: 'middCount', headerName: 'MIDD 수', flex: 1 },
        { field: 'raseCount', headerName: 'RASE 수', flex: 1 },
        // 여기에 더 많은 컬럼을 추가할 수 있습니다.
    ];

    const fetchData = async () => {
        api.get("/surveyresult/grade_teacher")
            .then(response => {
                //console.log(response.data);
                const dataWithIds = response.data.data.map(item => ({ ...item, id: item.eventId }));
                //console.log(dataWithIds);
                setData(dataWithIds);

                /*   // Generate 200 test data entries for testing
               const testData = Array.from({ length: 200 }, (_, index) => ({
                   id: `ID${index + 1}`,
                   eventTitle: `Event ${index + 1}`,
                   eventId: `EID${index + 1}`,
                   schoolLevel: ['초', '중', '고'][index % 3],
                   totalResponses: Math.floor(Math.random() * 100),
                   acesCount: Math.floor(Math.random() * 50),
                   middCount: Math.floor(Math.random() * 50),
                   raseCount: Math.floor(Math.random() * 50),
               }));
   
               // Combine API data with test data
               setData([...dataWithIds, ...testData]);
               */
                setIsLoading(false);
            })
            .catch(error => {
                console.error("API fetch error:", error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    // Mui DataGrid에 필요한 rows와 columns을 설정합니다.
    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 검사결과관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>
                <Box sx={{ width: '100%', height: data && data.length > 0 ? 'calc(100vh - 300px)' : 'auto' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 20]}
                        onPageChange={handlePageChange}
                        getRowId={(row) => row.id}
                        slots={{
                            toolbar: () => <CustomToolbar fileName="학교급별결과(교원)" />,
                            noRowsOverlay: () => (
                                <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                    <strong className="tit01">조회된 데이터가 없습니다.</strong>
                                </div>
                            )
                        }}
                        loading={isLoading}
                    />

                </Box>
            </div>
        </div>
    );
}

export default SurveyResultGradeTeacher;
