import React, { forwardRef, useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import TeacherLinks from './TeacherLinks';
import api from './apiService';
import './css/reset.css';
import './css/layout.css';
import './css/module.css';
import './StudentSurveyResultDetail.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Jua',
            'Open Sans',
            'Lato',
            'sans-serif',
        ].join(','),
    },
});

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const scopeColors = {
    scope1: '#FFC0CB', // 핑크색
    scope2: '#87CEEB', // 파란색
    scope3: '#FFE4A1', // 노란색
    scope4: '#7FFFD4'  // 민트색
};

const scopeLabels = {
    scope1: '실천영역(Action)',
    scope2: '관점영역(View)',
    scope3: '정서영역(Emotion)',
    scope4: '지식영역(Knowledge)'
};

const scopeDescriptions = {
    scope1: '장애 관련 이슈를 어떻게 해결할 수 있는가?',
    scope2: '장애를 어떻게 보는가?',
    scope3: '장애에 대해 어떤 느낌, 기분, 감정이 드는가?',
    scope4: '장애에 대해 얼마나 잘 알고 있는가?'
};


// Assume this is a simulated response from the API
const areaDescriptions = {
    E: {
        scope1: {
            ACES: 'A-ACES는 장애인과의 직접적인 상호 작용에 대해 거부감이나 두려움이 없으며 적극적으로 소통하는 방법을 알아내려고 노력하는 경우입니다. 장애 차별과 편견 문제에 대해 학생들에게 직접 설명할 수 있으며 장애학생에 대한 차별을 줄이기 위해 학교 시스템을 개선하는 데 적극적으로 노력하는 경우도 많습니다. 장애인에 대한 차별을 줄이기 위해 개인 수준에서 할 수 있는 일들을 실천하려고 노력하는 사람들입니다. ',
            MIDD: 'A-MIDD는 장애인과의 직접적인 상호 작용이나 관계 형성을 거부하거나 부정적인 태도로 대하지는 않지만, 적극적으로 기회를 만드는 편이 아닌 경우입니다. 장애인의 어려움은 어느 정도 이해하고 있으며 여건이 허락되면 필요한 일을 실천하려는 생각은 있습니다. 장애인에 대한 차별을 줄이기 위해 일상에서 실천할 수 있는 일에 조금씩만 더 관심을 기울이기를 바랍니다. ',
            RASE: 'A-RASE는 장애인 차별과 편견 문제에 대해 무관심하며, 장애인과의 직접적인 상호 작용 기회를 거부하거나 부정적으로 대하는 경향을 나타냅니다. 장애인은 다양한 영역에서 일상적인 차별에 노출되고 있습니다. 많은 사람들이 장애인 차별에 대해 관심을 가질 때 이런 문제를 조금씩 해결할 수 있습니다.'
        },
        scope2: {
            ACES: 'V-ACES는 장애를 사회적 제약과 차별의 문제로 인식하고 장애인을 비장애인과 동등한 권리를 가진 사회 구성원으로 보는 관점입니다.  이런 관점을 가지는 사람들은 대체로 장애인의 권리를 보장하기 위해서는 적극적 조치(Affirmative action)가 필요하다고 인식합니다.',
            MIDD: 'V-MIDD는 장애는 질병처럼 치료되는 것은 아니고 개인의 특성일 수 있지만 교육, 이동, 노동 등에서 장애인이 부딪히는 장벽은 장애로 인한 신체적·정신적 손상으로 인한 것으로 어쩔 수 없다고 보는 관점입니다. 장애인도 비장애인과 동등한 권리를 누려야 하지만 사회가 여건이 될 때 조금씩 개선해야 한다고 생각합니다. 좀 더 세심하게 관찰하면 장애는 사회의 구조적인 장벽과 관련이 높다는 것을 알 수 있습니다. 장애를 보는 다양한 관점을 접해 보기를 권합니다.',
            RASE: 'V-RASE는 장애를 개인의 손상과 결함으로 인한 비극으로 보며, 장애인을 나와는 다른 집단에 속한 사람으로 구분하는 관점입니다. 장애를 질병과 같이 의료적인 관점으로 보는 경향이 강하며 장애인이 겪는 사회적 장벽은 신체적·정신적 손상으로 인한 당연한 것으로 생각합니다. 아프고 불쌍한 사람을 도와주듯 장애인을 도움이 필요한 사람으로 봅니다. 그러나 세심하게 관찰하면 장애는 사회의 구조적인 장벽과 관련이 높다는 것을 알 수 있습니다. 장애를 보는 다양한 관점을 접해 보기를 권합니다.'
        },
        scope4: {
            ACES: 'K-ACES는 장애의 특성과 적절한 지원 방법을 잘 알고 있으며 장애인의 기본권 및 기본권 보장을 위한 법과 제도의 역할 및 실제 내용에 대해서도 풍부한 지식을 갖고 있습니다. 대체로 장애를 지원의 유무 및 강도에 따른 상대적인 속성으로 이해하고 있으며 장애 관련법이나 시스템에 대한 지식도 풍부합니다. 현재는 장애에 대한 지식 수준이 높지만 장애에 대한 지식은 계속 변화하니 꾸준히 지식을 발전시키길 바랍니다.',
            MIDD: 'K-MIDD는 대체로 장애 유형, 정도, 특성 등 장애와 관련된 용어를 알고 있고 장애인의 기본권 및 장애 관련 제도에 대해서도 알고 있는 경우입니다. 그러나 구체적인 상황에서 장애학생을 지원하기 위해서는 좀 더 세부적인 지식이 필요합니다. 장애인의 권리를 보장하기 위한 정책·제도도 계속 만들어지고 있으며 연구 결과도 축적되고 있습니다. 장애에 대한 풍부한 지식은 장애학생을 지원하는 것은 물론이고 통합학급 운영에도 도움이 됩니다.',
            RASE: 'K-RASE는 장애의 특성에 대해서 잘 알지 못하고 장애인을 지원하는 방법에 대해서도 충분히 숙지하지 못한 경우입니다. 장애인의 기본권 및 제도에 대해서도 피상적 수준에서 이해하고 있는 경우가 많습니다. 장애인을 지원하기 위해서는 장애에 대한 풍부한 지식과 장애인의 권리를 보장하기 위한 제도나 정책에 대한 이해도 중요합니다. 장애에 대한 정확하고 풍부한 지식은 장애학생을 지원하는 것은 물론이고 통합학급 운영에도 도움이 됩니다.'
        }
    },
    M: {
        scope1: {
            ACES: 'A-ACES는 장애인과의 직접적인 상호 작용에 대해 거부감이나 두려움이 없으며 적극적으로 소통하는 방법을 알아내려고 노력하는 경우입니다. 장애 차별과 편견 문제에 대해 학생들에게 직접 설명할 수 있으며 장애학생에 대한 차별을 줄이기 위해 학교 시스템을 개선하는 데 적극적으로 노력하는 경우도 많습니다. 장애인에 대한 차별을 줄이기 위해 개인 수준에서 할 수 있는 일들을 실천하려고 노력하는 사람들입니다. ',
            MIDD: 'A-MIDD는 장애인과의 직접적인 상호 작용이나 관계 형성을 거부하거나 부정적인 태도로 대하지는 않지만, 적극적으로 기회를 만드는 편이 아닌 경우입니다. 장애인의 어려움은 어느 정도 이해하고 있으며 여건이 허락되면 필요한 일을 실천하려는 생각은 있습니다. 장애인에 대한 차별을 줄이기 위해 일상에서 실천할 수 있는 일에 조금씩만 더 관심을 기울이기를 바랍니다. ',
            RASE: 'A-RASE는 장애인 차별과 편견 문제에 대해 무관심하며, 장애인과의 직접적인 상호 작용 기회를 거부하거나 부정적으로 대하는 경향을 나타냅니다. 장애인은 다양한 영역에서 일상적인 차별에 노출되고 있습니다. 많은 사람들이 장애인 차별에 대해 관심을 가질 때 이런 문제를 조금씩 해결할 수 있습니다.'
        },
        scope2: {
            ACES: 'V-ACES는 장애를 사회적 제약과 차별의 문제로 인식하고 장애인을 비장애인과 동등한 권리를 가진 사회 구성원으로 보는 관점입니다.  이런 관점을 가지는 사람들은 대체로 장애인의 권리를 ��장하기 위해서는 적극적 조치(Affirmative action)가 필요하다고 인식합니다.',
            MIDD: 'V-MIDD는 장애는 질병처럼 치료되는 것은 아니고 개인의 특성일 수 있지만 교육, 이동, 노동 등에서 장애인이 부딪히는 장벽은 장애로 인한 신체적·정신적 손상으로 인한 것으로 어쩔 수 없다고 보는 관점입니다. 장애인도 비장애인과 동등한 권리를 누려야 하지만 사회가 여건이 될 때 조금씩 개선해야 한다고 생각합니다. 좀 더 세심하게 관찰하면 장애는 사회의 구조적인 장벽과 관련이 높다는 것을 알 수 있습니다. 장애를 보는 다양한 관점을 접해 보기를 권합니다.',
            RASE: 'V-RASE는 장애를 개인의 손상과 결함으로 인한 비극으로 보며, 장애인을 나와는 다른 집단에 속한 사람으로 구분하는 관점입니다. 장애를 질병과 같이 의료적인 관점으로 보는 경향이 강하며 장애인이 겪는 사회적 장벽은 신체적·정신적 손상으로 인한 당연한 것으로 생각합니다. 아프고 불쌍한 사람을 도와주듯 장애인을 도움이 필요한 사람으로 봅니다. 그러나 세심하게 관찰하면 장애는 사회의 구조적인 장벽과 관련이 높다는 것을 알 수 있습니다. ��애를 보는 다양한 관점을 접해 보기를 권합니다.'
        },
        scope4: {
            ACES: 'K-ACES는 장애의 특성과 적절한 지원 방법을 잘 알고 있으며 장애인의 기본권 및 기본권 보장을 위한 법과 제도의 역할 및 실제 내용에 대해서도 풍부한 지식을 갖고 있습니다. 대체로 장애를 지원의 유무 및 강도에 따른 상대적인 속성으로 이해하고 있으며 장애 관련법이나 시스템에 대한 지식도 풍부합니다. 현재는 장애에 대한 지식 수준이 높지만 장애에 대한 지식은 계속 변화하니 꾸준히 지식을 발전시키길 바랍니다.',
            MIDD: 'K-MIDD는 대체로 장애 유형, 정도, 특성 등 장애와 관련된 용어를 알고 있고 장애인의 기본권 및 장애 관련 제도에 대해서도 알고 있는 경우입니다. 그러나 구체적인 상황에서 장애학생을 지원하기 위해서는 좀 더 세부적인 지식이 필요합니다. 장애인의 권리를 보장하기 위한 정책·제도도 계속 만들어지고 있으며 연구 결과도 축적되고 있습니다. 장애에 대한 풍부한 지식은 장애학생을 지원하는 것은 물론이고 통합학급 운영에도 도움이 됩니다.',
            RASE: 'K-RASE는 장애의 특성에 대해서 잘 알지 못하��� 장애인을 지원하는 방법에 대해서도 충분히 숙지하지 못한 경우입니다. 장애인의 기본권 및 제도에 대해서도 피상적 수준에서 이해하고 있는 경우가 많습니다. 장애인을 지원하기 위해서는 장애에 대한 풍부한 지식과 장애인의 권리를 보장하기 위한 제도나 정책에 대한 이해도 중요합니다. 장애에 대한 정확하고 풍부한 지식은 장애학생을 지원하는 것은 물론이고 통합학급 운영에도 도움이 됩니다.'
        }
    },
    H: {
        scope1: {
            ACES: 'A-ACES는 장애인과의 직접적인 상호 작용에 대해 거부감이나 두려움이 없으며 적극적으로 소통하는 방법을 알아내려고 노력하는 경우입니다. 장애 차별과 편견 문제에 대해 학생들에게 직접 설명할 수 있으며 장애학생에 대한 차별을 줄이기 위해 학교 시스템을 개선하는 데 적극적으로 노력하는 경우도 많습니다. 장애인에 대한 차별을 줄이기 위해 개인 수준에서 할 수 있는 일들을 실천하려고 노력하는 사람들입니다. ',
            MIDD: 'A-MIDD는 장애인과의 직접적인 상호 작용이나 관계 형성을 거부하거나 부정적인 태도로 대하지는 않지만, 적극적으로 기회를 만드는 편이 아��� 경우입니다. 장애인의 어려움은 어느 정도 이해하고 있으며 여건이 허락되면 필요한 일을 실천하려는 생각은 있습니다. 장애인에 대한 차별을 줄이기 위해 일상에서 실천할 수 있는 일에 조금씩만 더 관심을 기울이기를 바랍니다. ',
            RASE: 'A-RASE는 장애인 차별과 편견 문제에 대해 무관심하며, 장애인과의 직접적인 상호 작용 기회를 거부하거나 부정적으로 대하는 경향을 나타냅니다. 장애인은 다양한 영역에서 일상적인 차별에 노출되고 있습니다. 많은 사람들이 장애인 차별에 대해 관심을 가질 때 이런 문제를 조금씩 해결할 수 있습니다.'
        },
        scope2: {
            ACES: 'V-ACES는 장애를 사회적 제약과 차별의 문제로 인식하고 장애인을 비장애인과 동등한 권리를 가진 사회 구성원으로 보는 관점입니다.  이런 관점을 가지는 사람들은 대체로 장애인의 권리를 보장하기 위해서는 적극적 조치(Affirmative action)가 필요하다고 인식합니다.',
            MIDD: 'V-MIDD는 장애는 질병처럼 치료되는 것은 아니고 개인의 특성일 수 있지만 교육, 이동, 노동 등에서 장애인이 부딪히는 장벽은 장애로 인한 신체적·정신적 손상으로 인한 것으로 어쩔 수 없다고 보는 관점입니다. 장애인도 비장애인과 동등한 권리를 누려야 하지만 사회가 여건이 될 때 조금씩 개선해야 한다고 생각합니다. 좀 더 세심하게 관찰하면 장애는 사회의 구조적인 장벽과 관련이 높다는 것을 알 수 있습니다. 장애를 보는 다양한 관점을 접해 보기를 권합니다.',
            RASE: 'V-RASE는 장애를 개인의 손상과 결함으로 인한 비극으로 보며, 장애인을 나와는 다른 집단에 속한 사람으로 구분하는 관점입니다. 장애를 질병과 같이 의료적인 관점으로 보는 경향이 강하며 장애인이 겪는 사회적 장벽은 신체적·정신적 손상으로 인한 당연한 것으로 생각합니다. 아프고 불쌍한 사람을 도와주듯 장애인을 도움이 필요한 사람으로 봅니다. 그러나 세심하게 관찰하면 장애는 사회의 구조적인 장벽과 관련이 높다는 것을 알 수 있습니다. 장애를 보는 다양한 관점을 접해 보기를 권합니다.'
        },
        scope4: {
            ACES: 'K-ACES는 장애의 특성과 적절한 지원 방법을 잘 알고 있으며 장애인의 기본권 및 기본권 보장을 위한 법과 제도의 역할 및 실제 내용에 대해서도 풍부한 지식을 갖고 있습니다. 대체로 장애를 지원의 유무 및 강도에 따른 상대적인 속성으로 이해하고 있으며 장애 관련법이나 시스템에 대한 지식도 풍부합니다. 현재는 장애에 대한 지식 수준이 높지만 장애에 대한 지식은 계속 변화하니 꾸준히 지식을 발전시키길 바랍니다.',
            MIDD: 'K-MIDD는 대체로 장애 유형, 정도, 특성 등 장애와 관련된 용어를 알고 있고 장애인의 기본권 및 장애 관련 제도에 대해서도 알고 있는 경우입니다. 그러나 구체적인 상황에서 장애학생을 지원하기 위해서는 좀 더 세부적인 지식이 필요합니다. 장애인의 권리를 보장하기 위한 정책·제도도 계속 만들어지고 있으며 연구 결과도 축적되고 있습니다. 장애에 대한 풍부한 지식은 장애학생을 지원하는 것은 물론이고 통합학급 운영에도 도움이 됩니다.',
            RASE: 'K-RASE는 장애의 특성에 대해서 잘 알지 못하고 장애인을 지원하는 방법에 대해서도 충분히 숙지하지 못한 경우입니다. 장애인의 기본권 및 제도에 대해서도 피상적 수준에서 이해하고 있는 경우가 많습니다. 장애인을 지원하기 위해서는 장애에 대한 풍부한 지식과 장애인의 권리를 보장하기 위��� 제도나 정책에 대한 이해도 중요합니다. 장애에 대한 정확하고 풍부한 지식은 장애학생을 지원하는 것은 물론이고 통합학급 운영에도 도움이 됩니다.'
        }
    }
};

const TeacherSurveyResultDetail = forwardRef(({ eventId, schoolCode, teacherId, onPrint, onClose }, ref) => {
    const [teacherData, setTeacherData] = useState(null);
    const [eventDetails, setEventDetails] = useState(null);
    const [responseDetails, setResponseDetails] = useState(null);
    const [chartData, setChartData] = useState({});
    const [schoolName, setSchoolName] = useState(null);
    const [schoolData, setSchoolData] = useState(null);

    // Fetch event details
    useEffect(() => {
        const fetchSchoolCode = async () => {
            try {
                const response = await api.get(`/schoolmgr/school/${schoolCode}`);
                if (response.status === 200 && response.data) {
                    const { data } = response.data;
                    setSchoolData(data);
                    setSchoolName(data.schoolName);
                }
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchSchoolCode();
    }, [schoolCode]);

    // Fetch event details
    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await api.get(`/surveyevent/${eventId}`);
                if (response.status === 200 && response.data) {
                    const { data } = response.data;
                    setEventDetails(data);
                }
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchEventDetails();
    }, [eventId]);

    useEffect(() => {
        // Load student data
        const fetchTeacherData = async () => {
            try {
                const response = await api.get(`/schoolmgr/teacher/${teacherId}`);
                if (response.status === 200) {
                    console.log(response.data);
                    setTeacherData(response.data.data); // 학생과 다르게 data 키로 가져와야 한다.
                } else {
                    setTeacherData(null);
                }
            } catch (error) {
                console.error('Error fetching teacher data:', error);
                setTeacherData(null);
            }
        };

        // Load survey response data
        const fetchResponseDetails = async () => {
            try {
                const response = await api.get(`/api/teacher-response?eventId=${eventId}&schoolCode=${schoolCode}&teacherId=${teacherId}`);
                if (response.status === 200) {
                    const { data } = response;
                    const { response: resString } = data;
                    // Assuming the response data has the same structure as the provided JSON
                    setResponseDetails(data);

                    if (resString) {
                        const responseNumbers = resString.split('').map(Number);
                        // 각 막대에 대한 무작위 색상 배열 생성
                        const backgroundColors = responseNumbers.map(() => getRandomColor());
                        // 차트 데이터 설정
                        const data = {
                            labels: responseNumbers.map((_, index) => index + 1), // X축 레이블을 1부터 36까지 설정
                            datasets: [{
                                label: 'Score per Item',
                                data: responseNumbers,
                                backgroundColor: backgroundColors,
                                borderColor: backgroundColors.map(color => color),
                                borderWidth: 1,
                            }],
                        };
                        setChartData(data);
                    }
                } else {
                    setChartData(null);
                    setResponseDetails(null);
                }
            } catch (error) {
                console.error('Error fetching response data:', error);
                setResponseDetails(null);
            }
        };

        fetchTeacherData();
        fetchResponseDetails();
    }, [eventId, schoolCode, teacherId]);


    // Function to format dates
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString();
    };

    // Function to render area descriptions
    const renderAreaDescription = (level) => {
        return areaDescriptions[level] || 'No description available.';
    };

    // If data is not yet loaded, show loading message
    if (!teacherData || !eventDetails || !responseDetails || !chartData) {
        return <div>Loading...</div>;
    }

    const areaChartData = {
        series: [
            {
                data: [
                    responseDetails.scope1_score,
                    responseDetails.scope2_score,
                    responseDetails.scope4_score
                ],

                backgroundColor: ['#FF6384', '#36A2EB', '#4BC0C0'], // Different colors for each bar
            }
        ],
    };

    const xAxisData = ['실천영역', '관점영역', '지식영역'];

    // Close button handler (modify as per your requirement)
    const handleClose = () => {
        if (onClose) {
            onClose(); // If an onClose function is passed, call it
        } else {
            console.log('Close button clicked'); // Replace this with actual functionality
        }
    };

    const handlePrint = () => {
        console.log("handlePrint");
        onPrint();
    }

    // Render the component
    return (
        <div ref={ref} className="wrap">
            <div className="container page-result">
                <div className="content-w-result">
                    <button type="button" className="btn-type001 close no-print" onClick={onClose} style={{ position: 'absolute', top: '10px', right: '30px' }}>
                        <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                    </button>
                    <div className="resultInner-top">
                        <button type="button" className="btn-type004 tab print no-print" onClick={handlePrint}>
                            <span className="ico-type002 print"><i className="hide">프린트하기</i></span>
                            <span className="txt01">Print</span>
                        </button>

                        <h2><span className="logo-result"><i className="hide">학교장애인식검사 결과지</i></span></h2>
                        <strong className="result-user">
                            {schoolData.schoolName} {teacherData.teacherName}
                        </strong>
                        {eventDetails && (
                            <strong className="result-text">
                                학교장애인식검사는 장애 또는 장애인에 대한 인식 수준을 알아보기 위한 검사입니다.
                                장애 또는 장애인에 대한 인식을 알아야 하는 이유는, ‘장애’는 대부분의 사회에서 개인이 가질 수 있는 가장 큰 차이인데
                                이러한 차이에 대해 얼마나 잘 수용하고 존중하는지가 그 사회 구성원들의 시민 의식을 잘 나타내기 때문입니다.
                            </strong>
                        )}
                        {/* 검사지 구인 설명 */}
                        {teacherData && (
                            <div className="box-yellow">
                                <strong className="result-text">
                                    장애인식은 관점, 지식, 실천이라는 3가지 영역으로 구성되어 있으며, 각각의 영역에서 ACES, MIDD, RASE로 장애인식의 특성이 제시되어 있습니다.
                                </strong>
                            </div>
                        )}
                        <div className="result-chart">
                            {/* <h3 className="result-title">학교장애인식검사 해석</h3> */}
                            <div className="chartLabel-w-total">
                                <strong className="result-title">종합 점수</strong>
                                <div className="ico-label-blue">
                                    <span className="txt-yellow">{responseDetails.level}</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="chart-container" style={{ width: '40%', height: '250px' }}>
                                    <BarChart
                                        series={areaChartData.series}
                                        xAxis={[{ data: xAxisData, scaleType: 'band' }]}
                                        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                                    />
                                </div>
                            </div>
                            <div className="chart-inner">
                                {['scope1', 'scope2', 'scope4'].map((scope, index) => (
                                    <div key={index} className="chartLabel-w">
                                        <strong className="result-title" style={{ color: scopeColors[scope] }}>{scopeLabels[scope]}</strong>
                                        <div className="ico-label-white">
                                            <span className={`txt-${scope}`} style={{ color: scopeColors[scope] }}>{responseDetails[`${scope}_level`]}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="resultInner-middle">
                        <div className="result-table">
                            <div className="resultTable-head">
                                <div className="rth-inner">영역</div>
                                <div className="rth-inner">수준</div>
                                <div className="rth-inner">해석</div>
                            </div>
                            <div className="resultTable-body">
                                {['scope1', 'scope2', 'scope4'].map((scope, index) => (
                                    <div key={index} className={`rtb-w-${scope}`} style={{ backgroundColor: scopeColors[scope] }}>
                                        <div className="rtb-inner">
                                            <div className="resultText-w">
                                                <strong className="result-text">{scopeLabels[scope]}</strong>
                                                <strong className="result-text-white">{scopeDescriptions[scope]}</strong>
                                            </div>
                                            <div className="ico-label-white">
                                                <span className={`txt-${scope}`}>{responseDetails[`${scope}_level`]}</span>
                                            </div>
                                        </div>
                                        <div className="rtb-inner">
                                            <span className="result-text">
                                                {areaDescriptions[schoolData.schoolGrade][scope][responseDetails[`${scope}_level`]]}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <TeacherLinks schoolGrade={schoolData.schoolGrade} />
                    {/*                    <div className="result-footer">
                        <button className="btn-type004 tab no-print" onClick={handleClose}>Close</button>
                    </div>
                    */}
                </div>
            </div>
        </div>
    );
});

export default TeacherSurveyResultDetail;
