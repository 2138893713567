import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ManagerAuthCheck from './ManagerAuthCheck';
import ManagerSchoolRegister from './ManagerSchoolRegister';


function ManagerAuth() {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const location = useLocation();


  useEffect(() => {
    // 라우트의 상태를 검사하여 selectedMenu를 설정
    const menu = location.state?.selectedMenu || "학교관리자-등록인증";
    setSelectedMenu(menu);
    console.log(menu);
  }, [location]);

  return (
    <div>
      <Header setSelectedMenu={setSelectedMenu} />
      {selectedMenu === "학교관리자-등록인증" && <ManagerAuthCheck />}   
      {selectedMenu === "학교관리자-학교등록" && <ManagerSchoolRegister />}   
      {<Footer />}
    </div>
  );
}

export default ManagerAuth;