import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';
import CustomToolbar from './components/CustomToolbar'; // CustomToolbar를 임포트합니다

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '80vw',
    height: '80vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

function SurveyResultbySchoolDetail({ open, onClose, schoolCode, eventId, schoolName }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (schoolCode && eventId) {
      const fetchData = async () => {
        try {
          const response = await api.get(`/surveyresult/bySchool/detailed?schoolCode=${schoolCode}&eventId=${eventId}`);
          setData(response.data.data);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching detailed survey results:", error);
        }
      };
      fetchData();
    }
  }, [schoolCode, eventId]);

  // Return null if no data is available
  if (!data || data.length === 0) return null;

  const columns = [
    { field: 'schoolYear', headerName: '학년', flex: 1 },
    { field: 'schoolClass', headerName: '반', flex: 1 },
    { field: 'totalResponses', headerName: '총 응답 수', flex: 1 },
    { field: 'totalAces', headerName: 'ACES 수', flex: 1 },
    { field: 'totalMidd', headerName: 'MIDD 수', flex: 1 },
    { field: 'totalRase', headerName: 'RASE 수', flex: 1 },
  ];

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>{`학교별결과(학생) 상세보기 / ${schoolName}`}</DialogTitle>
      <DialogContent style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        overflow: 'auto',
        maxHeight: '70vh'
      }}>
        
          <DataGrid slots={{ toolbar: () => <CustomToolbar fileName={`학교별결과상세(학생)_${schoolName}`} /> }}
            rows={data}
            columns={columns}
            getRowId={(row) => `${row.schoolYear}-${row.schoolClass}`} // Unique ID for each row
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableRowSelectionOnClick
            style={{ height: '60vh' }}  // 스크롤이 생기도록 스타일 추가
            loading={isLoading}
          />
        
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
          <Button onClick={onClose}>Close</Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
}

export default SurveyResultbySchoolDetail;
