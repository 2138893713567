import React, { forwardRef, useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import StudentLinks from './StudentLinks';
import api from './apiService';
import './css/reset.css';
import './css/layout.css';
import './css/module.css';
import './StudentSurveyResultDetail.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Jua',
            'Open Sans',
            'Lato',
            'sans-serif',
        ].join(','),
    },
});

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const scopeColors = {
    scope1: '#FFC0CB', // 핑크색
    scope2: '#87CEEB', // 파란색
    scope3: '#FFE4A1', // 노란색
    scope4: '#7FFFD4'  // 민트색
};

const scopeLabels = {
    scope1: '실천영역(Action)',
    scope2: '관점영역(View)',
    scope3: '정서영역(Emotion)',
    scope4: '지식영역(Knowledge)'
};

const scopeDescriptions = {
    scope1: '장애 관련 이슈를 어떻게 해결할 수 있는가?',
    scope2: '장애를 어떻게 보는가?',
    scope3: '장애에 대해 어떤 느낌, 기분, 감정이 드는가?',
    scope4: '장애에 대해 얼마나 잘 알고 있는가?'
};

// Assume this is a simulated response from the API
const areaDescriptions = {
    E: {
        scope1: {
            ACES: 'A-ACES는 장애인과 관계를 맺고 대화하는 것에 대해 거부감이 없고 장애인 차별과 편견 문제를 해결하는 데 관심이 많습니다. 장애학생을 놀리거나 괴롭히는 아이들을 말리고, 수업 시간이나 교내외 체험 활동에서 장애학생이 도움이 필요할 때 적절하게 도와주려고 합니다. 장애인에 대한 차별과 편견은 각자가 할 수 있는 일을 모두 함께 조금씩이라도 실천할 때 줄어들 수 있음을 잘 아는 사람들입니다.',
            MIDD: 'A-MIDD는 장애인과 대화하고 관계를 맺는 것을 거부하거나 부정적인 태도로 대하지는 않지만, 적극적으로 장애인과 소통하는 기회를 만들지는 않습니다. 장애인의 어려움을 알고 있지만 장애인에 대한 사회적 장벽이나 차별을 줄이는 데 필요한 일을 실천하는 데는 소극적입니다. 장애인에 대한 차별을 줄이기 위해 일상에서 실천할 수 있는 일에 조금씩만 더 관심을 기울이기를 바랍니다. ',
            RASE: 'A-RASE는 장애인 차별과 편견 문제에 대해 관심이 없고, 장애인과의 어울리고 대화하는 것을 피하는 경향이 있습니다. 때로는 장애인을 놀리는 등  부적절한 행동을 할 때도 있습니다. 이런 행동은 다른 사람에게 큰 상처를 준다는 것을 기억해야 합니다. 장애인은 다양한 영역에서 일상적인 차별에 노출되고 있습니다. 많은 사람들이 장애인 차별에 대해 관심을 가질 때 이런 문제를 조금씩 해결할 수 있습니다.'
        },
        scope2: {
            ACES: 'V-ACES는 장애가 질병과는 다르며 장애인도 비장애인과 같은 권리를 누려야 한다는 관점입니다. 예를 들어 휠체어를 이용하는 장애인도 모든 곳을 편하게 다닐 수 있어야 하고, 보이지 않는 사람들도 다양한 정보를 편하게 얻을 수 있어야 한다고 생각합니다. 장애인이 겪는 어려움은 장애인이 편히 살 수 있도록 사회적 장벽을 낮추면 많이 해결될 수 있다고 생각합니다.',
            MIDD: 'V-MIDD는 장애인들도 비장애인들과 같은 권리를 누려야 하지만, 장애로 인한 차별은 장애인이 갖고 있는 신체적·정신적 손상 때문에 어쩔 수 없는 면이 있어서 바꿀 수 있는 부분들만 조금씩 바꾸면 된다는 관점입니다. 그러나 조금 더 자세히 관찰하면 장애인에게 사회적 장벽이 일상생활 곳곳에 있으며, 공동체가 함께 책임질 때 이런 문제들이 해결될 수 있다는 것을 알게 될 것입니다. ',
            RASE: 'V-RASE는 대체로 장애를 뭔가 부족한 상태로 이해하고, 장애인을 나와는 다른 집단에 속한 사람으로 구분하려는 경향입니다. 장애인을 도움이 필요한 사람으로 바라보며 장애인의 권리는 사람들이 도와줘서 보장해 주면 된다고 생각하기도 합니다. 그러나 장애인이 어려움을 느끼는 것은 일상생활 깊숙이 들어와 있는 사회적 장벽과 차별 때문인 경우가 많습니다. 개인이 일시적으로 도와주는 것보다는 사회적 장벽을 낮추고 차별을 줄이며 모두가 함께 살아갈 수 있는 환경을 만드는 것이 필요합니다. '
        },
        scope3: {
            ACES: 'E-ACES는 장애인이 특별한 존재가 아니라 같은 학교 및 지역 사회에서 일상을 함께하는 사람이라고 생각하는 경우입니다. 장애와 비장애를 특별히 구분하지 않으며 장애인을 편안하고 자연스럽게 느낍니다. 예를 들어 장애학생과 짝이 되거나 같은 모둠에 포함되는 것을 자연스럽게 받아들이고 관계를 맺고 있습니다. ',
            MIDD: 'E-MIDD는 장애인에게 이유 없이 거부감을 갖거나 회피할 정도로 부정적인 감정을 느끼지는 않지만, 장애인을 다른 사람들의 도움과 동정이 필요한 존재라고 생각하는 경우입니다. 예를 들어 장애학생이 옆에 있으면 대신 선택을 해 주거나 대답해 주려고 할 때가 있는데, 장애인을 특별하게 대하기보다 있는 그대로 존중해 주는 것이 필요합니다.',
            RASE: 'E-RASE는 장애인이 비장애인과 완전히 다른 존재라고 생각하고, 심리적 거리감을 크게 느끼는 경우입니다. 장애인에 대한 막연한 거부감과 불편함을 느끼는 경우도 종종 있으며 일부러 장애인을 피하는 경우도 있습니다. 어떤 사람을 거부하거나 피하는 것이 당하는 사람에게 큰 고통을 주는 일이라는 것을 잊지 말아야 합니다. 누구라도 고정관념이나 편견을 버리고 가까이 지내다보면 익숙해지고 친해질 수 있습니다.'
        },
        scope4: {
            ACES: 'K-ACES는 장애 특성을 이해하고 장애인에게 필요한 도움을 주는 방법, 그리고 장애인의 권리를 보장하기 위한 법과 제도에 대한 지식도 풍부합니다. 장애인에 대한 차별을 줄이기 위해 필요한 일들을 말할 수 있습니다. 장애에 대한 풍부한 지식을 바탕으로 장애인에게 필요한 도움을 줄 수도 있습니다. 지금도 장애에 대해 잘 알고 있지만 장애에 대한 지식이 계속 변화하니 꾸준히 지식을 발전시키길 바랍니다.',
            MIDD: 'K-MIDD는 장애와 관련된 용어와 장애인의 권리와 관련 제도에 대해서도 알고 있는 경우입니다. 그러나 구체적인 상황에서 장애인에게 필요한 도움을 주기 위해서는 더 세부적인 지식이 필요합니다. 이미 장애인의 권리에 대해 어느 정도 이해하고 있지만 차별을 줄이거나 없애기 위한 방법을 제안하고 적용하기 위해서는 조금 더 깊이 이해해야 합니다. 이 사회에는 다양한 구성원들이 있는데 서로 잘 알고 있어야 좋은 영향을 주고받으면서 살아갈 수 있기 때문입니다.',
            RASE: 'K-RASE는 장애의 특성이나 장애인에게 필요한 도움을 주는 방법, 장애인의 기본권 및 제도에 대해서 깊이 있게 이해하지 못하는 경우입니다. 장애인을 아픈 사람이나 불쌍한 사람이라고 말하는 등 부적절한 용어를 쓰는 경우도 간혹 있습니다. 장애와 관련하여 정확한 정보를 알아나가길 바랍니다. 이 사회에는 다양한 구성원들이 있는데 서로 잘 알고 있어야 좋은 영향을 주고받으면서 살아갈 수 있기 때문입니다. '
        }
    },
    M: {
        scope1: {
            ACES: 'A-ACES는 장애인과의 직접적인 상호 작용에 대해 거부감이 없으며 장애인과 소통하고 장애 차별과 편견 문제를 해결하는 데 관심이 많은 경우입니다. 장애학생을 놀리거나 괴롭히는 아이들을 말리거나, 학습이나 교내외 체험 활동 등에서 장애학생이 도움이 필요하면 적절하게 도와주려고 합니다. 장애인에 대한 차별과 편견은 자기가 할 수 있는 일을 조금씩이라도 실천할 때 줄어들 수 있음을 잘 아는 사람들입니다. ',
            MIDD: 'A-MIDD는 장애인과의 직접적인 상호 작용이나 관계 형성을 거부하거나 부정적인 태도로 대하지는 않지만, 적극적으로 기회를 만드는 편은 아닌 경우입니다. 장애인의 어려움을 어느 정도 이해하고 있으며 여건이 허락되면 필요한 일을 실천하려는 생각도 있습니다. 장애인에 대한 차별을 줄이기 위해 일상에서 실천할 수 있는 일에 조금씩만 더 관심을 기울이기를 바랍니다. ',
            RASE: 'A-RASE는 장애인 차별과 편견 문제에 대해 무관심하며, 장애인과의 직접적인 상호 작용 기회를 거부하거나 부정적으로 대하는 경향을 나타냅니다. 이 영역에 속하는 사람들 중 일부는 장애인에게 부적절한 용어를 사용하거나 놀리기도 합니다. 이런 행동은 다른 사람에게 큰 상처를 준다는 것을 기억해야 합니다. 장애인은 다양한 영역에서 일상적인 차별에 노출되고 있는데, 사회의 모든 구성원이 차별에 민감성을 키우고 대처할 때 좋은 사회가 될 것입니다.'
        },
        scope2: {
            ACES: 'V-ACES는 장애를 사회적 제약과 차별의 문제로 바라보며 장애인을 비장애인과 동등한 권리를 가진 사회 구성원으로 보는 관점입니다. 그래서  장애인의 권리를 보장하려면 적극적으로 정책과 제도가 마련되어야 한다고 봅니다. 예를 들어 휠체어를 이용하는 사람이 이동이 어려운 것은 그 사람이 걷지 못하기 때문이 아니라 엘리베이터나 경사로 등이 잘 갖추어지지 않은 환경이 문제라고 생각합니다.',
            MIDD: 'V-MIDD는 장애인들도 비장애인들과 같은 권리를 누려야 하지만, 장애로 인한 차별은 장애인의 신체적·정신적 손상 때문에 어쩔 수 없는 면이 있어서 바꿀 수 있는 부분들만 조금씩 바꾸면 된다는 관점입니다. 그러나 조금 더 자세히 보면 장애인에게는 사회적 장벽이 일상생활 곳곳에 있으며, 공동체가 함께 책임질 때 이런 문제들이 해결될 수 있다는 것을 알게 될 것입니다. 장애를 다른 관점에서 생각해 보기를 바랍니다.',
            RASE: 'V-RASE는 장애를 개인의 손상과 결함으로 인한 비극으로 보며, 장애인을 나와는 다른 집단에 속한 사람으로 보는 관점입니다. 장애인이 겪는 사회적 장벽은 신체적·정신적 손상으로 인해 당연하다고 여깁니다. 아프고 불쌍한 사람을 도와주듯 장애인도 도움이 필요한 사람이라고 봅니다. 조금만 세심하게 관찰하면 장애인에 대한 사회적 장벽은 일상생활 곳곳에 깊숙이 있으며 개인적인 도움이나 동정이 아니라 사회적 장벽을 낮추고 차별을 줄여야만 모두가 함께 살아갈 수 있는 환경을 만들 수 있습니다.'
        },
        scope3: {
            ACES: 'E-ACES는 장애인을 특별한 존재가 아니라 같은 학교 및 지역 사회에서 일상을 함께하는 사람으로 보고 있는 경우입니다. 장애학생과 짝이 되거나 같은 모둠에 포함되는 것을 자연스럽게 받아들이고 관계를 유지하려는 경향을 나타냅니다.',
            MIDD: 'E-MIDD는 장애인에 대해 무조건적인 거부감을 갖거나 장애인을 회피할 정도로 부정적인 감정을 느끼는 것은 아니지만, 장애인을 누군가의 도움과 동정이 필요한 존재로 보고 있는 경우입니다. 장애학생이 옆에 있으면 대신 선택해 주거나 대답해 주려고 할 때가 종종 있습니다. 장애인을 특별하게 대하기보다 있는 그대로 존중해 주는 것이 필요합니다.',
            RASE: 'E-RASE는 사람들은 장애인이 비장애인과는 다른 이질적인 존재라고 생각하여 심리적 거리감을 크게 느끼는 경우입니다. 장애인에 대한 막연한 거부감과 불편함을 느끼는 경우도 종종 있으며 의식적으로 장애인을 피하는 경우도 있습니다. 어떤 사람을 거부하거나 피하는 것이 당하는 사람에게 큰 고통을 주는 일이라는 것을 잊지 말아야 합니다. 누구라도 고정관념이나 편견을 버리고 가까이 지내다보면 익숙해지고 친해질 수 있습니다. '
        },
        scope4: {
            ACES: 'K-ACES는 장애 특성을 이해하고 지원하는 방법과 장애인의 기본권 및 기본권 보장을 위한 법과 제도의 역할에 대해서도 풍부한 지식을 가지고 있습니다. 대체로 장애인에 대한 차별을 줄이는 방법을 구체적으로 설명할 수 있으며 장애인에게 꼭 필요한 도움을 줄 수도 있습니다. 지금도 장애에 대해 잘 알고 있지만 장애에 대한 지식은 계속 변화하므로 꾸준히 지식을 발전시키길 바랍니다. ',
            MIDD: 'K-MIDD는 장애 유형, 정도, 특성 등 장애와 관련된 용어를 알고 있고 장애인의 기본권 및 장애 관련 제도에 대해서도 알고 있는 경우입니다. 그러나 구체적인 상황에서 장애학생을 지원하기 위해서는 좀 더 세부적인 지식이 필요합니다. 장애와 관련하여 정확한 정보를 알아나가길 바랍니다. 이 사회에는 다양한 구성원들이 있는데 서로 잘 알고 있어야 좋은 영향을 주고받으면서 살아갈 수 있기 때문입니다.',
            RASE: 'K-RASE는 장애의 특성과 장애인을 지원하는 방법, 그리고 장애인의 기본권 및 장애 관련 제도에 대해서 깊이 이해하지 못하고 있는 경우입니다.  장애인을 아픈 사람이나 불쌍한 사람으로 말하는 등 부적절한 용어를 쓰는 경우도 간혹 있습니다. 장애와 관련하여 정확한 정보를 알아나가길 바랍니다. 이 사회에는 다양한 구성원들이 있는데 서로 잘 알고 있어야 좋은 영향을 주고받으면서 살아갈 수 있기 때문입니다.'
        }
    },
    H: {
        scope1: {
            ACES: 'A-ACES는 장애인과의 직접적인 상호 작용에 대해 거부감이 없으며 장애인과 소통하고 장애 차별과 편견 문제를 해결하는 데 관심이 많은 경우입니다. 장애학생을 놀리거나 괴롭히는 아이들을 말리거나, 학습이나 교내외 체험 활동 등에서 장애학생이 도움이 필요하면 적절하게 도와주려고 합니다. 장애인에 대한 차별과 편견은 자기가 할 수 있는 일을 조금씩이라도 실천할 때 줄어들 수 있음을 잘 아는 사람들입니다. ',
            MIDD: 'A-MIDD는 장애인과의 직접적인 상호 작용이나 관계 형성을 거부하거나 부정적인 태도로 대하지는 않지만, 적극적으로 기회를 만드는 편은 아닌 경우입니다. 장애인의 어려움을 어느 정도 이해하고 있으며 여건이 허락되면 필요한 일을 실천하려는 생각도 있습니다. 장애인에 대한 차별을 줄이기 위해 일상에서 실천할 수 있는 일에 조금씩만 더 관심을 기울이기를 바랍니다. ',
            RASE: 'A-RASE는 장애인 차별과 편견 문제에 대해 무관심하며, 장애인과의 직접적인 상호 작용 기회를 거부하거나 부정적으로 대하는 경향을 나타냅니다. 이 영역에 속하는 사람들 중 일부는 장애인에게 부적절한 용어를 사용하거나 놀리기도 합니다. 이런 행동은 다른 사람에게 큰 상처를 준다는 것을 기억해야 합니다. 장애인은 다양한 영역에서 일상적인 차별에 노출되고 있는데, 사회의 모든 구성원이 차별에 민감성을 키우고 대처할 때 좋은 사회가 될 것입니다.'
        },
        scope2: {
            ACES: 'V-ACES는 장애를 사회적 제약과 차별의 문제로 바라보며 장애인을 비장애인과 동등한 권리를 가진 사회 구성원으로 보는 관점입니다. 그래서  장애인의 권리를 보장하려면 적극적으로 정책과 제도가 마련되어야 한다고 봅니다. 예를 들어 휠체어를 이용하는 사람이 이동이 어려운 것은 그 사람이 걷지 못하기 때문이 아니라 엘리베이터나 경사로 등이 잘 갖추어지지 않은 환경이 문제라고 생각합니다.',
            MIDD: 'V-MIDD는 장애인들도 비장애인들과 같은 권리를 누려야 하지만, 장애로 인한 차별은 장애인의 신체적·정신적 손상 때문에 어쩔 수 없는 면이 있어서 바꿀 수 있는 부분들만 조금씩 바꾸면 된다는 관점입니다. 그러나 조금 더 자세히 보면 장애인에게는 사회적 장벽이 일상생활 곳곳에 있으며, 공동체가 함께 책임질 때 이런 문제들이 해결될 수 있다는 것을 알게 될 것입니다. 장애를 다른 관점에서 생각해 보기를 바랍니다.',
            RASE: 'V-RASE는 장애를 개인의 손상과 결함으로 인한 비극으로 보며, 장애인을 나와는 다른 집단에 속한 사람으로 보는 관점입니다. 장애인이 겪는 사회적 장벽은 신체적·정신적 손상으로 인해 당연하다고 여깁니다. 아프고 불쌍한 사람을 도와주듯 장애인도 도움이 필요한 사람이라고 봅니다. 조금만 세심하게 관찰하면 장애인에 대한 사회적 장벽은 일상생활 곳곳에 깊숙이 있으며 개인적인 도움이나 동정이 아니라 사회적 장벽을 낮추고 차별을 줄여야만 모두가 함께 살아갈 수 있는 환경을 만들 수 있습니다.'
        },
        scope3: {
            ACES: 'E-ACES는 장애인을 특별한 존재가 아니라 같은 학교 및 지역 사회에서 일상을 함께하는 사람으로 보고 있는 경우입니다. 장애학생과 짝이 되거나 같은 모둠에 포함되는 것을 자연스럽게 받아들이고 관계를 유지하려는 경향을 나타냅니다.',
            MIDD: 'E-MIDD는 장애인에 대해 무조건적인 거부감을 갖거나 장애인을 회피할 정도로 부정적인 감정을 느끼는 것은 아니지만, 장애인을 누군가의 도움과 동정이 필요한 존재로 보고 있는 경우입니다. 장애학생이 옆에 있으면 대신 선택해 주거나 대답해 주려고 할 때가 종종 있습니다. 장애인을 특별하게 대하기보다 있는 그대로 존중해 주는 것이 필요합니다.',
            RASE: 'E-RASE는 사람들은 장애인이 비장애인과는 다른 이질적인 존재라고 생각하여 심리적 거리감을 크게 느끼는 경우입니다. 장애인에 대한 막연한 거부감과 불편함을 느끼는 경우도 종종 있으며 의식적으로 장애인을 피하는 경우도 있습니다. 어떤 사람을 거부하거나 피하는 것이 당하는 사람에게 큰 고통을 주는 일이라는 것을 잊지 말아야 합니다. 누구라도 고정관념이나 편견을 버리고 가까이 지내다보면 익숙해지고 친해질 수 있습니다. '
        },
        scope4: {
            ACES: 'K-ACES는 장애 특성을 이해하고 지원하는 방법과 장애인의 기본권 및 기본권 보장을 위한 법과 제도의 역할에 대해서도 풍부한 지식을 가지고 있습니다. 대체로 장애인에 대한 차별을 줄이는 방법을 구체적으로 설명할 수 있으며 장애인에게 꼭 필요한 도움을 줄 수도 있습니다. 지금도 장애에 대해 잘 알고 있지만 장애에 대한 지식은 계속 변화하므로 꾸준히 지식을 발전시키길 바랍니다. ',
            MIDD: 'K-MIDD는 장애 유형, 정도, 특성 등 장애와 관련된 용어를 알고 있고 장애인의 기본권 및 장애 관련 제도에 대해서도 알고 있는 경우입니다. 그러나 구체적인 상황에서 장애학생을 지원하기 위해서는 좀 더 세부적인 지식이 필요합니다. 장애와 관련하여 정확한 정보를 알아나가길 바랍니다. 이 사회에는 다양한 구성원들이 있는데 서로 잘 알고 있어야 좋은 영향을 주고받으면서 살아갈 수 있기 때문입니다.',
            RASE: 'K-RASE는 장애의 특성과 장애인을 지원하는 방법, 그리고 장애인의 기본권 및 장애 관련 제도에 대해서 깊이 이해하지 못하고 있는 경우입니다.  장애인을 아픈 사람이나 불쌍한 사람으로 말하는 등 부적절한 용어를 쓰는 경우도 간혹 있습니다. 장애와 관련하여 정확한 정보를 알아나가길 바랍니다. 이 사회에는 다양한 구성원들이 있는데 서로 잘 알고 있어야 좋은 영향을 주고받으면서 살아갈 수 있기 때문입니다.'
        }
    }
};

const StudentSurveyResultDetail = forwardRef(({ eventId, schoolCode, studentSeq, onPrint, onClose }, ref) => {
    const [studentData, setStudentData] = useState(null);
    const [eventDetails, setEventDetails] = useState(null);
    const [responseDetails, setResponseDetails] = useState(null);
    const [chartData, setChartData] = useState({});
    const [schoolName, setSchoolName] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [isPrinting, setIsPrinting] = useState(false);

    // Fetch event details
    useEffect(() => {
        const fetchSchoolCode = async () => {
            try {
                const response = await api.get(`/schoolmgr/school/${schoolCode}`);
                if (response.status === 200 && response.data) {
                    const { data } = response.data;
                    setSchoolData(data);
                    setSchoolName(data.schoolName);
                }
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchSchoolCode();
    }, [schoolCode]);

    // Fetch event details
    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await api.get(`/surveyevent/${eventId}`);
                if (response.status === 200 && response.data) {
                    const { data } = response.data;
                    setEventDetails(data);
                }
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchEventDetails();
    }, [eventId]);

    useEffect(() => {
        // Load student data
        const fetchStudentData = async () => {
            try {
                const response = await api.get(`/student/${studentSeq}`);
                if (response.status === 200) {
                    setStudentData(response.data);
                } else {
                    setStudentData(null);
                }
            } catch (error) {
                console.error('Error fetching student data:', error);
                setStudentData(null);
            }
        };

        // Load survey response data
        const fetchResponseDetails = async () => {
            try {
                const response = await api.get(`/api/student-response?eventId=${eventId}&schoolCode=${schoolCode}&studentSeq=${studentSeq}`);
                if (response.status === 200) {
                    const { data } = response;
                    const { response: resString } = data;
                    // Assuming the response data has the same structure as the provided JSON
                    setResponseDetails(data);

                    if (resString) {
                        const responseNumbers = resString.split('').map(Number);
                        const data = {
                            labels: responseNumbers.map((_, index) => index + 1), // X축 레이블을 1부터 36까지 설정
                            datasets: [{
                                label: 'Score per Item',
                                data: responseNumbers,
                                backgroundColor: responseNumbers.map(() => getRandomColor()),
                            }],
                        };
                        setChartData(data);
                    }
                } else {
                    setChartData(null);
                    setResponseDetails(null);
                }
            } catch (error) {
                console.error('Error fetching response data:', error);
                setResponseDetails(null);
            }
        };

        fetchStudentData();
        fetchResponseDetails();
    }, [eventId, schoolCode, studentSeq]);


    // Function to format dates
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString();
    };

    // Function to render area descriptions
    const renderAreaDescription = (level) => {
        return areaDescriptions[level] || 'No description available.';
    };

    // If data is not yet loaded, show loading message
    if (!studentData || !eventDetails || !responseDetails || !chartData) {
        return <div>Loading...</div>;
    }

    const areaChartData = {
        series: [
            {
                data: [
                    responseDetails.scope1_score,
                    responseDetails.scope2_score,
                    responseDetails.scope3_score,
                    responseDetails.scope4_score
                ],

                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'], // Different colors for each bar
            }
        ],
    };

    const xAxisData = ['실천영역', '관점영역', '정서영역', '지식영역'];

    // Close button handler (modify as per your requirement)
    const handleClose = () => {
        if (onClose) {
            onClose(); // If an onClose function is passed, call it
        } else {
            console.log('Close button clicked'); // Replace this with actual functionality
        }
    };

    const handlePrint = () => {
        console.log("handlePrint");
        onPrint();
    }


    return (
        <div ref={ref} className="wrap">
            <div className="container page-result">
                <div className="content-w-result">
                    <button type="button" className="btn-type001 close no-print" onClick={onClose} style={{ position: 'absolute', top: '10px', right: '30px' }}>
                        <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                    </button>
                    <div className="resultInner-top">
                        <button type="button" className="btn-type004 tab print" onClick={handlePrint}>
                            <span className="ico-type002 print"><i className="hide">프린트하기</i></span>
                            <span className="txt01">Print</span>
                        </button>

                        <h2><span className="logo-result"><i className="hide">학교장애인식검사 결과지</i></span></h2>
                        <strong className="result-user">
                            {schoolName} {`${studentData.schoolYear}학년 ${studentData.schoolClass}반 ${studentData.studentNumber}번 ${studentData.studentName}`}
                        </strong>

                        {eventDetails && (
                            <strong className="result-text">
                                학교장애인식검사는 장애 또는 장애인에 대한 인식 수준을 알아보기 위한 검사입니다.
                                장애 또는 장애인에 대한 인식을 알아야 하는 이유는, ‘장애’는 대부분의 사회에서 개인이 가질 수 있는 가장 큰 차이인데
                                이러한 차이에 대해 얼마나 잘 수용하고 존중하는지가 그 사회 구성원들의 시민 의식을 잘 나타내기 때문입니다.
                            </strong>
                        )}
                        {/* 검사지 구인 설명 */}
                        {studentData && (
                            <div className="box-yellow">
                                <strong className="result-text">
                                    장애인식은 관점, 지식, 정서, 실천이라는 4가지 영역으로 구성되어 있으며, 각각의 영역에서 ACES, MIDD, RASE로 장애인식의 특성이 제시되어 있습니다.
                                </strong>
                            </div>
                        )}
                        <div className="result-chart">
                            {/* <h3 className="result-title">학교장애인식검사 해석</h3> */}
                            <div className="chartLabel-w-total">
                                <strong className="result-title">종합 점수</strong>
                                <div className="ico-label-blue">
                                    <span className="txt-yellow">{responseDetails.level}</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="chart-container" style={{ width: '60%', height: '250px' }}>
                                    <BarChart
                                        series={areaChartData.series}
                                        xAxis={[{ data: xAxisData, scaleType: 'band' }]}
                                        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                                    />
                                </div>
                            </div>

                            <div className="chart-inner">
                                {['scope1', 'scope2', 'scope3', 'scope4'].map((scope, index) => (
                                    <div key={index} className="chartLabel-w">
                                        <strong className="result-title" style={{ color: scopeColors[scope] }}>{scopeLabels[scope]}</strong>
                                        <div className="ico-label-white">
                                            <span className={`txt-${scope}`} style={{ color: scopeColors[scope] }}>{responseDetails[`${scope}_level`]}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="resultInner-middle">
                        <div className="result-table">
                            <div className="resultTable-head">
                                <div className="rth-inner">영역</div>
                                <div className="rth-inner">수준</div>
                                <div className="rth-inner">해석</div>
                            </div>
                            <div className="resultTable-body">
                                {['scope1', 'scope2', 'scope3', 'scope4'].map((scope, index) => (
                                    <div key={index} className={`rtb-w-${scope}`} style={{ backgroundColor: scopeColors[scope] }}>
                                        <div className="rtb-inner">
                                            <div className="resultText-w">
                                                <strong className="result-text">{scopeLabels[scope]}</strong>
                                                <strong className="result-text-white">{scopeDescriptions[scope]}</strong>

                                            </div>
                                            <div className="ico-label-white">
                                                <span className={`txt-${scope}`}>{responseDetails[`${scope}_level`]}</span>
                                            </div>
                                        </div>
                                        <div className="rtb-inner">
                                            <span className="result-text">
                                                {areaDescriptions[schoolData.schoolGrade][scope][responseDetails[`${scope}_level`]]}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <StudentLinks schoolGrade={schoolData.schoolGrade} />
                    {/*                    <div className="result-footer">
                        <button className="btn-type004 tab no-print" onClick={handleClose}>Close</button>
                    </div>
                    */}
                </div>
            </div>
        </div>
    );
});

export default StudentSurveyResultDetail;
// Additional style options for area colors
