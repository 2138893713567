import React, { useState } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';

// Section component adjusted for consistent spacing
const Section = ({ title, summary, fullText }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Grid item xs={12} style={{ maxWidth: '1440px', margin: 'auto' }}> {/* Grid item to control max width and centering */}

      <Box sx={{ mb: 4 }}> {/* Adjusted outer spacing */}
        <Box sx={{
          display: 'inline-flex',
          p: 1,
          borderRadius: '15px',
          backgroundColor: '#fbc02d',
          color: '#FFFFFF',
          fontWeight: 'bold',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          mx: 'auto', // Centers the box if needed
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'inline-block', mx: 2 }}> {/* Added horizontal spacing inside the title box */}
            {title}
          </Typography>
        </Box>
        <Typography sx={{ mt: 2, textAlign: 'left', mx: 2 }}> {/* Consistent horizontal spacing for summary */}
          {summary}
        </Typography>
        {showMore && (
          <Typography sx={{ mt: 2, textAlign: 'left', mx: 2 }}> {/* Consistent spacing for full text */}
            {fullText}
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={() => setShowMore(!showMore)}
          sx={{
            mt: 2,
            backgroundColor: '#87CEFA', // Light sky blue color
            '&:hover': {
              backgroundColor: '#b0e0e6', // Slightly darker shade on hover
            },
            mx: 2
          }}
        >
          더 알아보기
        </Button>
      </Box>
    </Grid>
  );
};


function Intro() {
  return (
    <div>
      <Header />

      <Grid container spacing={2} justifyContent="center"> {/* Use Grid container to manage layout */}
        <Box p={3} sx={{ width: '100%', maxWidth: '1440px', paddingBottom: '100px' }}> {/* Set max width for the Box */}
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            학교장애인식검사 사용 방법
          </Typography>

          {/* Sections will use Grid layout for vertical alignment */}
          {/* Section for "초등용 학교장애인식검사" */}
          <Section
            title="초등용 학교장애인식검사"
            summary="‘초등용 학교장애인식검사’는 총 5종으로 개발되었다. 각 검사는 6개의 다른 상황에 서 총 34문항을 포함하고 있다. 그리고 하나의 상황에 4가지 구인을 나타내는 문항이 골고루 포함되어 있다. "
            fullText="학생은 홈페이지 메인 화면에서 학생용으로 접근한다. 학교급에서 '초'를 선택한 후 본인 학교명으로 검색한다. 학교가 검색이 된다면 학생등록 버튼을 눌러 학년/반/번호/이름/학생인증번호(숫자 5자리-담임선생님께 확인) 를 입력한다. 정상적으로 학생 인증이 완료되면 검사 가능한 검사지를 조회할 수 있고, 응답한 검사지에 대한 결과지를 확인할 수 있다."
          />

          {/* Section for "중·고등학생용 학교장애인식검사" */}
          <Section
            title="중·고등학생용 학교장애인식검사"
            summary="‘중·고등학생용 학교장애인식검사’는 총 5종으로 개발되었다. 각 검사는 7개의 다른 상황에 총 41문항을 포함하고 있다. 그리고 하나의 상황에 4가지 구인을 나타내는 문항이 골고루 포함되어 있다."
            fullText="학생은 홈페이지 메인 화면에서 학생용으로 접근한다. 학교급에서 중학생은 '중', 고등학생은 '고'를 선택한 후 본인 학교명으로 검색한다. 학교가 검색이 된다면 학생등록 버튼을 눌러 학년/반/번호/이름/학생인증번호(숫자 5자리-담임선생님께 확인) 를 입력한다. 정상적으로 학생 인증이 완료되면 검사 가능한 검사지를 조회할 수 있고, 응답한 검사에 대한 결과를 확인할 수 있다."
          /> 

          {/* Section for "교사용 학교장애인식검사" */}
          <Section
            title="교사용 학교장애인식검사"
            summary="‘교사용 학교장애인식검사’는 학생용과 마찬가지로 총 5종으로 개발되었다. 각 검사는 6개의 다른 상황에 총 36문항을 포함하고 있다. 그리고 하나의 상황에 4가지 구인을 나타내는 문항이 골고루 포함되어 있다. "
            fullText="교원(담임/비담임)은 홈페이지 메인 화면에서 교사용으로 접근한다. 회원가입을 통해 본인 학교를 검색한 후 '교원회원가입' 버튼을 클릭하여 가입을 진행한다. 이 때 교원인증번호는 학교관리자를 통해 확인한다. 회원가입이 정상적으로 완료되면 로그인 후에 검사 가능한 검사지와 응답 결과지를 확인할 수 있다. 담임의 경우 반 학생들의 검사 결과지를 조회할 수 있다."
          />

          {/* Section for "검사 시행 지침" */}
          <Section
            title="학교관리자"
            summary="학교정보관리, 학급관리, 교원관리를 할 수 있다. 학교 검사결과를 조회할 수 있다."
            fullText="교육청으로부터 통보받은 학교관리자인증 URL에 접속한 후 학교관리자로 등록한다. 기존 학교관리자가 존재하는 경우에는 교육청에 학교관리자 삭제를 요청한 후에 학교관리자 등록 절차를 진행한다."
          />

        </Box>
      </Grid>

      <Footer />
    </div>
  );
}

export default Intro;
