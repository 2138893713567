import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        fontSize: '0.8rem',
    },
    '& .MuiInputBase-input': {
        fontSize: '0.9rem',
    },
    '& .MuiInputLabel-root': {
        fontSize: '0.9rem',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&:hover fieldset': {
            borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
        },
    },
});

// Similarly for FormControlLabel, if you want to adjust styles
const StyledFormControlLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        // Adjust the font size here for the labels next to the radios
        fontSize: '0.9rem',
    },
});

function SchoolAuth({ selectedMenu }) {
    const navigate = useNavigate();

    // 새 이벤트의 초기 상태 정의
    const [authData, setAuthData] = useState({
        seq: '',
        url: '',
        password: '',
    });
    const [saveStatus, setSaveStatus] = useState('default'); // 'default', 'saving', 'success', 'error'

    // Additional state to keep track of the initial values
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await api.get(`/schoolmgr/schoolauth`);
            setAuthData(response.data);
            setInitialData(response.data); // Store the initial data
        };
        fetchData();
    }, []);

    const handleSave = async () => {
        if (!window.confirm('저장하겠습니까?')) {
            return; // If the user cancels, do not proceed with saving
        }

        setSaveStatus('saving');
        const dataToSave = {
            seq: authData.seq,
            url: authData.url,
            password: authData.password
        };

        try {
            const response = await api.put(`/schoolmgr/schoolauth`, dataToSave);
            if (response.status >= 200 && response.status < 300) {
                setSaveStatus('success');
                setInitialData(response.data); // Store the initial data
                alert('저장되었습니다.');
                // navigate('/operator', { state: { selectedMenu: '학교인증관리' } });
            } else {
                setSaveStatus('error');
                alert(`저장에 실패했습니다. 상태 코드: ${response.status}`);
            }
        } catch (error) {
            setSaveStatus('error');
            alert(`저장에 실패했습니다. 오류 메시지: ${error.message}`);
            console.error("There was an error saving the auth", error);
        }
    };

    // Get color based on save status
    const getButtonColor = () => {
        switch (saveStatus) {
            case 'success':
                return 'green';
            case 'error':
                return 'red';
            case 'saving':
                return 'blue'; // Or another color to indicate in-progress
            default:
                return 'blue'; // Default color
        }
    };

    const handleCancel = () => {
        setAuthData(initialData); // Reset the authData state
    };

    const handleCopy = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(authData.url).then(() => {
                alert('URL이 클립보드에 복사되었습니다.');
            }).catch(err => {
                console.error('복사 실패:', err);
            });
        } else {
            // Fallback method for older browsers
            const textArea = document.createElement('textarea');
            textArea.value = authData.url;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                alert('URL이 클립보드에 복사되었습니다.');
            } catch (err) {
                console.error('복사 실패:', err);
            }
            document.body.removeChild(textArea);
        }
    };

    return (
        <div className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 학교관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <div className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">학교등록번호</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="seq" className="hide">Seq</label>
                                <input
                                    id="seq"
                                    type="text"
                                    value={authData.seq}
                                    disabled
                                    placeholder="Seq"
                                />
                            </div>
                            
                        </form>
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">학교등록 URL</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="url" className="hide">URL</label>
                                <input
                                    id="url"
                                    type="text"
                                    value={authData.url}
                                    onChange={(e) => setAuthData({ ...authData, url: e.target.value })}
                                    placeholder="http://sample"
                                />
                            </div>
                            <button type="button" className="btn-type001 copy" onClick={handleCopy}>
                                <span className="ico-type002 copy"><i className="hide">복사</i></span>
                            </button>
                        </form>
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">인증비밀번호</strong>
                            </div>
                            <div className="input-type01">
                                <label htmlFor="password" className="hide">Password</label>
                                <input
                                    id="password"
                                    type="text"
                                    value={authData.password}
                                    onChange={(e) => setAuthData({ ...authData, password: e.target.value })}
                                    placeholder="Password"
                                />
                            </div>
                        </form>
                    </div>

                    <div className="btns-group03">
                        <button
                            type="button"
                            className="btn-type005 blue"
                            onClick={handleSave}
                            disabled={saveStatus === 'saving'}
                        >
                            <span className="txt">저장</span>
                        </button>
                        <button type="button" className="btn-type005 border" onClick={handleCancel}>
                            <span className="txt">취소</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SchoolAuth;