import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import api, { signInOperator } from './apiService';
import { useLocation } from 'react-router-dom';
import Modal from './components/Modal';

function Login() {
  const [selectedRole, setSelectedRole] = useState('교원');
  const [showSignupButton, setShowSignupButton] = useState(true);
  const [loginError, setLoginError] = useState(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('로그인 실패');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const group = searchParams.get('group');
    
    if (group === '1') {
      setSelectedRole('교육청');
    } else if (group === '3') {
      setSelectedRole('교원');
    } else {
      setSelectedRole('교원'); // 기본값 설정, 로그인 초기 화면은 교사와 교육청만 선택됨.
    }
  }, [location]);

  useEffect(() => {
    setShowSignupButton(selectedRole !== '교육청' && selectedRole !== '교육지원청');
  }, [selectedRole]);

  const handleLogin = () => {
    const userGroupMapping = {
      '교원': '3',
      '학교관리자': '2',
      '교육지원청': '5',
      '교육청': '1'
    };

    const userData = {
      usergroup: userGroupMapping[selectedRole],
      userid: userId,
      password: password
    };

    signInOperator(userData)
      .then(response => {
        if (response && response.status === 200 && response.data) {
          setLoginError(false);
          localStorage.setItem('loginInfo', JSON.stringify(response.data));
          
          if (response.data.data.usergroup === '5') { // 교육지원청
            navigate('/supporter');
          } else if (response.data.data.usergroup === '1') { // 교육청
            navigate('/operator');
          } else if (response.data.data.usergroup === '2') { // 학교관리자
            api.get(`/schoolmgr/schoolmanager/${userData.userid}`)
              .then(response => {
                localStorage.setItem('schoolCode', response.data.data.schoolCode);
                navigate('/manager');
              })
              .catch(err => {
                console.error("Failed to fetch school manager data:", err);
                setLoginError(true);
              });
          } else if (response.data.data.usergroup === '3') { // 교원
            api.get(`/schoolmgr/teacher/${userData.userid}`)
              .then(response => {
                localStorage.setItem('schoolCode', response.data.data.schoolCode);
                localStorage.setItem('teacherId', response.data.data.teacherId);
                navigate('/teacher');
              })
              .catch(err => {
                console.error("Failed to fetch school manager data:", err);
                setLoginError(true);
              });
          }
        } else {
          showErrorModal();
        }
      })
      .catch(error => {
        showErrorModal();
      });
  }

  const showErrorModal = () => {
    let message = '';
    if (selectedRole === '교원') {
      message = '아이디, 비밀번호가 일치하지 않습니다. 아이디, 비밀번호는 학교관리자에게 문의하시기 바랍니다.';
    } else if (selectedRole === '학교관리자' || selectedRole === '교육지원청') {
      message = '아이디, 비밀번호가 일치하지 않습니다. 아이디, 비밀번호는 교육청에 문의하시기 바랍니다.';
    } else if (selectedRole === '교육청') {
      message = '아이디, 비밀번호가 일치하지 않습니다. 운영기관 담당자에 문의하시기 바랍니다.';
    }
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const handleSignup = () => {
    if (selectedRole === '교원') {
      //window.location.href = '/teachersignup';
      navigate('/teachersignup');
    } else if (selectedRole === '학교관리자') {
      //window.location.href = '/managerauth';
      navigate('/managerauth');
    }
  }

  return (
    <div className="wrap">
      <Header />

      <div id="container" className="container page-login">
        <div className="content-w-login">
          <h2 className="title-type001">
            <strong className="tit01">로그인</strong>
          </h2>

          <div className="login-inner">
            <div className="radio-form">
              <span className="input-radio01">
                <input type="radio" id="type01" name="types" className="radio01" value="교원" checked={selectedRole === '교원'} onChange={() => setSelectedRole('교원')} />
                <label htmlFor="type01" className="txt-label01">교원</label>
              </span>
              <span className="input-radio01">
                <input type="radio" id="type02" name="types" className="radio01" value="학교관리자" checked={selectedRole === '학교관리자'} onChange={() => setSelectedRole('학교관리자')} />
                <label htmlFor="type02" className="txt-label01">학교관리자</label>
              </span>
              <span className="input-radio01">
                <input type="radio" id="type03" name="types" className="radio01" value="교육지원청" checked={selectedRole === '교육지원청'} onChange={() => setSelectedRole('교육지원청')} />
                <label htmlFor="type03" className="txt-label01">교육지원청</label>
              </span>
              <span className="input-radio01">
                <input type="radio" id="type04" name="types" className="radio01" value="교육청" checked={selectedRole === '교육청'} onChange={() => setSelectedRole('교육청')} />
                <label htmlFor="type04" className="txt-label01">교육청</label>
              </span>
            </div>

            <div className="fieldset-type02">
              <form className="input-group02">
                <div className="title-type002">
                  <strong className="tit01">아이디</strong>
                </div>
                <div className="input-type01">
                  <label htmlFor="id" className="hide">아이디</label>
                  <input id="id" type="text" placeholder="아이디" value={userId} onChange={(e) => setUserId(e.target.value)} />
                </div>
              </form>
              <form className="input-group02">
                <div className="title-type002">
                  <strong className="tit01">비밀번호</strong>
                </div>
                <div className="input-type01">
                  <label htmlFor="pwd" className="hide">비밀번호</label>
                  <input id="pwd" type="password" placeholder="비밀번호" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
              </form>
              <div className="btns-group03">
                <button type="button" className="btn-type005 blue" onClick={handleLogin}>
                  <span className="txt">로그인</span>
                </button>
                {showSignupButton && (
                  <button type="button" className="btn-type005 border" onClick={handleSignup}>
                    <span className="txt">회원가입</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} message={modalMessage} title={modalTitle} />
    </div>
  );
}

export default Login;
