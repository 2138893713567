import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from './Login';
import LoginTest from './LoginTest';
import LogoutTest from './LogoutTest';
import Operator from './Operator';
import Supporter from './Supporter';
import ManagerAuth from './ManagerAuth';
import Manager from './Manager';
import TeacherSignUp from './TeacherSignUp';
import Teacher from './Teacher';
import StudentSignUp from './StudentSignUp';
import Student from './Student';
import NoticeList from './NoticeList';
import NoticeCreate from './NoticeCreate';
import HomeAll from './HomeAll';
import Intro from './Intro';
import Usage from './Usage';
import { MenuProvider } from './MenuContext'; // MenuProvider import

const RedirectBasedOnRole = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const loginInfoString = localStorage.getItem('loginInfo');
    //console.log(loginInfoString);
    
    if (loginInfoString) {
      try {
        const loginInfo = JSON.parse(loginInfoString);
        const { usergroup } = loginInfo.data; // usergroup: '1', '2', '3', etc. 객체 구조 분할. data 안에 usergroup 존재.
        
        switch (usergroup) {
          case '1': // 교육청 
            navigate('/operator');
            break;
          case '2': // 학교관리자 
            navigate('/manager');
            break;
          case '3': // 교사
            navigate('/teacher');
            break;
          case '4': // 학생
            navigate('/student');
            break;
          case '5': // 교육지원청
            navigate('/supporter');
            break;
          default:
            navigate('/home');
        }
      } catch (error) {
        console.error('Error parsing loginInfo:', error);
      }
    }
  }, [navigate]);

  return null;
};

const AppRouter = () => {
  return (
    <MenuProvider> {/* MenuProvider 추가 */}
      <RedirectBasedOnRole />
      <Routes>
        <Route path="/" element={<HomeAll />} /> 
        <Route path="/login" element={<Login />} /> 
        <Route path="/logintest" element={<LoginTest />} /> 
        <Route path="/logouttest" element={<LogoutTest />} /> 
        <Route path="/operator" element={<Operator />} /> 
        <Route path="/supporter" element={<Supporter />} /> 
        <Route path="/managerauth" element={<ManagerAuth />} /> 
        <Route path="/manager" element={<Manager />} /> 
        <Route path="/teachersignup" element={<TeacherSignUp />} /> 
        <Route path="/teacher" element={<Teacher />} /> 
        <Route path="/studentsignup" element={<StudentSignUp />} /> 
        <Route path="/student" element={<Student />} /> 
        <Route path="/notice" element={<NoticeList />} /> 
        <Route path="/noticecreate" element={<NoticeCreate />} /> 
        <Route path="/home" element={<HomeAll />} /> 
        <Route path="/intro" element={<Intro />} /> 
        <Route path="/usage" element={<Usage />} /> 
      </Routes>
    </MenuProvider>
  );
}

export default AppRouter;
