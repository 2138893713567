import React, { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
    const [currentMenu, setCurrentMenu] = useState('');

    return (
        <MenuContext.Provider value={{ currentMenu, setCurrentMenu }}>
            {children}
        </MenuContext.Provider>
    );
};
