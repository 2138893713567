import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';
import CustomToolbar from './components/CustomToolbar';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '70vw',
        height: '70vh',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

const StyledDetailDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        minWidth: '80%',
        maxWidth: 'none',
        width: 'auto',
        minHeight: '80vh',
        maxHeight: 'none',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100vh'
        }
    }
}));

function ManagerSurveyResultStudentDetail({ open, onClose, schoolCode, eventId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [detailedData, setDetailedData] = useState([]);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [schoolName, setSchoolName] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedClass, setSelectedClass] = useState('');

    useEffect(() => {
        setIsLoading(true);
        if (schoolCode && eventId) {
            const fetchData = async () => {
                try {
                    const response = await api.get(`/surveyresult/bySchool/detailed?schoolCode=${schoolCode}&eventId=${eventId}`);
                    setData(response.data.data);
                    setSchoolName(response.data.data[0].schoolName);
                   
                } catch (error) {
                    console.error("Error fetching detailed survey results:", error);
                }
                setIsLoading(false);
            };
            fetchData();
        }
    }, [schoolCode, eventId]);

    const handleRowClick = async (params) => {
        try {
            setIsLoading(true);
            const response = await api.get(`/surveyresult/school/class?eventId=${eventId}&schoolCode=${schoolCode}&Year=${params.row.schoolYear}&Class=${params.row.schoolClass}`);
            setDetailedData(response.data.data);
            setSelectedYear(params.row.schoolYear);
            setSelectedClass(params.row.schoolClass);
            setIsDetailModalOpen(true);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching student details:', error);
            setIsLoading(false);
        }
    };

    const handleCloseDetailModal = () => {
        setIsDetailModalOpen(false);
    };

    if (!data || data.length === 0) return null;

    const columns = [
        { field: 'schoolYear', headerName: '학년', flex: 1 },
        { field: 'schoolClass', headerName: '반', flex: 1 },
        { field: 'totalResponses', headerName: '총 응답 수', flex: 1 },
        { field: 'totalAces', headerName: 'ACES 수', flex: 1 },
        { field: 'totalMidd', headerName: 'MIDD 수', flex: 1 },
        { field: 'totalRase', headerName: 'RASE 수', flex: 1 },
    ];

    const detailcolumns = [
        { field: 'responseStuSeq', headerName: 'resstuseq', flex: 1 },
        { field: 'studentSeq', headerName: 'stuseq', flex: 1 },
        { field: 'schoolYear', headerName: 'Year', flex: 1 },
        { field: 'schoolClass', headerName: 'Class', flex: 1 },
        { field: 'studentNumber', headerName: 'Number', flex: 1 },
        { field: 'studentName', headerName: 'Name', flex: 1 },
        { field: 'score', headerName: 'Score', flex: 1 },
        { field: 'level', headerName: 'Level', flex: 1 },
    ];

    const dataGridComponent = (
        <DataGrid slots={{ toolbar: () => <CustomToolbar fileName={`검사결과(전체학년)_${schoolName}`} /> }} 
            rows={data}
            columns={columns}
            getRowId={(row) => `${row.schoolYear}-${row.schoolClass}`}
            onRowClick={handleRowClick}
            style={{ height: '60vh' }}
            loading={isLoading}
        />
    );

    const detailModalComponent = (
        <StyledDetailDialog open={isDetailModalOpen} onClose={handleCloseDetailModal}>
            <DialogTitle>{`검사결과(${selectedYear}학년/${selectedClass}반)`}</DialogTitle>
            <DialogContent style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                overflow: 'auto',
                maxHeight: '70vh'
            }}>
                <DataGrid slots={{ toolbar: () => <CustomToolbar fileName={`검사결과(${selectedYear}학년/${selectedClass}반)_${schoolName}`} /> }} 
                    rows={detailedData}
                    columns={detailcolumns}
                    getRowId={(row) => row.responseStuSeq}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    disableRowSelectionOnClick
                    style={{ height: '60vh' }}
                    loading={isLoading}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
                    <Button onClick={handleCloseDetailModal}>Close</Button>
                </div>
            </DialogContent>
        </StyledDetailDialog>
    );

    return (
        <div>
            <StyledDialog open={open} onClose={onClose} maxWidth={false}>
                <DialogTitle>{`검사결과(전체 학년) / ${data[0].schoolName}`}</DialogTitle>
                <DialogContent style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    overflow: 'auto',
                    maxHeight: '70vh'
                }}>
                    {dataGridComponent}
                </DialogContent>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
                    <Button onClick={onClose}>Close</Button>
                </div>
            </StyledDialog>
            {detailModalComponent}
        </div>
    );
}

export default ManagerSurveyResultStudentDetail;
