import React, { useState, useEffect } from 'react';
import api from './apiService';

function ManagerInfoDetail({ userId, onClose }) {
    const initialSchoolManagerState = {
        schoolManagerId: '',
        schoolManagerPasswd: '',
        schoolManagerName: '',
        schoolManagerPhone: '',
        registerDate: '',
        updatetimeDate: null,
        schoolCode: '',
        schoolDTO: {
            schoolCode: '',
            schoolName: '',
            schoolGrade: '',
            schoolZipcode: '',
            schoolAddress1: '',
            schoolAddress2: '',
            teacherPasswd: ''
        }
    };

    const [schoolManager, setSchoolManager] = useState(initialSchoolManagerState);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    useEffect(() => {
        const fetchSchoolManagerData = async () => {
            try {
                const response = await api.get(`/schoolmgr/schoolmanager/${userId}`);
                if (response.status === 200 && response.data.data) {
                    setSchoolManager(response.data.data);
                } else {
                    console.log('Response status is not 200 or data is missing');
                }
            } catch (error) {
                console.error('Error fetching school manager data:', error);
            }
        };

        if (userId) {
            fetchSchoolManagerData();
        } else {
            console.log('userId is missing');
        }
    }, [userId]);

    const handleSave = async () => {
        if (!newPassword) {
            alert('새로운 비밀번호를 입력하세요.');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            alert('패스워드가 일치하지 않습니다.');
            return;
        }

        try {
            const updatedData = {
                ...schoolManager,
                schoolManagerPasswd: newPassword,
                registerDate: new Date().toISOString()
            };

            console.log(updatedData);
            const response = await api.put(`/schoolmgr/schoolmanager/${userId}`, updatedData);

            if (response.status === 200 && response.data.data) {
                alert('정상적으로 변경했습니다.');
                onClose(); // Close the modal on successful save
            } else {
                alert('문제가 발생했습니다.');
            }
        } catch (error) {
            alert('문제가 발생했습니다.');
            console.error(error);
        }
    };

    return (
        <div id="modalEdit" className="modal-w active">
            <div className="modal-inner-type45">
                <div className="title-type001">
                    <strong className="tit01">내정보</strong>
                </div>
                <fieldset className="fieldset-type02">
                    <div className="input-type01">
                        <label htmlFor="id" className="hide">아이디</label>
                        <input id="id" type="text" value={schoolManager.schoolManagerId} readOnly placeholder="아이디(최대 10자리)" />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="pwd2" className="hide">새로운 비밀번호</label>
                        <input id="pwd2" type="password" placeholder="새로운 비밀번호" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="pwd3" className="hide">새로운 비밀번호 확인</label>
                        <input id="pwd3" type="password" placeholder="새로운 비밀번호 확인" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="name" className="hide">관리자명</label>
                        <input id="name" type="text" placeholder="관리자명" value={schoolManager.schoolManagerName} onChange={(e) => setSchoolManager({ ...schoolManager, schoolManagerName: e.target.value })} />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="phone" className="hide">연락처</label>
                        <input id="phone" type="text" placeholder="연락처" value={schoolManager.schoolManagerPhone} onChange={(e) => setSchoolManager({ ...schoolManager, schoolManagerPhone: e.target.value })} />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="schoolCode" className="hide">학교코드</label>
                        <input id="schoolCode" type="text" value={schoolManager.schoolCode} readOnly placeholder="학교코드" />
                    </div>
                    <div className="input-type01">
                        <label htmlFor="schoolName" className="hide">학교명</label>
                        <input id="schoolName" type="text" value={schoolManager.schoolDTO?.schoolName} readOnly placeholder="학교명" />
                    </div>
                    <button type="button" className="btn-type005 blue" onClick={handleSave}><span className="txt">저장</span></button>
                </fieldset>
                
                <button type="button" className="btn-type005 border close" onClick={onClose}>
                    <span className="txt01">닫기</span>
                </button>
            </div>
            <i className="dimm"></i>
        </div>
    );
}

export default ManagerInfoDetail;