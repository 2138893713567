import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import SurveyResultGradeStudent from './SurveyResultGradeStudent';
import SurveyResultGradeTeacher from './SurveyResultGradeTeacher';
import SurveyResultbySchool from './SurveyResultbySchool';
import SurveyResultbySchoolTeacher from './SurveyResultbySchoolTeacher';
import SchoolAuth from './SchoolAuth';
import SchoolSearch from './SchoolSearch';
import SchoolManagerSearch from './SchoolManagerSearch';
import SchoolTeacherSearch from './SchoolTeacherSearch';
import OperatorPrivateInfoManage from './OperatorPrivateInfoManage';
import MyInfo from './MyInfo';

function Supporter() {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleMouseOver = () => {
      setIsFooterVisible(true);
    };

    const handleMouseOut = () => {
      setIsFooterVisible(false);
    };

    const footerElement = document.querySelector('.footer');
    footerElement.addEventListener('mouseover', handleMouseOver);
    footerElement.addEventListener('mouseout', handleMouseOut);

    return () => {
      footerElement.removeEventListener('mouseover', handleMouseOver);
      footerElement.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const menuFromURL = queryParams.get('menu');
    if (menuFromURL) {
      setSelectedMenu(menuFromURL);
    } else if (location.state?.selectedMenu) {
      setSelectedMenu(location.state.selectedMenu);
    } else {
      setSelectedMenu('학교급별결과(학생)');
    }
  }, [location]);

  useEffect(() => {
    const loginInfo = localStorage.getItem('loginInfo');
    if (!loginInfo) {
      navigate('/login');
    }
  }, [selectedMenu]);

  const changeMenu = (menu) => {
    setSelectedMenu(menu);
    navigate(`/supporter?menu=${menu}`, { state: { selectedMenu: menu } });
  };

  return (
    <div style={{ paddingBottom: '60px' }}>
      <Header setSelectedMenu={changeMenu} />

      {selectedMenu === "학교급별결과(학생)" && <SurveyResultGradeStudent selectedMenu={selectedMenu}/>}
      {selectedMenu === "학교급별결과(교원)" && <SurveyResultGradeTeacher selectedMenu={selectedMenu}/>}
      {selectedMenu === "학교별결과(학생)" && <SurveyResultbySchool selectedMenu={selectedMenu}/>}
      {selectedMenu === "학교별결과(교원)" && <SurveyResultbySchoolTeacher selectedMenu={selectedMenu}/>}
      {selectedMenu === "학교인증관리" && <SchoolAuth selectedMenu={selectedMenu}/> }
      {selectedMenu === "학교정보관리" && <SchoolSearch selectedMenu={selectedMenu}/>}
      {selectedMenu === "학교관리자관리" && <SchoolManagerSearch selectedMenu={selectedMenu}/>}
      {selectedMenu === "교원관리" && <SchoolTeacherSearch selectedMenu={selectedMenu}/>}
      {selectedMenu === "개인정보관리" && <OperatorPrivateInfoManage selectedMenu={selectedMenu}/>}
      {selectedMenu === "내정보" && <MyInfo selectedMenu={selectedMenu}/>}
      <div 
        className={`footer ${isFooterVisible ? 'visible' : 'hidden'}`}
        onMouseOver={() => setIsFooterVisible(false)}
        onMouseOut={() => setIsFooterVisible(false)}
      >
        {/*<Footer />*/}
      </div>
    </div>
  );
}

export default Supporter; 