import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '30%',
        maxWidth: '800px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

function SchoolManagerDetail({ open, onClose, schoolManagerId, onDeleteSuccess }) {
    const [schoolManagerData, setSchoolManagerData] = useState(null);

    useEffect(() => {
        if (schoolManagerId) {
            const fetchData = async () => {
                const response = await api.get(`/schoolmgr/schoolmanager/${schoolManagerId}`);
                setSchoolManagerData(response.data.data);
            };
            fetchData();
        }
    }, [schoolManagerId]);

    const handleDelete = async () => {
        if (!window.confirm('삭제하시겠습니까?')) {
            return;
        }
        try {
            const response = await api.delete(`/schoolmgr/schoolmanager/${schoolManagerId}`);
            if (response.status >= 200 && response.status < 300) {
                alert('삭제에 성공했습니다.');
                onDeleteSuccess(schoolManagerId);
                onClose();
            }
        } catch (error) {
            alert('삭제에 실패했습니다. 오류: ' + error.message);
            console.error('Failed to delete:', error);
        }
    };

    if (!schoolManagerData) return null;

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth={false}>
            <DialogTitle>학교관리자 정보</DialogTitle>
            <DialogContent style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                overflow: 'auto',
                maxHeight: '70vh'
            }}>
                <TextField
                    label="학교관리자ID"
                    fullWidth
                    value={schoolManagerData.schoolManagerId}
                    disabled={true}
                />

                <TextField
                    label="관리자명"
                    fullWidth
                    value={schoolManagerData.schoolManagerName}
                    disabled={true}
                />

                <TextField
                    label="연락처"
                    fullWidth
                    value={schoolManagerData.schoolManagerPhone}
                    disabled={true}
                />

                <TextField
                    label="학교명"
                    fullWidth
                    value={schoolManagerData.schoolDTO.schoolName}
                    disabled={true}
                />

                <div style={{ display: 'flex', gap: '12px' }}>
                    <Button onClick={handleDelete}>삭제</Button>
                    <Button onClick={onClose}>닫기</Button>
                </div>

            </DialogContent>
        </StyledDialog>
    );
}

export default SchoolManagerDetail;
