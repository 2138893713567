import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import api from './apiService';
import { Box } from '@mui/material';
import SurveyResultGradeStudentDetail from './SurveyResultGradeStudentDetail';
import CustomToolbar from './components/CustomToolbar'; // CustomToolbar를 임포트합니다

function SurveyResultGradeStudent({ selectedMenu }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const [selectedEventId, setSelectedEventId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const columns = [
        { field: 'eventTitle', headerName: '검사이벤트', flex: 1.5 },
        { field: 'eventId', headerName: '이벤트ID', flex: 1 },
        { field: 'schoolLevel', headerName: '학교급', flex: 1 },
        { field: 'totalResponses', headerName: '총 응답 수', flex: 1 },
        { field: 'acesCount', headerName: 'ACES 수', flex: 1 },
        { field: 'middCount', headerName: 'MIDD 수', flex: 1 },
        { field: 'raseCount', headerName: 'RASE 수', flex: 1 },
    ];

    const fetchData = async () => {
        api.get("/surveyresult/grade_student")
            .then(response => {
                const dataWithIds = response.data.data.map(item => ({ ...item, id: item.eventId }));

                setData(dataWithIds);

                /*  랜덤하게 200개 데이터 추가... 스크롤 페이지 테스트 용 
                const testData = Array.from({ length: 200 }, (_, index) => ({
                    id: `ID${index + 1}`,
                    eventTitle: `Event ${index + 1}`,
                    eventId: `EID${index + 1}`,
                    schoolLevel: ['초', '중', '고'][index % 3],
                    totalResponses: Math.floor(Math.random() * 100),
                    acesCount: Math.floor(Math.random() * 50),
                    middCount: Math.floor(Math.random() * 50),
                    raseCount: Math.floor(Math.random() * 50),
                }));
    
                // Combine API data with test data
                setData([...dataWithIds, ...testData]);
                */
                setIsLoading(false);

            })
            .catch(error => {
                console.error("API fetch error:", error);
            });
    };

    useEffect(() => {
        fetchData();

    }, []);

    const handlePageChange = (event, page) => {
        setPaginationModel({
            ...paginationModel,
            page,
        });
    };

    const handleRowClick = (params) => {
        setSelectedEventId(params.id);
        setOpenDialog(true);
        console.log(params.row);
    };

    return (
        <div id="container" className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 검사결과관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>
                {/*<div className="form-inner">*/}
                <Box sx={{ width: '100%', height: data && data.length > 0 ? 'calc(100vh - 300px)' : 'auto' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 20]}
                        onPageChange={handlePageChange}
                        getRowId={(row) => row.eventId}
                        onRowClick={handleRowClick}
                        slots={{
                            toolbar: () => <CustomToolbar fileName="학교급별결과(학생)" />,
                            noRowsOverlay: () => (
                                <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                    <strong className="tit01">조회된 데이터가 없습니다.</strong>
                                </div>
                            )
                        }}
                        loading={isLoading}
                    />

                    {openDialog && <SurveyResultGradeStudentDetail
                        open={openDialog}
                        onClose={() => {
                            setOpenDialog(false);
                            fetchData();
                        }}
                        eventId={selectedEventId}
                    />}
                </Box>
            </div>
        </div>
    );
}

export default SurveyResultGradeStudent;
