import React, { useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';

// Section component adjusted for consistent spacing
const Section = ({ title, summary, fullText }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Grid item xs={12} style={{ maxWidth: '1440px', margin: 'auto' }}> {/* Grid item to control max width and centering */}

      <Box sx={{ mb: 4 }}> {/* Adjusted outer spacing */}
        <Box sx={{
          display: 'inline-flex',
          p: 1,
          borderRadius: '15px',
          backgroundColor: '#fbc02d',
          color: '#FFFFFF',
          fontWeight: 'bold',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          mx: 'auto', // Centers the box if needed
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'inline-block', mx: 2 }}> {/* Added horizontal spacing inside the title box */}
            {title}
          </Typography>
        </Box>
        <Typography sx={{ mt: 2, textAlign: 'left', mx: 2 }}> {/* Consistent horizontal spacing for summary */}
          {summary}
        </Typography>
        {showMore && (
          <Typography sx={{ mt: 2, textAlign: 'left', mx: 2 }}> {/* Consistent spacing for full text */}
            {fullText}
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={() => setShowMore(!showMore)}
          sx={{
            mt: 2,
            backgroundColor: '#87CEFA', // Light sky blue color
            '&:hover': {
              backgroundColor: '#b0e0e6', // Slightly darker shade on hover
            },
            mx: 2
          }}
        >
          더 알아보기
        </Button>
      </Box>
    </Grid>
  );
};


function Intro() {
  return (
    <div>
      <Header />

      <Grid container spacing={2} justifyContent="center"> {/* Use Grid container to manage layout */}
        <Box p={3} sx={{ width: '100%', maxWidth: '1440px', paddingBottom: '100px' }}> {/* Set max width for the Box */}
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            학교장애인식검사 소개
          </Typography>

          {/* Sections will use Grid layout for vertical alignment */}
          {/* Section for "검사의 목적과 중요성" */}
          <Section
            title="검사의 목적과 중요성"
            summary="학교장애인식검사는 학생과 교육자들의 장애에 대한 이해와 태도를 높이기 위해 마련되었습니다. 이를 통해 포용적인 교육 환경 조성을 목표로 합니다."
            fullText="이 검사는 교육 환경 내에서 장애에 대한 인식 개선과 태도 변화를 촉진하고자 개발되었습니다. 학교 커뮤니티가 장애를 둘러싼 오해와 편견을 인식하고, 이를 극복하기 위한 긍정적인 행동 변화를 이끌어내는 것이 중요한 목표입니다. 이 과정을 통해 모든 학생에게 동등한 교육 기회를 제공하고, 장애를 가진 학생들이 교육 공동체의 일원으로서 완전히 통합될 수 있는 환경을 조성하는 데 기여하고자 합니다."
          />

          {/* Section for "검사 개발 배경" */}
          <Section
            title="검사 개발 배경"
            summary="검사는 교육 현장에서 장애에 대한 이해와 수용성을 높이기 위한 필요성에 기반하여 개발되었습니다. 이는 포용적 교육 정책과 실천을 지원하기 위해 연구와 협력을 통해 이루어졌습니다."
            fullText="이 검사는 다양한 교육 이해관계자들의 의견을 수렴하고, 장애 인식에 관한 최신 연구 결과를 통합하여 설계되었습니다. 교육 과정에 장애에 대한 깊은 이해와 포용을 장려하는 내용을 포함시키기 위한 목적으로, 장애인 권리 보장 및 평등한 교육 접근을 강조하는 국내외 법령과 정책을 반영하고자 노력하였습니다. 검사는 교육자들이 장애에 대한 편견 없는 태도를 개발하고, 모든 학생이 존중받으며 학습할 수 있는 환경을 조성하는 데 중요한 도구가 될 것입니다."
          />

          {/* Section for "검사 구성 요소" */}
          <Section
            title="검사 구성 요소"
            summary="검사 구성 요소는 장애에 대한 태도, 지식, 그리고 포용적 행동을 포함하여 장애 인식과 관련된 다양한 영역을 측정합니다. 이러한 구성을 통해 교육 환경 내에서 장애에 대한 이해와 수용성을 높이는 것을 목표로 합니다."
            fullText="이 검사는 장애에 대한 개인의 태도와 인식을 평가하는 문항, 장애와 관련된 기본적 지식을 측정하는 문항, 그리고 장애인을 포함한 모든 학생들이 교육 환경에서 존중받고 포용될 수 있도록 실천적 행동을 요구하는 문항들로 구성되어 있습니다. 검사를 통해 얻은 결과는 교육 기관이 장애 인식 개선 프로그램을 설계하고 실행하는 데 필요한 중요한 정보를 제공합니다."
          />

          {/* Section for "검사 시행 지침" */}
          <Section
            title="검사 시행 지침"
            summary="검사 시행 지침은 학교장애인식검사를 진행하는 데 필요한 절차와 방법을 안내합니다."
            fullText="검사 시행 지침은 교육기관에서 학교장애인식검사를 실시할 때 준수해야 할 절차와 지침을 포함합니다. 이에는 검사의 시행 시간과 장소, 참여자의 역할 및 책임, 문항에 대한 이해도 테스트, 결과의 수집과 해석 방법 등이 포함됩니다. 또한, 검사 진행에 필요한 자원 및 장비에 대한 안내도 포함되어 있습니다. 검사 시행 지침은 교육기관이 학교장애인식검사를 원활하게 진행할 수 있도록 지원하고, 검사 결과의 신뢰성과 유효성을 보장하기 위해 중요한 역할을 합니다."
          />

          {/* Section for "검사 결과 해석" */}
          <Section
            title="검사 결과 해석"
            summary="검사 결과 해석은 학생 및 교육자들이 검사 결과를 이해하고 적절한 조치를 취할 수 있도록 돕습니다."
            fullText="검사 결과 해석은 학생과 교육자들이 검사에서 얻은 데이터를 분석하고 해석하는 과정을 의미합니다. 이에는 각 항목의 결과를 해석하여 개인 또는 교육기관의 강점과 개선이 필요한 부분을 식별하고, 이를 기반으로 개별 학생이나 교육 환경에 맞는 개별화된 지원 및 개선 방안을 도출하는 과정이 포함됩니다. 검사 결과 해석은 학교장애인식검사의 의미 있는 활용을 위해 중요한 단계로, 정확한 해석을 통해 학생들의 교육 수준 향상과 교육 공동체의 발전에 기여합니다."
          />

          {/* 법령 정보 */}
          <Typography variant="h6" gutterBottom>법령 정보</Typography>
          <TableContainer component={Paper} sx={{ backgroundColor: '#e7f2f8' }}>
            <Table aria-label="law table">
              <TableHead>
                <TableRow>
                  <TableCell>법령명</TableCell>
                  <TableCell align="left">적용 내용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    장애인복지법
                    <br />
                    「장애인복지법」 제25조 제2항 [시행 2016.11.30]
                  </TableCell>
                  <TableCell align="left">
                    국가기관 및 지방자치단체의장,『영유아보육법』에 따른 어린이집, 『유아교육법』『초·중등교육법』『고등교육법』에 따른 각급 학교의 장, 그 밖에 대통령령으로 정하는 교육기관 및 공공단체의 장은 소속 직원·학생을 대상으로 장애인에 대한 인식개선을 위한 교육을 실시하고, 그 결과를 보건복지부장관에게 제출하여야 한다.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    장애인복지법 시행령
                    <br />
                    「장애인복지법 시행령」 제16조(인식개선교육의 실시), 제16조의2~4
                  </TableCell>
                  <TableCell align="left">
                    법 제25조 제2항에 따른 기관 또는 단체(이하 &ldquo;국가기관등&rdquo;이라 한다)의 장은 소속 직원ㆍ학생을 대상으로 장애인에 대한 인식개선을 위한 교육(이하 &ldquo;인식개선교육&rdquo;이라 한다)을 매년 1회 이상, 1시간 이상 실시해야 한다. 다만, 보건복지부장관이 교육 대상별로 교육 시간을 단축하여 달리 정한 경우에는 그에 따라 인식개선교육을 실시해야 한다. &lt;개정 2021. 6. 1.&gt;
                    <br /><br />
                    인식개선교육에는 다음 각 호의 사항이 포함되어야 한다. &lt;개정 2021. 6. 29.&gt;
                    <ol>
                      <li>장애 및 장애인에 대한 이해와 긍정적 인식의 제고</li>
                      <li>장애인의 인권과 관련한 법과 제도</li>
                      <li>장애가 가지는 다양성에 대한 존중</li>
                      <li>장애인의 자율성 및 자립에 대한 존중</li>
                      <li>장애인보조기구 및 장애인 편의시설 등의 접근성에 대한 이해</li>
                      <li>그 밖에 장애인에 대한 인식을 개선할 수 있는 내용</li>
                    </ol>
                    인식개선교육은 집합 교육 또는 인터넷 강의 등을 활용한 원격 교육, 체험 교육 등의 방법으로 하되, 특별한 사정이 없는 한 대면교육을 포함해야 한다. &lt;개정 2021. 6. 1., 2021. 6. 29.&gt;
                    <br /><br />
                    국가기관등의 장은 인식개선교육을 실시한 경우 교육 내용, 방법, 교육 대상인원 및 참가인원 등의 교육 결과를 보건복지부령으로 정하는 바에 따라 보건복지부장관에게 제출해야 한다. &lt;개정 2021. 6. 1.&gt;
                  </TableCell>

                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    장애인복지법 시행규칙
                    <br />
                    「장애인복지법 시행규칙」 제2조의3(인식개선교육의 실시 결과 제출), 제2조의2(인식개선교육의 실시 결과 제출)
                  </TableCell>
                  <TableCell align="right">
                    「장애인복지법」(이하 “법”이라 한다) 제25조제2항 및 영 제16조제5항에 따라 장애인에 대한 인식개선을 위한 교육(이하 “인식개선교육”이라 한다)을 실시한 국가기관 및 지방자치단체의 장, 「영유아보육법」에 따른 어린이집, 「유아교육법」·「초ㆍ중등교육법」·「고등교육법」에 따른 각급 학교의 장 및 영 제16조제1항 각 호에 따른 기관 또는 단체(이하 “국가기관등”이라 한다)의 장은 매년 2월 말일까지 전년도의 인식개선교육 실시 결과를 법 제25조제8항에 따른 인식개선교육 정보시스템을 통해 보건복지부장관에게 제출해야 한다.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>




          {/* Adding the image below the title */}
          <img src="/assets/intro.webp" alt="Introduction" style={{ maxWidth: '50%', marginTop: '20px', marginBottom: '20px' }} />
        </Box>
      </Grid>

      <Footer />
    </div>
  );
}

export default Intro;
