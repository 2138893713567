import React, { useState } from 'react';

function SchoolManagerMenu({ setSelectedMenu: setExternalSelectedMenu }) {
    const [selectedMenu, setSelectedMenu] = useState('');
    const [activeSubMenu, setActiveSubMenu] = useState('');
    const [activeMainMenu, setActiveMainMenu] = useState('');
    const [hoveredSubMenu, setHoveredSubMenu] = useState('');
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const subMenus = {
        '학교관리': ['학교정보관리', '학급관리', '교원관리'],
        '검사결과관리': ['교원', '학생']
    };

    const handleMenuClick = (menu) => {
        setSelectedMenu(selectedMenu === menu ? '' : menu); // Toggle menu
    };

    const handleSubMenuClick = (subMenu, mainMenu) => {
        console.log("subMenu clicked:" + subMenu);
        setExternalSelectedMenu(subMenu);
        setActiveSubMenu(subMenu);
        setActiveMainMenu(mainMenu);
        setSelectedMenu(''); // Close submenu on click
        setMobileMenuOpen(false); // Close mobile menu
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className="headerLnb-w">
            <button type="button" className="btn-type001 menu" onClick={toggleMobileMenu}>
                <span className="ico-type001 menu"><i className="hide">메뉴열기</i></span>
            </button>
            <div className={`headerLnb-inner ${isMobileMenuOpen ? 'open' : ''}`}>
                {Object.keys(subMenus).map(menu => (
                    <div key={menu} className="menu-item" style={{ position: 'relative' }}>
                        <a
                            href="#none"
                            className={`title-type002 ${selectedMenu === menu || activeMainMenu === menu ? 'current' : ''}`}
                            onClick={() => handleMenuClick(menu)}
                            style={{
                                color: activeMainMenu === menu ? '#00796B' : 'white'
                            }}
                        >
                            <span className="tit01-white">{menu}</span>
                        </a>
                        {selectedMenu === menu && (
                            <div className="subMenu" style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                backgroundColor: '#E0F7FA',
                                padding: '10px',
                                borderRadius: '5px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                zIndex: 1000,
                                whiteSpace: 'nowrap'
                            }}>
                                {subMenus[menu].map(subMenu => (
                                    <a
                                        key={subMenu}
                                        href="#none"
                                        className={`title-type002 ${activeSubMenu === subMenu ? 'active' : ''} ${hoveredSubMenu === subMenu ? 'hovered' : ''}`}
                                        onClick={() => handleSubMenuClick(subMenu, menu)}
                                        onMouseEnter={() => setHoveredSubMenu(subMenu)}
                                        onMouseLeave={() => setHoveredSubMenu('')}
                                    >
                                        <span className="tit01">{subMenu}</span>
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {isMobileMenuOpen && (
                <div className="headerMobileMenu-w current">
                    <div className="headerMobileMenu-inner">
                        {Object.keys(subMenus).map(menu => (
                            <div key={menu} className="mobile-menu-category">
                                <span className="mobile-menu-title">{menu}</span>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                    {subMenus[menu].map(subMenu => (
                                        <a
                                            key={subMenu}
                                            href="#none"
                                            className={`title-type002 ${activeSubMenu === subMenu ? 'active' : ''} ${hoveredSubMenu === subMenu ? 'hovered' : ''}`}
                                            onClick={() => handleSubMenuClick(subMenu, menu)}
                                            onMouseEnter={() => setHoveredSubMenu(subMenu)}
                                            onMouseLeave={() => setHoveredSubMenu('')}
                                        >
                                            <span className="tit01">{subMenu}</span>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <button type="button" className="btn-type001 close" onClick={() => setMobileMenuOpen(false)}>
                        <span className="ico-type004 close"><i className="hide">창 닫기</i></span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default SchoolManagerMenu;