import React, { useRef, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, DialogTitle, DialogContent, Modal, Box, Button, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import api from './apiService';
import { styled } from '@mui/system';
import StudentSurveyResultDetail from './StudentSurveyResultDetail';
import { useReactToPrint } from 'react-to-print';
import CustomToolbar from './components/CustomToolbar'; // CustomToolbar를 임포트합니다
import iconSearch from './image/icon_search.svg'; // 아이콘 경로


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw', // 화면 너비의 100%
    height: '100vh', // 화면 높이의 100%
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto', // 내용이 많을 경우 스크롤 가능하도록 설정
};

function TeacherSurveyResultStudentDetail({ open, onClose, schoolCode, eventId, teacherId }) {
    const [isLoading, setIsLoading] = useState(true);

    const [detailedData, setDetailedData] = useState([]);
    const [schoolYear, setSchoolYear] = useState();
    const [schoolClass, setSchoolClass] = useState();

    // 학생정보를 편집
    const [editData, setEditData] = useState({});
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, message: '' });

    const [openModal, setOpenModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    // react to print
    const componentRef = useRef();

    const isMobile = useMediaQuery('(max-width:600px)');


    const handleDetailOpenClick = (event, params) => {    // 학생의 진단결과 상세보기 모달 열기 실행
        event.stopPropagation(); // 중요: 다른 rowClick 이벤트의 전파를 중지
        setSelectedRowData(params.row);
        setOpenModal(true);
    };

    const handleDetailCloseModal = () => {  // 학생의 진단결과 상세보기 모달 닫기
        setOpenModal(false);
        setSelectedRowData(null);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Student Survey Result",
        //onBeforeGetContent: handleOnBeforeGetContent,
        //onBeforePrint: handleBeforePrint,
        //onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    // 교원 정보를 조회해서 schoolYear, schoolClass 정보를 가져온다.
    useEffect(() => {
        setIsLoading(true);
        if (teacherId) {
            const fetchData = async () => {
                try {
                    const response = await api.get(`/schoolmgr/teacher/${teacherId}`);
                    if (response.data.data.teacherId) {
                        if (response.data.data.schoolYear && response.data.data.schoolClass) {
                            setSchoolYear(response.data.data.schoolYear);
                            setSchoolClass(response.data.data.schoolClass);
                        }
                    } else {
                        console.log("No data found..");
                    }
                } catch (error) {
                    console.error("Error fetching teacher:", error);
                }
                setIsLoading(false);
            };
            fetchData();
           
        }
    }, [teacherId]);

    const reloadData = async () => {
        // The content of your fetchData functions goes here
        // You may need to refactor your useEffects so you have the fetchData functions available to call.
        setIsLoading(true);
        if (schoolCode && eventId && schoolYear && schoolClass) {
          
            const fetchData = async () => {
                try {
                    console.log("schoolYear, schoolClass", schoolYear, schoolClass);
                    const response = await api.get(`/surveyresult/school/class?schoolCode=${schoolCode}&eventId=${eventId}&Year=${schoolYear}&Class=${schoolClass}`);
                    if (response.status === 200 && response.data.data) {
                        setDetailedData(response.data.data);
                    }
                    else {
                        alert("반 학생의 응답 정보가 없습니다.");
                    }
                } catch (error) {
                    console.error("Error fetching detailed survey results:", error);
                }
                setIsLoading(false);
            };
            fetchData();
        }
    };

    useEffect(() => {
        if (schoolCode && eventId && schoolYear && schoolClass) {
            reloadData();
        }
    }, [schoolCode, eventId, schoolYear, schoolClass]);

    // Use the open prop directly for opening the modal and call the passed onClose for closing it
    const handleCloseDetailModal = () => {
        onClose(); // Use the onClose prop to signal the closing of the modal
        // Resetting state can remain here if you want to clear it when modal closes
        setSchoolYear(null);
        setSchoolClass(null);
    };

    //학생 정보를 클릭
    const handleRowClick = (params) => {
        setEditData(params.row);
        setIsEditModalOpen(true);
    };

   //학생 편집 저장
const handleSave = async () => {
    try {
        const response = await api.put(`/student/${editData.studentSeq}`, {
            schoolClass: editData.schoolClass,
            studentNumber: editData.studentNumber,
            studentName: editData.studentName
        });
        
        if (response.status === 200) {
            // Show success message dialog
            setConfirmDialog({
                isOpen: true,
                message: '저장되었습니다.',
                onConfirm: handleCloseConfirmDialog // Close dialog on confirm
            });
            handleCloseEditModal(); // Close edit modal
            reloadData(); // Reload data
        }
    } catch (error) {
        console.error("Error updating student:", error);
        
        // 에러 응답 메시지 확인
        let errorMessage = "학생 정보 수정 중 오류가 발생했습니다.";
        
        if (error.response) {
            // 서버에서 응답이 왔을 경우
            if (error.response.status === 409) {
                // 중복된 학생 정보가 있는 경우
                errorMessage = error.response.data.message || "입력하신 학생 정보가 이미 존재합니다.";
            } else if (error.response.status === 404) {
                // 학생을 찾을 수 없는 경우
                errorMessage = error.response.data.message || "학생 정보를 찾을 수 없습니다.";
            } else {
                // 기타 서버 에러
                errorMessage = error.response.data.message || "서버 오류가 발생했습니다.";
            }
        }

        // Show error message dialog
        setConfirmDialog({
            isOpen: true,
            message: errorMessage,
            onConfirm: () => {
                setConfirmDialog({ isOpen: false });
            }
        });
    }
};

    //학생 정보 삭제
    const handleDelete = async () => {
        try {
            const response = await api.delete(`/surveyresult/student/response`, {
                params: {
                    responseStuSeq: editData.responseStuSeq,
                    eventId: eventId,
                    schoolCode: schoolCode,
                    Year: editData.schoolYear,
                    Class: editData.schoolClass
                }
            });
            if (response.status === 200) {
                // Show success message dialog
                setConfirmDialog({
                    isOpen: true,
                    message: '삭제되었습니다.',
                    onConfirm: handleCloseConfirmDialog // Close dialog on confirm
                });
                handleCloseEditModal(); // Close edit modal
                reloadData(); // Reload data
            }
        } catch (error) {
            console.error("Error deleting student response:", error);
        }
    };

    const handleEditChange = (prop) => (event) => {
        setEditData({ ...editData, [prop]: event.target.value });
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleConfirm = (action) => {
        setConfirmDialog({
            isOpen: true,
            message: action === 'save' ? '저장하시겠습니까?' : '삭제하시겠습니까?',
            onConfirm: action === 'save' ? handleSave : handleDelete,
            showCancel: true
        });
    };

    const ConfirmationDialog = ({ open, message, onClose, onConfirm, showCancel }) => (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>확인</DialogTitle>
            <DialogContent>
                <div>{message}</div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    {showCancel && <Button onClick={onClose}>취소</Button>}
                    <Button color="primary" onClick={onConfirm}>확인</Button>
                </div>
            </DialogContent>
        </Dialog>
    );

    const handleCloseConfirmDialog = () => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'Invalid Date';
        // 시간대 정보 추가
        const date = new Date(dateString);
        return isNaN(date) ? 'Invalid Date' : date.toLocaleDateString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const detailcolumns = isMobile ? [
        { field: 'schoolYear', headerName: '학년', flex: 0.5 },
        { field: 'schoolClass', headerName: '반', flex: 0.5 },
        { field: 'studentNumber', headerName: '번호', flex: 0.5 },
        {
            field: 'studentName',
            headerName: '이름',
            flex: 1,
            renderCell: (params) => (
                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => handleRowClick(params)}
                >
                    {params.value}
                </span>
            )
        },
        { 
            field: 'responseDate', 
            headerName: '검사응답일', 
            flex: 1, 
            renderCell: (params) => {
                const dateString = params.value;
                //console.log('responseDate:', dateString); // value 확인
                return formatDate(dateString);
            }
        },
        { field: 'score', headerName: '점수', flex: 0.5 },
        { field: 'level', headerName: '수준', flex: 0.5 },
        {
            field: 'detail',
            headerName: '결과지',
            sortable: false,
            renderCell: (params) => (
                <img
                    src={iconSearch}
                    alt="상세보기"
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => handleDetailOpenClick(event, params)}
                />
            ),
            flex: 1
        },
    ] : [
        { field: 'schoolYear', headerName: '학년', flex: 0.5 },
        { field: 'schoolClass', headerName: '반', flex: 0.5 },
        { field: 'studentNumber', headerName: '번호', flex: 0.5 },
        {
            field: 'studentName',
            headerName: '이름',
            flex: 1,
            renderCell: (params) => (
                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => handleRowClick(params)}
                >
                    {params.value}
                </span>
            )
        },
        { 
            field: 'responseDate', 
            headerName: '검사응답일', 
            flex: 1, 
            renderCell: (params) => {
                const dateString = params.value;
                //console.log('responseDate:', dateString); // value 확인
                return formatDate(dateString);
            }
        },
        { field: 'score', headerName: '점수', flex: 0.5 },
        { field: 'level', headerName: '수준', flex: 0.5 },
        {
            field: 'detail',
            headerName: '결과지',
            sortable: false,
            renderCell: (params) => (
                <img
                    src={iconSearch}
                    alt="상세보기"
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => handleDetailOpenClick(event, params)}
                />
            ),
            flex: 1
        },
    ];


    const detailModalComponent = (
        <div className="modal-w active">
            <div className="modal-inner-type80">
                <div className="title-type001">
                    <strong className="tit01 blue">학생 검사결과</strong>
                </div>
                <div className="formDatagrid-w" style={{ height: '600px', width: '100%' }}>
                    <DataGrid
                        rows={detailedData}
                        columns={detailcolumns}
                        getRowId={(row) => row.responseStuSeq}
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        loading={isLoading}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: 'lightgray',
                                fontSize: '1.2rem',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: '1.1rem',
                            },
                            '& .MuiDataGrid-footerContainer': {
                                backgroundColor: '#fff',
                            },
                            '& .MuiDataGrid-overlay': {
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                            },
                            backgroundColor: '#fff',
                        }}
                        slots={{
                            toolbar: () => (<CustomToolbar fileName="검사결과_반(학생)" />)
                        }}
                        
                    />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn-type005 border close" onClick={handleCloseDetailModal}>
                        <span className="txt01">닫기</span>
                    </button>
                </div>
            </div>
            <div className="dimm"></div>
        </div>
    );


    const editModalComponent = (
        <div className={`modal-w ${isEditModalOpen ? 'active' : ''}`} aria-modal="true">
            <div className="modal-inner-type45">
                <div className="title-type001">
                    <strong className="tit01">학생 정보 수정</strong>
                </div>
                <div className="modal-body">
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">반</strong>
                        </div>
                        <div className="input-type01">
                            <label htmlFor="schoolClass" className="hide">반</label>
                            <input
                                type="text"
                                value={editData.schoolClass}
                                onChange={handleEditChange('schoolClass')}
                                disabled
                                style={{ width: '100%', margin: '8px 0', padding: '8px', boxSizing: 'border-box' }}
                            />
                            </div>
                        </form>
                    </fieldset>
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">번호</strong>
                        </div>
                        <div className="input-type01">
                            <label htmlFor="studentNumber" className="hide">번호</label>
                            <input
                                type="text"
                                value={editData.studentNumber}
                                onChange={handleEditChange('studentNumber')}
                                style={{ width: '100%', margin: '8px 0', padding: '8px', boxSizing: 'border-box' }}
                            />
                            </div>
                        </form>
                    </fieldset>
                    <fieldset className="fieldset-type02">
                        <form className="input-group02">
                            <div className="title-type002">
                                <strong className="tit01">이름</strong>
                            </div>
                        <div className="input-type01">
                            <label htmlFor="studentName" className="hide">이름</label>
                            <input
                                type="text"
                                value={editData.studentName}
                                onChange={handleEditChange('studentName')}
                                style={{ width: '100%', margin: '8px 0', padding: '8px', boxSizing: 'border-box' }}
                            />
                            </div>
                        </form>
                    </fieldset>
                </div>
                <fieldset className="fieldset-type02" style={{ marginTop: '20px' }}>
                    <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button color="primary" variant="contained" onClick={() => handleConfirm('save')}>저장</Button>
                        <Button color="secondary" variant="contained" onClick={() => handleConfirm('delete')}>삭제</Button>
                        <Button onClick={handleCloseEditModal}>닫기</Button>
                    </div>
                </fieldset>
            </div>
            <i className="dimm"></i>
        </div>
    );

    // Return null if no detailedData is available
    /*
    if (!detailedData || detailedData.length === 0) {
        console.log(detailedData);
        return null;
    }
    */
    return (
        <div>
            {detailModalComponent}
            {editModalComponent}
            <ConfirmationDialog
                open={confirmDialog.isOpen}
                message={confirmDialog.message}
                onClose={handleCloseConfirmDialog}
                onConfirm={confirmDialog.onConfirm}
                showCancel={confirmDialog.showCancel}
            />
            <Modal
                open={openModal}
                onClose={handleDetailCloseModal}
                aria-labelledby="student-response-modal"
            >
                <Box className="print-only" sx={{ ...style }}>
                    {selectedRowData && (
                        <StudentSurveyResultDetail
                            eventId={selectedRowData.eventId}
                            schoolCode={schoolCode}
                            studentSeq={selectedRowData.studentSeq}
                            onPrint={handlePrint}
                            onClose={handleDetailCloseModal}
                            ref={componentRef}

                        />
                    )}
                </Box>
            </Modal>
        </div>
    );
}

export default TeacherSurveyResultStudentDetail;
