import React, { useRef, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Modal, Box, useMediaQuery } from '@mui/material'; // MUI의 Button 및 Modal 컴포넌트 사용
import { useTheme } from '@mui/material/styles';
import api from './apiService';
import StudentSurveyResultDetail from './StudentSurveyResultDetail';
import { useReactToPrint } from 'react-to-print';

const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

const StudentSurveyResultPage = ({ schoolCode, studentSeq }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const [openModal, setOpenModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    // react-to-print
    const componentRef = useRef();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Student Survey Result",
        removeAfterPrint: true
    });

    useEffect(() => {
        setIsLoading(true);
        api.get(`/surveyevent/studentresponse?&studentSeq=${studentSeq}`)
            .then(response => {
                if (response.error) {
                    console.error('Error fetching data:', response.data.error);
                    setEvents([]);
                } else {
                    const updatedEvents = response.data.data.map(event => {
                        event.response = event.response === null ? "응답안함" : "확인가능";
                        event.eventStartDate = new Date(event.eventStartDate).toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        event.eventEndDate = new Date(event.eventEndDate).toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        });
                        return event;
                    });
                    setEvents(updatedEvents);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setEvents([]);
                setIsLoading(false);
            });
    }, [schoolCode, studentSeq]);

    const columns = isMobile ? [
        { field: 'eventTitle', headerName: '검사이벤트명', flex: 2.5 },
        {
            field: 'response',
            headerName: '결과지',
            flex: 1,
            renderCell: (params) => (
                params.value === "확인가능" ? (
                    <Button variant="contained" color="primary" onClick={() => handleRowClick(params)} sx={{ fontSize: '1.2rem' }}>
                        결과지보기
                    </Button>
                ) : (
                    <span>응답안함</span>
                )
            )
        },
    ] : [
        { field: 'eventTitle', headerName: '검사이벤트명', flex: 2.5 },
        { field: 'eventStartDate', headerName: '시작일', flex: 1 },
        { field: 'eventEndDate', headerName: '종료일', flex: 1 },
        {
            field: 'response',
            headerName: '결과지',
            flex: 1,
            renderCell: (params) => (
                params.value === "확인가능" ? (
                    <Button variant="contained" color="primary" onClick={() => handleRowClick(params)} sx={{ fontSize: '1.2rem' }}>
                        결과지보기
                    </Button>
                ) : (
                    <span>응답안함</span>
                )
            )
        },
    ];

    const handleRowClick = (params) => {
        setSelectedRowData(params.row);
        if (params.row.response === "확인가능") {
            setOpenModal(true);
        } else {
            alert("응답 정보가 없습니다.");
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedRowData(null);
    };

    return (
        <div className="container page-student">
            <div className="content-w-form">
                <h2 className="title-type001">
                    <strong className="tit01">학생 검사 결과</strong>
                </h2>
                <div className="form-inner">
                    <div className="formDatagrid-w" style={{ width: '100%', backgroundColor: '#fff' }}>
                        <DataGrid
                            rows={events}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[5, 10, 20]}
                            onPageChange={(page) => setPaginationModel({ ...paginationModel, page })}
                            getRowId={(row) => row.eventId}
                            loading={isLoading}
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'lightgray',
                                    fontSize: '1.2rem',
                                },
                                '& .MuiDataGrid-cell': {
                                    fontSize: '1.1rem',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    backgroundColor: '#fff',
                                },
                                '& .MuiDataGrid-overlay': {
                                    justifyContent: 'center',
                                    display: 'flex',
                                },
                                backgroundColor: '#fff',
                            }}
                            slots={{
                                noRowsOverlay: () => (
                                    <div style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
                                        <strong className="tit01">조회된 데이터가 없습니다.</strong>
                                    </div>
                                )
                            }}
                        />
                        
                    </div>
                    <div className="input-type01" style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', textAlign: 'center' }}>
                            <span className="tit01" style={{ fontSize: '18px', color: '#333', fontWeight: 'bold' }}>
                                ※ 결과지보기에서 자신의 학교장애인식지수를 확인한 후, 홈에 있는
                                <a href="/main_shorts.html" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'underline', marginLeft: '5px' }}>
                                    쇼츠보기
                                </a>
                                에서 영상을 볼 수 있습니다. MIDD 또는 RASE가 나온 영역부터 시청해보세요.
                            </span>
                        </div>
                </div>
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    {selectedRowData && (
                        <StudentSurveyResultDetail
                            eventId={selectedRowData.eventId}
                            schoolCode={schoolCode}
                            studentSeq={studentSeq}
                            onPrint={handlePrint}
                            onClose={handleCloseModal}
                            ref={componentRef}
                        />
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default StudentSurveyResultPage;