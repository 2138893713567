import axios from 'axios';

// 로컬 저장소에서 로그인 정보를 가져오는 함수
const getStoredLoginInfo = () => {
    const storedLoginInfo = localStorage.getItem('loginInfo');
    return storedLoginInfo ? JSON.parse(storedLoginInfo) : null;
};

// 프로토콜에 따라 API URL 선택
const apiUrl = process.env.REACT_APP_ENV === 'development'
  ? process.env.REACT_APP_API_BASE_URL_HTTP
  : process.env.REACT_APP_API_BASE_URL_HTTPS;

// Axios 인스턴스 생성
const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

// 요청을 보낼 때마다 인터셉터를 사용하여 토큰과 지역을 헤더에 추가
api.interceptors.request.use(config => {
  const token = getStoredLoginInfo()?.data?.token;
  const region = window.location.hostname.split('.')[0]; // 서브도메인에서 지역 추출
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  if (region) {
    config.headers['Region'] = region; // Region 헤더 추가
    console.log(`Request Region: ${region}`); // Log the region
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 응답 인터셉터
api.interceptors.response.use(response => {
  return response;
}, error => {
  // 403 Forbidden 응답을 받으면 로그인 페이지로 리디렉션
  if (error.response && error.response.status === 403) {
    window.location = '/login';
  }
  return Promise.reject(error);
});

export const signInOperator = (userData) => {
    let endpoint;
    switch (userData.usergroup) {
        case '1':
            endpoint = '/login/operator/signin';
            break;
        case '5':
            endpoint = '/login/supporter/signin';
            break;    
        case '2':
            endpoint = '/login/school/signin';
            break;
        case '3':
            endpoint = '/login/teacher/signin';
            break;
        default:
            throw new Error('Invalid usergroup');
    }
    return api.post(endpoint, userData);
}

export default api;
