import React, { useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import api from './apiService';
import SchoolTeacherDetail from './SchoolTeacherDetail';

function SchoolTeacherSearch({ selectedMenu }) {
    const [data, setData] = useState([]);
    const [searchParam, setSearchParam] = useState({
        teacherId: "",
        teacherPasswd: "",
        teacherType: "",
        schoolYear: null,
        schoolClass: null,
        teacherName: "",
        registerDate: "",
        schoolCode: "",
        schoolDTO: {
            schoolCode: "",
            schoolName: "",
            schoolGrade: "",
            schoolZipcode: "",
            schoolAddress1: "",
            schoolAddress2: "",
            teacherPasswd: ""
        }
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTeacherId, setSelectedTeacherId] = useState(null);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const columns = [
        { field: 'teacherId', headerName: '선생님ID', flex: 1 },
        { field: 'teacherName', headerName: '성명', flex: 1 },
        { field: 'schoolYear', headerName: '학년', flex: 0.5 },
        { field: 'schoolClass', headerName: '반', flex: 0.5 },
        { field: 'schoolName', headerName: '학교명', flex: 1.5, renderCell: (params) => params.row.schoolDTO.schoolName },
        { field: 'schoolCode', headerName: '학교코드', flex: 1, renderCell: (params) => params.row.schoolDTO.schoolCode },
    ];

    const schoolGradeOptions = [
        { schoolGrade: 'E', name: '초등' },
        { schoolGrade: 'M', name: '중등' },
        { schoolGrade: 'H', name: '고등' },
    ];

    const handleSearch = (overrideSchoolName = null) => {
        const schoolName = overrideSchoolName !== null ? overrideSchoolName : searchParam.schoolDTO.schoolName;
        if (!searchParam.schoolDTO.schoolGrade || !schoolName) {
            alert('학교급과 학교명을 모두 입력해야 검색이 가능합니다.');
            return;
        }

        const paramsToSearch = {
            ...searchParam,
            schoolDTO: {
                ...searchParam.schoolDTO,
                schoolName: schoolName,
            }
        };

        api.post("/schoolmgr/teacher/search", paramsToSearch)
            .then(response => {
                const teacherDatas = response.data.data.map(item => ({ ...item, id: item.teacherId }));
                setSearchParam(paramsToSearch);
                setData(teacherDatas);
            })
            .catch(error => {
                console.error("API fetch error:", error);
            });
    };

    const handleRowClick = (params) => {
        setSelectedTeacherId(params.row.teacherId);
        setOpenDialog(true);
    };

    const inputRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e.target.value);
            e.preventDefault();
        }
    };

    const handleDeleteSuccess = (deletedTeacherId) => {
        const newData = data.filter(item => item.teacherId !== deletedTeacherId);
        setData(newData);
    };

    return (
        <div className="container page-admin">
            <div className="content-w-form">
                <div className="admin-indicator">
                    <div className="txt-type001">
                        <span className="txt01">홈 &gt; 학교관리 &gt; {selectedMenu}</span>
                    </div>
                </div>

                <h2 className="title-type001">
                    <strong className="tit01">{selectedMenu}</strong>
                </h2>

                <div className="form-inner">
                    <fieldset className="fieldset-type01">
                        <div className="select-wrap">
                            <select
                                name="schoolGrade"
                                className="select-type01"
                                value={searchParam.schoolDTO.schoolGrade}
                                onChange={(e) => setSearchParam({
                                    ...searchParam,
                                    schoolDTO: {
                                        ...searchParam.schoolDTO,
                                        schoolGrade: e.target.value
                                    }
                                })}
                            >
                                <option value="" disabled>학교급을 선택하세요</option>
                                {schoolGradeOptions.map(option => (
                                    <option key={option.schoolGrade} value={option.schoolGrade}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <form className="input-group01">
                            <div className="input-type01">
                                <label htmlFor="searchTeacher" className="hide">학교명</label>
                                <input
                                    id="searchTeacher"
                                    type="text"
                                    placeholder="학교명"
                                    defaultValue={searchParam.schoolDTO.schoolName}
                                    ref={inputRef}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                            <button
                                type="button"
                                className="btn-type005 blue"
                                onClick={() => handleSearch(inputRef.current.value)}
                            >
                                <span className="txt01">검색</span>
                            </button>
                        </form>
                    </fieldset>
                </div>

                <div style={{ width: '100%' }}>
                    <DataGrid
                        autoHeight
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 20]}
                        onRowClick={handleRowClick}
                    />
                </div>

                {openDialog && <SchoolTeacherDetail
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                        setSelectedTeacherId(null);
                        handleSearch();
                    }}
                    onDeleteSuccess={handleDeleteSuccess}
                    teacherId={selectedTeacherId}
                />}
            </div>
        </div>
    );
}

export default SchoolTeacherSearch;
